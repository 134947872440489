import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-singup-eror-page',
  templateUrl: './singup-eror-page.component.html',
  styleUrls: ['./singup-eror-page.component.scss']
})
export class SingupErorPageComponent implements OnInit {

  error: any
  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params.error){
        this.error = params.error
      }
    });
  }

}
