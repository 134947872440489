<form class="ts-form create-form-full-view w-100 px-0">
    <div class="create-form-content-full-view px-0" [formGroup]="workspaceForm">
        <div class="content-view">
            <div class="d-flex">
              <a
                class="form-title">Create Workspce</a>
              <i class="ml-auto fa-close-alt pointer ml-50"  (click)="goBack()" [matTooltip]="'hint.message.common.close' | translate"></i>
            </div>
            <div class="create-form-title mb-8 pt-30"><span style="color: red;">* </span> <span [translate]="'work_space.form.name'"></span></div>
            <div class="form-group pb-14 ml-auto">
                <input class="form-control" required name="name" formControlName="name" autofocus/>
            </div>
            <div class="form-group">
                <span class="text-dark pointer" [translate]="'work_space.form.description'" [class.text-underline]="!showDescription" (click)="showDescription=!showDescription"></span>
                <div class="mt-15" @fade *ngIf="showDescription">
                    <app-description class="without-label" [formCtrlName]="workspaceForm.controls.description"></app-description>
                </div>
            </div>
            <div class="form-group ts-col-100">
                <div class="p-0 field mb-0">
                    <input type="text" id="name" formControlName="workspaceType" placeholder=" " class="form-control"/>
                    <label [translate]="'work_space.form.workspaceType'" for="name" class="required"></label>
                </div>
            </div>
        </div>
    </div>

    <div class="theme-border-t ts-col-70 mx-auto text-right py-15">
        <a  [translate]="'btn.common.cancel'" class="theme-btn-clear-default" (click)="goBack()"></a>
        <button class="theme-btn-primary" (click)="Create()" [message]="'message.common.saving'" >Create</button>
    </div>
</form>
