/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { UrlConstantsService } from "app/shared/services/url.constants.service";
import { HttpHeadersService } from "app/shared/services/http-headers.service";
import { Session } from "app/shared/models/session.model";
import { throwError, from, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class SessionService {
  #DEMO_TOKEN: string = "demo.token";
  #API_KEY!: string;
  get apiKey(): string {
    return this.#API_KEY;
  }
  setApiKey(key: string) {
    this.#API_KEY = key;
  }
  readonly #JWT_KEY = "_t";
  get token() {
    return (
      sessionStorage.getItem(this.#JWT_KEY) ||
      localStorage.getItem(this.#JWT_KEY)
    );
  }
  constructor(
    private http: HttpClient,
    private httpHeaders: HttpHeadersService,
    private URLConstants: UrlConstantsService,
    private router: Router
  ) { }
  setToken(token: string, remember?: boolean) {
    if (remember) {
      localStorage.setItem(this.#JWT_KEY, token);
    } else {
      sessionStorage.setItem(this.#JWT_KEY, token);
    }
  }
  getSession() {
    const token = this.token;
    if (!token) {
      return throwError(new Error("Token is not found"));
    }
    return this.http.get<Session>(this.URLConstants.sessionUrl).pipe(
      map((data) => new Session().deserialize(data)),
      catchError((err) => {
        // this.router.navigateByUrl("/login");
        return throwError(err);
      })
    );
  }

  login(username: string, password: string, remember?: boolean) {
    // if (username === 'demo' && password === 'demo') {
    //   this.setToken(this.#DEMO_TOKEN, remember);
    //   return new Observable((observer) => {
    //     observer.next({});
    //     observer.complete();
    //   });
    // }
    return this.http
      .post<any>(
        this.URLConstants.loginUrl,
        {
          username,
          password,
        },
        {
          headers: this.httpHeaders.contentTypeApplication,
        }
      )
      .pipe(
        map((resp) => {
          this.setToken(resp.token, remember);
          return;
        })
      );
  }

  sendTokenToChromeExtension({ extensionId, event }: any) {
    if (chrome.runtime) {
      chrome.runtime.sendMessage(extensionId, { event }, (res: any) => {
        if (!res.success) {
          return res;
        }
      });
    }
  }

  logout() {
    sessionStorage.removeItem(this.#JWT_KEY);
    localStorage.removeItem(this.#JWT_KEY);
    // window.location.href = `https://stagging.contextqa.com/logout?org=${window.location.hostname.split('.')[0]}&client_id=pgadpooodaipbhbchnojijmlkhpamadh&client_name=extension`;
    window.location.href = `https://accounts.contextqa.com/logout?org=${window.location.hostname.split('.')[0]}&client_id=pgadpooodaipbhbchnojijmlkhpamadh&client_name=extension`;
    // window.location.href = `http://localhost:4203/logout?org=${window.location.hostname.split('.')[0]}&client_id=pgadpooodaipbhbchnojijmlkhpamadh&client_name=extension`;
    
    this.sendTokenToChromeExtension({ extensionId: 'pgadpooodaipbhbchnojijmlkhpamadh', event: "logout" })
  }

  logoutFromLeftNav() {
    sessionStorage.removeItem(this.#JWT_KEY);
    localStorage.removeItem(this.#JWT_KEY);
    window.location.href = `https://accounts.contextqa.com/onboard`;

  }
}
