import { Component } from "@angular/core";
// import { loadStripe, Stripe } from '@stripe/stripe-js';
import { LicenceSevices } from "../../shared/services/license.service";
import { BaseComponent } from "app/shared/components/base.component";
import { AuthenticationGuard } from "app/shared/guards/authentication.guard";
import { NotificationsService, NotificationType } from "angular2-notifications";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-stripe-pricing",
  //   template: `
  //   <script async src="https://js.stripe.com/v3/pricing-table.js"></script>
  //   <stripe-pricing-table pricing-table-id="prctbl_1NiHwSKCkF1UmVlR6GAQainY"
  //   [publishable-key]="stripePublishableKey">
  //   </stripe-pricing-table>`,
  templateUrl: "./stripe-pricing.component.html",
  styleUrls: ["./stripe-pricing.component.css"],
})
export class StripePricingComponent extends BaseComponent {
  // public stripePublishableKey: string = 'pk_live_51Mja1ZKCkF1UmVlRBGwOVJ0DtwI4etGqSzWzAJl2W0v7pFtWRxfJ6G3tialjA1SE23gnBcjlDlmBh0iMZ6jXujX400z853LGJd';
  // public strip ="hello world"
  public dataValue: any;
  constructor(
    public LicenceSevices: LicenceSevices,
    public notificationsService: NotificationsService,
    public authGuard: AuthenticationGuard,
    public translate: TranslateService,
    public toastrService: ToastrService
  ) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  ngOnInit(): void {}

  OnclickFreeBuy() {
    this.LicenceSevices.getFreePricing().subscribe({
      next: (data) => {
        this.dataValue = data;
        window.open(this.dataValue.url, "_blank")?.focus();
      },
      error: (error) => {
      this.showNotification(NotificationType.Error, 'Invalid email address');
      }
    });
  }
  
  OnclickBasicBuy() {
    this.LicenceSevices.getBasicPricing().subscribe({
      next: (data) => {
        this.dataValue = data;
        window.open(this.dataValue.url, "_blank")?.focus();
      },
      error: (error) => {
        this.showNotification(NotificationType.Error, 'Invalid email address');
      }
    });
  }
  
  OnclickPremiumBuy() {
    this.LicenceSevices.getPremiumPricing().subscribe({
      next: (data) => {
        this.dataValue = data;
        window.open(this.dataValue.url, "_blank")?.focus();
      },
      error: (error) => {
        this.showNotification(NotificationType.Error, 'Invalid email address');
      }
    });
  }
}
