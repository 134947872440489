import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, OnInit, SimpleChange, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TestCase } from 'app/models/test-case.model';
import { TestStepService } from 'app/services/test-step.service';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject, Subscription, timer } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { TestStep } from "../../../../models/test-step.model";
@Component({
  selector: 'app-execution-model',
  templateUrl: './execution-model.component.html',
  styleUrls: ['./execution-model.component.scss']
})
export class ExecutionModelComponent implements OnInit, DoCheck {
  @ViewChild('logContainer') private logContainer: ElementRef;

  public Prop: any;
  public loader: any;
  public TestCase: any;
  public TestSteps: TestCase[];
  public TestStep: TestStep[];
  public ExecutedStepsData: TestCase[];
  public time: any;
  public failSteps: any;
  public testRunId: any;
  public successSteps: any;
  public stepsData: any = [];
  public isFull_Model: boolean = false;
  public activeLog: string = 'log';
  stepGroupSteps: any = [];
  public displayedLogs: { text: string, complete: boolean }[] = [];
  private lastLogLength: number = 0;
  public Conversation: any;
  public message: any;
  public faildStepsDetail: any;
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';

  private timerSubscription: Subscription | null = null;
  private inputEnabled = new BehaviorSubject<boolean>(false);
  public inputEnabled$ = this.inputEnabled.asObservable();

  public remainingTime$ = new BehaviorSubject<number>(0);
  private timerStarted: boolean = false;
  public locatorNotDetect: boolean = false;

  public readonly CIRCLE_CIRCUMFERENCE = 2 * Math.PI * 52; // 52 is the radius of our circle



  constructor(
    private dialogRef: MatDialogRef<ExecutionModelComponent>,
    private testStepService: TestStepService,
    public router: Router,
    private socket: Socket,
    private cdr: ChangeDetectorRef
  ) { }

  // ngOnChanges(data: SimpleChange) {
  //   console.log(this.Prop)
  // }

  ngOnInit(): void {

  }

  ngDoCheck() {
    if (this.Prop && this.Prop.log && this.Prop.log.length > this.lastLogLength) {
      const newLogs = this.Prop.log.slice(this.lastLogLength);
      newLogs.forEach(log => this.addNewLog(log));
      this.lastLogLength = this.Prop.log.length;
    }
    if (this.Prop && this.Prop.stepStatus === 'LOCATOR_NOT_DETECT' && !this.timerStarted) {
      this.startTimer();
      this.timerStarted = true;
    }
    if (this.Prop && this.Conversation && this.Conversation.stepStatus === "TIMEOUT" && !this.timerStarted) {
      this.startTimer();
      this.timerStarted = true;
    }

  }

  private addNewLog(log: string): void {
    this.displayedLogs.push({ text: '', complete: false });
    this.simulateTyping(log, this.displayedLogs.length - 1);
  }

  redirectToCE() {
    if (this.Conversation.stepStatus === "LOCATOR_NOT_DETECT") {
      console.log("Conversation redirectToCE ::", this.Conversation.id)
      this.testStepService.show(this.Conversation.id).subscribe((steps) => {
        console.log("STESP DETAIL ::", steps)
        this.faildStepsDetail = steps
        if (this.faildStepsDetail) {
          if (chrome && chrome.runtime) {

            let data = {
              type: "test_case",
              // id: testcase.id,
              // result: testcase,
              action: "openSidePanelFromPortal1",
              origin: window.location.hostname.split(".")[0],
              // origin: "dtest01",
              stepNumber: 2, //Add position - 1
              faildStepsDetail: this.faildStepsDetail,
              jwt: localStorage.getItem("_t"),
              userEmail: localStorage.getItem('useremail')
            };
            chrome.runtime.sendMessage(
              this.extensionId,
              { message: "openSidePanelFromPortal1", data: data },
              (data: any) => {
              }
            );
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url = this.faildStepsDetail.event.targetPageUrl || this.faildStepsDetail.event.href;
            window.open(url, '_blank');
          } else {
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url = this.faildStepsDetail.event.targetPageUrl || this.faildStepsDetail.event.href;
            window.open(url, '_blank');
          }
        }
      })
    }
  }
  private simulateTyping(log: string, index: number): void {
    let charIndex = 0;
    const interval = setInterval(() => {
      if (charIndex < log.length) {
        this.displayedLogs[index].text += log[charIndex];
        charIndex++;
        this.cdr.detectChanges();
        this.scrollToBottom();
      } else {
        clearInterval(interval);
        this.displayedLogs[index].complete = true;
        this.cdr.detectChanges();
      }
    }, 50); // Adjust typing speed as needed
  }

  private scrollToBottom(): void {
    try {
      this.logContainer.nativeElement.scrollTop = this.logContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }


  /** Re Run */
  reRun(value?: boolean): void {
    this.dialogRef.close({ data: 'you confirmed' });
    this.testStepService.onRerun.emit()
  }

  /** Close Dialog */
  closeDialog() {
    this.dialogRef.close({ data: 'you confirmed' });
  }

  fullModel() {
    this.isFull_Model = !this.isFull_Model;
  }

  /** Get Step Data */
  expandedIndex: number | null = null;
  getStepDetailData(data: any, index: number) {
    if (this.expandedIndex === index) {
      this.expandedIndex = null;
    } else {
      this.expandedIndex = index;
    }
    if (data.stepGroupId) {
      let query = "testCaseId:" + data.stepGroupId;
      this.testStepService.findAll(query).subscribe((res) => {
        this.stepGroupSteps = res;
      });
    }
  }

  /** Re direct to test run  */
  redirectToRunDetail() {
    const url = this.router.createUrlTree(['/td/runs/', this.testRunId]).toString();
    window.open(url, '_blank');
  }

  sentevent() {
    if (this.Conversation && this.Conversation.stepStatus == 'LOCATOR_NOT_DETECT') {
      this.socket.emit('internal', { message: 'Locator updated', topic: this.Conversation?.replyBack_id });
    } else {
      this.socket.emit('internal', { message: this.message, topic: this.Conversation?.replyBack_id });
    }
    this.Conversation = null;
    this.message = '';
    this.timerStarted = false;
  }

  private startTimer() {
    this.stopTimer(); // Ensure any existing timer is stopped
    this.inputEnabled.next(true);
    this.locatorNotDetect = true;

    const duration = 2 * 60; // 2 minutes in seconds
    const TimeoutDuration = 1 * 50;
    if (this.Conversation.stepStatus === 'TIMEOUT') {
      this.remainingTime$.next(TimeoutDuration);
    } else {
      this.remainingTime$.next(duration);
    }


    this.timerSubscription = timer(0, 1000).pipe(
      takeWhile(() => this.remainingTime$.value > 0)
    ).subscribe(() => {
      const currentTime = this.remainingTime$.value;
      if (currentTime > 0) {
        this.remainingTime$.next(currentTime - 1);
      }
      if (currentTime === 1) {
        this.inputEnabled.next(false);
        this.stopTimer();
        if (this.Conversation.stepStatus === 'TIMEOUT') {
          this.Conversation = null;
        }
        this.timerStarted = false;
        this.locatorNotDetect = false;
      }
    });
  }

  private stopTimer() {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
      this.timerSubscription = null;
    }
  }

  public formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  public getDashOffset(remainingTime: number): number {
    const progress = 1 - (remainingTime / (2 * 60)); // 2 minutes total
    return this.CIRCLE_CIRCUMFERENCE * progress;
  }
}
