<!-- suggestion.component.html -->
<div class="container">
    <div class="content-wrapper">
        <div style="display: flex">
            <h2 class="main-title">Test Case Details</h2>
            <button mat-icon-button class="close-button" (click)="closeDialog()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="details-card">
            <div class="card-header">
                <h3>{{ runData?.testCase?.name || "Test Case" }}</h3>
                <p class="description" innerHtml="{{runData?.testCase?.description}}">
                    <!-- {{ runData?.testCase?.description || "No description available" }} -->
                </p>
            </div>

            <div class="test-summary">
                <div class="run-info">
                    <span class="run-number">Run #{{ runData?.index }}</span>
                </div>
                <div class="action-buttons">
                    <button (click)="addorupdateallData()" mat-icon-button matTooltip="Approve All" class="approve-btn">
                        <mat-icon>done_all</mat-icon>
                    </button>
                    <button (click)="rehectall()" mat-icon-button matTooltip="Deny All" class="deny-btn">
                        <mat-icon>clear_all</mat-icon>
                    </button>
                </div>
            </div>

            <div class="divider"></div>
            <!-- Test Steps List -->
            <div class="steps-container" *ngFor = "let data of testCaseSteps">
                <div class="step-item">
                    <div class="step-content">
                        <div style="display: flex; gap: 10px;align-items: baseline;">
                            <span class="step-number">{{data.Step}}</span> {{data.Description}}
                            <div class="option-actions">
                                <button (click)="addorupdateData(data)" mat-icon-button matTooltip="Approve" class="approve-btn">
                                    <mat-icon>check_circle</mat-icon>
                                </button>
                                <button mat-icon-button matTooltip="Deny" class="deny-btn">
                                    <mat-icon>cancel</mat-icon>
                                </button>
                            </div>
                        </div>
                        <div class="step-info">
                            <!-- Step Options -->
                            <div class="step-options">
                                <div class="option-item-bg mb-20">
                                    <div class="option-item">
                                        <span class="option-label">Test Step Summary</span>
                                        <!-- <div class="option-actions">
                                            <button mat-icon-button matTooltip="Approve Image" class="approve-btn">
                                                <mat-icon>check_circle</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Deny Image" class="deny-btn">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </div> -->
                                        </div>
                                        <div class="comparison-columns">
                                            <div class="comparison-column original">
                                                <div class="step-action-box"> 
                                                    <span *ngIf="data.Type != 'Create'" class="label mt-12" [innerHTML]="'- ' + (failedSteps[0]?.action || '')"></span>
                                                </div>
                                            </div>
                                            <div class="comparison-column modified">
                                                <div class="step-action-box">
                                                    <span class="label mt-12">+ {{data.newStep}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                </div>
                                                <div>
                                                    <div style="font-size: 14px;
                                                                                                                                                                                                    margin-bottom: 14px;"
                                                        class="version-label"><b>Action: </b> {{data.Type}}
                                                    </div>
                                                    <div style="font-size: 14px;
                                                                                                                                                                                                    margin-bottom: 14px;"
                                                        class="version-label"><b>Action Type: </b> {{data.Action}}
                                                    </div>
                                                    <div style="font-size: 14px;
                                                                                                                                                                                                    margin-bottom: 14px;"
                                                        class="mt-5">
                                                    <b> AI Suggestion:</b>
                                                    {{data.Reason}}
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div *ngIf="failedSteps && failedSteps[0]?.screenshot" class="option-item-bg mb-20">
                                            <div class="option-item">
                                        <span class="option-label">Images</span>
                                        <!-- <div class="option-actions">
                                            <button mat-icon-button matTooltip="Approve Image" class="approve-btn">
                                                <mat-icon>check_circle</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Deny Image" class="deny-btn">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </div> -->
                                    </div>
                                    <div  class="comparison-columns">
                                        <div class="comparison-column original">
                                            <div class="step-screenshot"> {{data.Type}}
                                            - <img *ngIf="data.Type != 'Create'" src="{{failedSteps[0].screenshot}}" alt="Original screenshot" (click)="openScreenShort('{{failedSteps[0].screenshot}}')" />
                                            </div>
                                        </div>
                                        <div class="comparison-column modified">
                                            <div class="step-screenshot">
                                            + <img src="{{failedSteps[0].screenshot}}" alt="New screenshot" (click)="openScreenShort('{{failedSteps[0].screenshot}}')" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="option-item-bg mb-20">
                                    <div class="option-item">
                                        <span class="option-label">Metadata</span>
                                        <!-- <div class="option-actions">
                                            <button mat-icon-button matTooltip="Approve Metadata" class="approve-btn">
                                                <mat-icon>check_circle</mat-icon>
                                            </button>
                                            <button mat-icon-button matTooltip="Deny Metadata" class="deny-btn">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                        </div> -->
                                    </div>
                                    <div class="comparison-columns">
                                        <div class="comparison-column original">
                                            <div class="step-action-box">
                                                <span class="label mt-12" *ngIf="data.Type != 'Create'">
                                                    - {{ failedSteps[0]?.metadata || 'There is no any metadata' }}
                                                </span>
                                            </div>
                                        </div>
                                        <div class="comparison-column modified">
                                            <div class="step-action-box">
                                                <span class="label mt-12">+ {{data.metadata}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="option-item">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <span class="option-label">Assertions</span>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    <div class="option-actions">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <button mat-icon-button matTooltip="Approve Assertions" class="approve-btn">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <mat-icon>check_circle</mat-icon>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        <button mat-icon-button matTooltip="Deny Assertions" class="deny-btn">
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            <mat-icon>cancel</mat-icon>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                        </button>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div>
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    </div> -->
                            </div>
                        </div>
                    </div>
                    </div>
                    </div>

        </div>
        <div *ngIf="loading" style="display: flex; justify-content: center; padding: 20px; align-items: center; height: 100%;">
            <div class="loader">Loading...</div>
        </div>
    </div>
</div>