import {Injectable} from '@angular/core';
import {HttpHeadersService} from "../shared/services/http-headers.service";
import {UrlConstantsService} from "../shared/services/url.constants.service";
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {UserPreference} from "../models/user-preference.model";
import {FilterTimePeriod} from "../enums/filter-time-period.enum";
import {InfiniteScrollableDataSource} from "../data-sources/infinite-scrollable-data-source";
import {Upload} from "../shared/models/upload.model";

@Injectable({
  providedIn: 'root'
})
export class UserPreferenceService {

  constructor(
    private http: HttpClient,
    private httpHeaders: HttpHeadersService,
    private URLConstants: UrlConstantsService) {
  }


  private daysFilter = new BehaviorSubject({});
  daysFilter$ = this.daysFilter.asObservable();

  setDaysFilter(data) {
    this.daysFilter.next(data);
  }

  public show(): Observable<UserPreference> {
    return this.http.get<UserPreference>(this.URLConstants.userPreferencesUrl).pipe(
      map(data => new UserPreference().deserialize(data)),
      catchError(() => throwError('Problem while fetching UserPreference'))
    )
  }

  public getFeatureCount(): any {
    return this.http.get(this.URLConstants.testCaseMatrix).pipe(
      map(data => data),
      catchError(() => throwError('Problem while fetching Count'))
    )
  }

  public getHealth(data): any {
    return this.http.post<Upload>(this.URLConstants.dashboardHealth , data, {
    }).pipe(
      map(data => data),
      catchError((exception) => throwError(exception))
    )
  }

  public getTrends(data): any {
    return this.http.post<Upload>(this.URLConstants.dashboardTrends , data, {
    }).pipe(
      map(data => data),
      catchError((exception) => throwError(exception))
    )
  }

  public getFeatureCountByDays(params): any {
    return this.http.get(this.URLConstants.testCaseMatrixDay, {params}).pipe(
      map(data => data),
      catchError(() => throwError('Problem while fetching Count'))
    )
  }

  save(userPreference: UserPreference): Observable<UserPreference> {
    return this.http.put<UserPreference>(this.URLConstants.userPreferencesUrl, userPreference.serialize()).pipe(
      map(data => new UserPreference().deserialize(data)),
      catchError(() => throwError('Problem while saving UserPreference'))
    )
  }

  isExtensionInstalled(extensionId: string): Promise<boolean> {
    return new Promise((resolve) => {
      if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
        chrome.runtime.sendMessage(extensionId, 'ping', response => {
          resolve(!!response);
        });
      } else {
        resolve(false);
      }
    });
  }
}
