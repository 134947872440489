import { Component, Inject, OnInit, Input } from '@angular/core';

import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { TestStepService } from 'app/services/test-step.service';

import { TestStep } from "../../models/test-step.model";

import { HttpClient } from '@angular/common/http';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';




@Component({

  selector: 'app-errorfix-type-popup',

  template: `

  <div class="theme-overlay-container">

      <div class="theme-overlay-header">

        <span class="theme-overlay-title">Quick Resolve Steps</span>

        <button class="theme-overlay-close"

                type="button"

                [matTooltip]="'hint.message.common.close' | translate"

                mat-dialog-close>

        </button>

      </div>

      <div class="content-center">

        <p *ngIf="stepData.type === 'TS_TIMEOUT'" class="step-type">Increase Testcase timeout</p>

        <p *ngIf="stepData.type === 'LOCATOR_ND'" class="step-type">Update Element</p>

    <form [formGroup]="form" (ngSubmit)="save()">

  <mat-form-field appearance="outline">
    <input matInput class="form-control"

           [type]="stepData.type === 'TS_TIMEOUT' ? 'number' : 'text'"

           formControlName="value"

           [placeholder]="stepData.type === 'TS_TIMEOUT' ? 'Enter timeout' : 'Enter element locator'">
    <mat-label>{{ stepData.type === 'TS_TIMEOUT' ? 'Timeout (in seconds)' : 'Element Locator' }}</mat-label>
    <mat-error style="margin-top: 12px;" *ngIf="form.get('value').hasError('required')">
      {{ stepData.type === 'TS_TIMEOUT' ? 'Timeout' : 'Element Locator' }} is required
    </mat-error>

    <mat-error style="margin-top: 12px;" *ngIf="stepData.type === 'TS_TIMEOUT' && form.get('value').hasError('min')">

      Timeout must be at least 1 second

    </mat-error>

    <mat-error style="margin-top: 12px;" *ngIf="stepData.type === 'TS_TIMEOUT' && form.get('value').hasError('max')">

      Timeout cannot exceed 300 seconds

    </mat-error>

  </mat-form-field>

  <div class="button-group">

    <button mat-raised-button color="primary" type="submit" [disabled]="form.invalid">Save</button>

    <button mat-button type="button" mat-dialog-close = 'Cancel'>Cancel</button>

  </div>

</form>

      </div>

    </div>

  `,

  styles: [`

    .theme-overlay-container {

      background-color: #f5f5f5;

      border-radius: 8px;

    }

    .theme-overlay-header {

      display: flex;

      justify-content: space-between;

      align-items: center;

      margin-bottom: 20px;

    }

    .theme-overlay-title {

      font-size: 24px;

      font-weight: bold;

    }

    .theme-overlay-close {

      background: none;

      border: none;

      cursor: pointer;

    }

    .content-center {

      display: flex;

      flex-direction: column;

      align-items: center;

    }

    .step-type {

      font-size: 18px;

      margin-bottom: 20px;

      color: #333;

    }

    form {

      width: 100%;

      max-width: 300px;

    }

    mat-form-field {

      width: 100%;

    }

    .button-group {

      display: flex;

      justify-content: space-between;

      margin-top: 20px;

    }

  `]

})

export class errorFixTypepopupComponent implements OnInit {

  get testStep(): TestStep {

    return this.option.testStep;

  }




  public testStepId: any;

  form: FormGroup;

  stepData;

  matchingStepData: any;




  constructor(

    @Inject(MAT_DIALOG_DATA) public option: { testStepData: any, testStep: TestStep, matchingStepData: any },

    private testStepService: TestStepService,

    private http: HttpClient,

    private fb: FormBuilder,

    private dialogRef: MatDialogRef<errorFixTypepopupComponent>

  ) {

    this.stepData = this.option.testStepData;

    this.matchingStepData = this.option.matchingStepData;

    this.testStepId = this.option.testStepData.failed_step_id;

    console.log(this.stepData)

  }




  ngOnInit() {

    this.form = this.fb.group({

      value: ['', [Validators.required, ...(this.stepData.type === 'TS_TIMEOUT' ? [Validators.min(1), Validators.max(1000)] : [])]]

    });

  }




  save() {

    if (this.form.valid) {

      const value = this.form.get('value').value;

      const updatedStepData = JSON.parse(JSON.stringify(this.matchingStepData));

      let payload: any;




      if (this.stepData.type === 'TS_TIMEOUT') {

        updatedStepData.event.value = value;

        updatedStepData.testData = value;

        updatedStepData.action = `Wait for <span data-key=\"test-data\" data-event-key=\"value\" class=\"test_data\">${value}</span> seconds`;

        payload = {

          ...updatedStepData,

          timeout: value

        };

      } else {

        // Update locator

        if (!Array.isArray(updatedStepData.event.pwLocator)) {

          updatedStepData.event.pwLocator = [];

        }

        updatedStepData.event.pwLocator.unshift(value);

        updatedStepData.event.selector = value;

        updatedStepData.element = value;

        updatedStepData.action = `Click on <span data-key=\"label\" data-event-key=\"label\" class=\"element action-flex-auto\">${value}</span>`;

        payload = {

          ...updatedStepData,

          locator: value

        };

      }

      this.testStepService.updateForQuick(payload).subscribe((data: any) => {

        this.dialogRef.close({ ...data, updatedStep: this.matchingStepData });

      }, (error: any) => {




      })

    }

  }










}