import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../../shared/components/base.component";
import {TestCaseService} from "../../services/test-case.service";
import {ActivatedRoute, Params, Router, RouterOutlet} from '@angular/router';
import {Page} from "../../shared/models/page";
import {TestCase} from "../../models/test-case.model";


@Component({
    selector: 'impact-analysis',
    templateUrl: './impact_analysis.component.html',
    host: {'class': 'page-content-container'},
    styles: []
  })

export class ImapctAnaysisListComponent extends BaseComponent implements OnInit {
    public testCases: Page<TestCase>;
    public testCaseResultId: Number;

    constructor(public testCaseService: TestCaseService,
                public route: ActivatedRoute,
                public router: Router,){super()}
    
    // constructor(){
    //     super();
    // }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            // this.pushToParent(this.route, params);
            this.testCaseResultId = params.resultId;

        })
        this.fetchImapctDataTestCases()
    }

    fetchImapctDataTestCases(){
        this.testCaseService.findAllImpactAnalysis(this.testCaseResultId as number).subscribe(res => {
            this.testCases = res;
        })
    }
}