import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {

  innerHTML:any;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
  }

  // cleanURL(url:string) {
  //   return this.sanitizer.bypassSecurityTrustUrl(url);
  // }

}
