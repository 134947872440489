import {Component, Input, OnInit} from '@angular/core';
import {WorkspaceVersion} from "../../models/workspace-version.model";
import {TestCaseService} from "../../services/test-case.service";
import {TestCaseTypesService} from "../../services/test-case-types.service";
import {InfiniteScrollableDataSource} from "../../data-sources/infinite-scrollable-data-source";
import {AuthenticationGuard} from "../../shared/guards/authentication.guard";
import {UserPreferenceService} from "../../services/user-preference.service";
import {UserPreference} from "../../models/user-preference.model";
import {FilterTimePeriod} from "../../enums/filter-time-period.enum";
import * as moment from "moment";
import { LicenceSevices } from "../../shared/services/license.service";
@Component({
  selector: 'app-test-case-by-count',
  templateUrl: './test-case-by-count.component.html',
  styles: []
})
export class TestCaseByCountComponent implements OnInit {
  @Input('version') version: WorkspaceVersion;
  public totalTestCases: InfiniteScrollableDataSource;
  public passedTestCases: InfiniteScrollableDataSource;
  public failedTestCases: InfiniteScrollableDataSource;
  public notExecutedTestCases: InfiniteScrollableDataSource;
  public userPreference: UserPreference;
  public entity: any;
  private defaultName = {
    "Pass": "#66ed66",
    "Fail": "#f34f4f",
    "Draft": "#c3c384"
  };
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  filterObj: any;

  constructor(
    private testCaseTypesService: TestCaseTypesService,
    public authGuard: AuthenticationGuard,
    private userPreferenceService: UserPreferenceService,
    private testCaseService: TestCaseService,
    public LicenceSevices: LicenceSevices,) {
  }

  ngOnInit(): void {
    this.entity = [];
    this.fetchUserPreference();
    this.updateTestCases(); // Call updateTestCases initially
    this.LicenceSevices.dayFilter$.subscribe((data: any) => {
      this.filterObj = data;
      if (data?.customDays) {
        this.period = data?.customDays;
        this.updateTestCases();
      }
    });
  }

  private async updateTestCases(): Promise<void> {
    this.totalTestCases = await  this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,workspaceVersionId:" + this.version.id);
    this.passedTestCases = await this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result:SUCCESS,workspaceVersionId:" + this.version.id);
    this.failedTestCases = await this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result@FAILURE#ABORTED,workspaceVersionId:" + this.version.id);
    this.notExecutedTestCases = await this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result@QUEUED#STOPPED#NOT_EXECUTED#null,workspaceVersionId:" + this.version.id);
    setTimeout(() => {
      this.entity = [];
      if (this.passedTestCases.totalElements > 0 || this.failedTestCases.totalElements > 0 || this.notExecutedTestCases.totalElements > 0) {
        this.entity.push(
          {
            name: 'Pass',
            color: this.defaultName['Pass'] ? this.defaultName['Pass'] : 'green',
            y: this.passedTestCases.totalElements
          },
          {
            name: 'Fail',
            color: this.defaultName['Fail'] ? this.defaultName['Fail'] : 'red',
            y: this.failedTestCases.totalElements
          },
          {
            name: 'Draft',
            color: this.defaultName['Draft'] ? this.defaultName['Draft'] : '#c3c384',
            y: this.notExecutedTestCases.totalElements
          }
        );
      }
    }, 10000);
  }


  fetchTestPlanResults(period: FilterTimePeriod, query) {
    // if (period != FilterTimePeriod.All_TIME) query += ",createdDate>" + this.getFormattedDate(period);
    // query += ",createdDate>" + this.getFormattedDate(period);
    if (this.filterObj?.createdDateRange?.status === 'VALID') {
      query += ",createdDate>" + moment.utc(this.filterObj?.createdDateRange.getRawValue().start).local().format("YYYY-MM-DD")
      query += ",createdDate<" + moment.utc(this.filterObj?.createdDateRange.getRawValue().end).local().format("YYYY-MM-DD")
    } else {
      if (period != FilterTimePeriod.All_TIME) query += ",createdDate>" + this.getFormattedDate(period);
    }
    if (this.filterObj?.tags?.length)
      query += ",tagId@" + this.filterObj.tags.join("#")
    return new InfiniteScrollableDataSource(this.testCaseService, query, "createdDate,desc", 25);
  }

  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
    }
  }

  fetchUserPreference() {
    if(this.authGuard) {
      this.userPreferenceService.show().subscribe(res => {
        this.userPreference = res;
      })
    } else {
      setTimeout( () => this.fetchUserPreference(), 300)
    }
  }

  getRandomNumber(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

}
