<div class="page-content" style="top: 10px;">
    <div class="d-flex fz-20 align-items-center mt-10 mb-15">
        <div class="list-header align-items-center min-height-set" style=" background: white;">
            <div class="ts-col-70 pr-10 d-flex align-items-center">
              <span class="ts-col-100-61">
              <app-inline-sort [heading]="'message.common.text.title'"></app-inline-sort>
              </span>
            </div>
            <div  class="ts-col-15" [translate]="'message.common.text.result'"></div>
            <div class="ts-col-15 px-25 ">
              <app-inline-sort [heading]="'message.common.text.status'"></app-inline-sort>
            </div>
          </div>
    </div>
    <div class="list-container h-90">
      <a class="list-view green-highlight lg-pm pl-10 text-t-secondary align-items-center min-h-62" [routerLink]="['/td', 'cases',  testCase.id]" *ngFor='let testCase of testCases?.content' style="background: ghostwhite;">
        <div class="ts-col-70 d-flex align-items-center">
          <div class="d-flex flex-wrap text-break  ts-col-100-61">
            <span class="ts-col-100 pb-0 list-title" [textContent]="testCase?.name"></span>
          </div>
        </div>
        <div class="ts-col-15">
          <a (click)="$event.preventDefault();$event.stopPropagation()" [routerLink]="['/td', 'test_case_results', testCase?.lastRun?.id]" *ngIf="testCase?.lastRun">
            <app-result-pie-chart-column [width]="30" [height]="30" [resultEntity]="testCase?.lastRun"></app-result-pie-chart-column>
          </a>
        </div>
        <div class="ts-col-15 px-25" [textContent]="'testcase.status_'+testCase?.status| translate"></div>
      </a>
      <p *ngIf="testCases?.content?.length == 0" style="
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      padding: 8px;
      background: ghostwhite;">Data not found</p>
    </div>
</div>
