<div class="latest-run-card ts-col-100 mt-30" style="margin-right: 20px;">
    <div class="run-result-chart">
        <span class="chart-title">Test Plans Results</span>
        <form class="example-form test-plan-list">
            <mat-form-field appearance="outline">
                <mat-select placeholder="Choose Run Results" [(ngModel)]="selectedValue"
                    (selectionChange)="onSelectChange($event)">
                    <mat-option *ngFor="let testPlan of testPlans?.content" [value]="testPlan">
                        {{testPlan.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </form>
    </div>
    <div class="d-flex flex-wrap">
        <div class="ts-col-100 chart-container" style="padding: 16px;" chartType="bar">
            <div class="loadingState" *ngIf="isLoadingGraph">
                Loading data...
            </div>
            <ng-container>
                <div class="chart-navigation">
                    <button class="nav-button" (click)="showPreviousData()" [disabled]="!canShowPrevious">
                       Previous
                    </button>
                    <button class="nav-button" (click)="showNextData()" [disabled]="!canShowNext">
                        Next
                    </button>
                </div>
                <canvas baseChart [data]="barChartData" [options]="barChartOptions" [width]="250" [height]="80"
                    [type]="barChartType">
                </canvas>
            </ng-container>
        </div>
    </div>
</div>