import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestCaseService } from "../../services/test-case.service";
import { OnBoarding } from '../../enums/onboarding.enum';
import { Router } from "@angular/router";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { UserPreferenceService } from "../../services/user-preference.service";
import { UserPreference } from "../../models/user-preference.model";
import { FilterTimePeriod } from "../../enums/filter-time-period.enum";
import * as moment from "moment/moment";
import { LicenceSevices } from "../../shared/services/license.service";
@Component({
  selector: 'app-test-case-coverage-count',
  template: `
      <div class="coverage-summary"  (click)="navigateToTestCase('all')" >
        <div class="align-items-center d-flex fa-test-cases-alt icon justify-content-around result-status-text-0"></div>
        <div class="align-items-baseline d-flex flex-column justify-content-center ts-col-50">
          <div class="dashboard-secondary-title" [translate]="'dashboard.summary.total'"></div>
          <div>
            <ng9-odometer style="font-size: 31px !important;" class="fz-17"  [number]="0" *ngIf="totalTestCases?.totalElements < 10"></ng9-odometer>
            <ng9-odometer style="font-size: 31px !important;"  class="fz-17"  [number]="totalTestCases?.totalElements"></ng9-odometer>
          </div>
        </div>
      </div>
      <div class="coverage-summary coverage-summary-grey" (click)="navigateToTestCase('passed')" >
        <div class="align-items-center d-flex justify-content-around icon fa-result-0 result-status-text-0"></div>
        <div class="align-items-baseline d-flex flex-column justify-content-center ts-col-50">
          <div  class="dashboard-secondary-title" [translate]="'dashboard.summary.passed'"></div>
          <div>
            <ng9-odometer style="font-size: 31px !important;"  class="fz-17"  [number]="0" *ngIf="passedTestCases?.totalElements < 10"></ng9-odometer>
            <ng9-odometer style="font-size: 31px !important;"   class="fz-17"  [number]="passedTestCases?.totalElements"></ng9-odometer>
          </div>
        </div>
      </div>
      <div class="coverage-summary" (click)="navigateToTestCase('failed')" >
        <div class="align-items-center d-flex justify-content-around icon fa-result-1-dashboard fa-result-1"></div>
        <div class="align-items-baseline d-flex flex-column justify-content-center ts-col-50">
          <div  class="dashboard-secondary-title" [translate]="'dashboard.summary.failed'"></div>
          <div>
            <ng9-odometer class="fz-17" style="font-size: 31px !important;"  [number]="0" *ngIf="failedTestCases?.totalElements < 10"></ng9-odometer>
            <ng9-odometer class="fz-17" style="font-size: 31px !important;"  [number]="failedTestCases?.totalElements"></ng9-odometer>
          </div>
        </div>
      </div>
      <div class="coverage-summary coverage-summary-grey" (click)="navigateToTestCase('notExecuted')" >
        <div class="align-items-center d-flex justify-content-around icon fa-result-3"></div>
        <div class="align-items-baseline d-flex flex-column justify-content-center ts-col-50">
          <div class="dashboard-secondary-title">Not Executed</div>

          <div>
            <ng9-odometer class="fz-17" style="font-size: 31px !important;"  [number]="0" *ngIf="failedTestCases?.totalElements < 10"></ng9-odometer>
            <ng9-odometer class="fz-17" style="font-size: 31px !important;"   [number]="notExecutedTestCases?.totalElements"></ng9-odometer>
          </div>
        </div>
      </div>
  `,
  styles: [
  ]
})
export class TestCaseCoverageCountComponent implements OnInit, OnDestroy {
  @Input('version') version: WorkspaceVersion;
  @Input('selectedValue') selectedValue;
  public totalTestCases: InfiniteScrollableDataSource;
  public passedTestCases: InfiniteScrollableDataSource;
  public failedTestCases: InfiniteScrollableDataSource;
  public notExecutedTestCases: InfiniteScrollableDataSource;
  public userPreference: UserPreference;
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  filterObj:any;

  constructor(
    public router: Router,
    public authGuard: AuthenticationGuard,
    private userPreferenceService: UserPreferenceService,
    private testCaseService: TestCaseService,
    public LicenceSevices: LicenceSevices,) { }

  async ngOnInit() {
    this.fetchUserPreference();
    this.LicenceSevices.dayFilter$.subscribe((data:any) => {
      this.filterObj = data;
      if (data?.customDays) {
        this.period = data.customDays;
        this.totalTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,workspaceVersionId:" + this.version.id);
        this.passedTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result:SUCCESS,workspaceVersionId:" + this.version.id);
        this.failedTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result@FAILURE#ABORTED,workspaceVersionId:" + this.version.id);
        this.notExecutedTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result@QUEUED#STOPPED#NOT_EXECUTED#null,workspaceVersionId:" + this.version.id);
      }
    });
    this.totalTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,workspaceVersionId:" + this.version.id);
    this.passedTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result:SUCCESS,workspaceVersionId:" + this.version.id);
    this.failedTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result@FAILURE#ABORTED,workspaceVersionId:" + this.version.id);
    this.notExecutedTestCases = this.fetchTestPlanResults(this.period, "deleted:false,isStepGroup:false,result@QUEUED#STOPPED#NOT_EXECUTED#null,workspaceVersionId:" + this.version.id);
  }

  ngOnDestroy() {
    this.LicenceSevices.setDayFilter(null);
  }

  fetchTestPlanResults(period: FilterTimePeriod, query) {
    if (this.filterObj?.createdDateRange?.status === 'VALID') {
      query += ",createdDate>" + moment.utc(this.filterObj?.createdDateRange.getRawValue().start).local().format("YYYY-MM-DD")
      query += ",createdDate<" + moment.utc(this.filterObj?.createdDateRange.getRawValue().end).local().format("YYYY-MM-DD")
    } else {
      if (period != FilterTimePeriod.All_TIME) query += ",createdDate>" + this.getFormattedDate(period);
    }
    if (this.filterObj?.tags?.length)
      query += ",tagId@" + this.filterObj.tags.join("#")
    return new InfiniteScrollableDataSource(this.testCaseService, query, "createdDate,desc", 25);
  }

  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
    }
  }

  fetchUserPreference() {
    if (this.authGuard) {
      this.userPreferenceService.show().subscribe(res => {
        this.userPreference = res;
      })
    } else {
      setTimeout(() => this.fetchUserPreference(), 300)
    }
  }

  navigateToTestCase(result?) {
    var query;
    // if (this.period != FilterTimePeriod.All_TIME) query += ",createdDate>" + this.getFormattedDate(this.period);
    // const query = ",createdDate>" + this.getFormattedDate(this.period);
    if (this.filterObj?.createdDateRange?.status === 'VALID') {
      query += ",createdDate>" + moment.utc(this.filterObj?.createdDateRange.getRawValue().start).local().format("YYYY-MM-DD")
      query += ",createdDate<" + moment.utc(this.filterObj?.createdDateRange.getRawValue().end).local().format("YYYY-MM-DD")
    } else {
      if (this.period != FilterTimePeriod.All_TIME) query += ",createdDate>" + this.getFormattedDate(this.period);
    }
    this.router.navigate(['/td', this.version.id, 'cases', 'filter', 2], { queryParams: { result: result, tempFilter: true, q: query } });
  }

}
