<header>
    <div class="left-section">
        <app-workspace-switcher class="position-relative web-workspace" [leftNavPath]="detailsAliasName"
            (onProjectSwitch)="closeDialog()" [version]="version"></app-workspace-switcher>
    </div>
    <div class="right-section">
        <button *ngIf="!isExtensionInstalled" class="install-extension">Install Extension</button>
        <div class="quick-actions">
            <span class="search-icon">
                <img src="/assets/images/search.svg" width="24" height="24" alt="Search">
            </span>
            <input type="text" placeholder="Search or type a command">
        </div>
    </div>
</header>
<div class="container">
    <main>
        <div class="content">
            <div class="centered-content">
                <img src="/assets/images/landingrobot.svg" alt="Robot" class="robot-image">
                <h2 class="poppins-thin">Test Case Library</h2>
                <p class="inter">Create a new test case and find it here!</p>
                <button class="create-test" (click)="openDialog()">Create Test</button>
            </div>
        </div>
    </main>
</div>

<ng-template #dialogTemplate>
    <div mat-dialog-content class="test-case-dialog">
        <div class="dialog-header">
            <button mat-icon-button class="close-button" (click)="dialogReff.close()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div class="dialog-content">
            <a href="javascript:void(0)" class="primary-icon-plus">
                <div><i class="fa-plus-filled"></i></div>
            </a>
            <h2>Create Test Case</h2>
            <p style="font-size: 18px !important;">Select or open a new tab to start capturing steps automatically</p>
            <form [formGroup]="urlForm" (ngSubmit)="onSubmit()">
                <div appearance="fill" class="url-input">
                    <input matInput placeholder="Enter website name" formControlName="url" class="form-control"
                        >
                    <mat-error *ngIf="urlForm.get('url')?.hasError('required')">URL is required</mat-error>
                    <mat-error *ngIf="urlForm.get('url')?.hasError('invalidUrl')">Please enter a valid https://
                        URL</mat-error>
                </div>
                <div class="test-list-container">
                    <ul class="test-list">
                        <li *ngFor="let tab of tabs" class="test-item">
                            <img [src]="tab.favIconUrl || ''" alt="Tab icon" class="tab-icon">
                            <span class="tab-title">{{ tab.title }}</span>
                            <span class="tab-url">{{ tab.url }}</span>
                        </li>
                    </ul>
                </div>
                <div class="btn">
                    <button type="submit" class="create-test" [disabled]="!urlForm.valid">Create Test</button>
                </div>
                <div class="text-center sm mt-20" style="color: #5a67d8;cursor: pointer;"
                    [routerLink]="['/td', versionId, 'cases', 'create']" (click)="closeDialog()">
                    Manually create a test step
                </div>
            </form>
        </div>
    </div>
</ng-template>