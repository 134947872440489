import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Integrations} from "../../shared/models/integrations.model";
import {IntegrationsService} from "../../shared/services/integrations.service";
import {JiraIssueField} from "../../models/jira-issue-field.model";
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {JiraProject} from "../../models/jira-project.model";
import {JiraIssueType} from "../../models/jira-issue-type.model";
import {TestCaseResultExternalMapping} from "../../models/test-case-result-external-mapping.model";
import {TestCaseResult} from "../../models/test-case-result.model";
import {TestCaseResultExternalMappingService} from "../../services/test-case-result-external-mapping.service";
import {BaseComponent} from "../../shared/components/base.component";
import {AuthenticationGuard} from "../../shared/guards/authentication.guard";
import {NotificationsService} from "angular2-notifications";
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";
import {MatDialog} from "@angular/material/dialog";
import {JiraFieldAllowedValue} from "../../models/jira-field-allowed-value.model";
import {catchError, debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {ResultConstant} from "../../enums/result-constant.enum";
import {TestCase} from "../../models/test-case.model";
import {TestCaseExternalMapping} from "../../models/test-case-external-mapping.model";
import { TestStepService } from 'app/services/test-step.service';
import { Router } from '@angular/router';
import { TestCasePrioritiesService } from 'app/services/test-case-priorities.service';
import { TestCaseTypesService } from 'app/services/test-case-types.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-jira-issue-form',
  templateUrl: './jira-issue-form.component.html',
  styles: []
})
export class JiraIssueFormComponent extends BaseComponent implements OnInit {
  @Input('application') application: Integrations;
  @Input('testCaseResult') testCaseResult: TestCaseResult;
  @Input('testCase') testCase: TestCase;
  @Output('onCreate') createCallBack = new EventEmitter<TestCaseResultExternalMapping>();
  @Output('onCreateTestCase') createCallBackTestCase = new EventEmitter<TestCaseExternalMapping>();
  public fields: JiraIssueField[];
  public summaryValue: any;
  public multiSelectFields: JiraIssueField[];
  form: FormGroup;
  public projects: JiraProject[];
  public issueTypes: JiraIssueType[];
  public selectedProject: JiraProject;
  public selectedIssueType: JiraIssueType;
  public isProjectShow: boolean = false;
  public isIssueTypeShow: boolean = false;
  public selectedPriority: JiraFieldAllowedValue;
  public issueList: any;
  public selectedIssue: any;
  public isLinkToIssue: boolean = false;
  public searchIssuesFormCtrl: FormControl = new FormControl();
  public isFetchingIssues: Boolean = false;
  @Input("isButtonClicked") isButtonClicked: Boolean;
  public testSteps:any;
  public testStepsId:any;

  constructor(
    private router: Router,
    public testStepService: TestStepService,
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private mappingService: TestCaseResultExternalMappingService,
    private applicationService: IntegrationsService,
    private testCasePriorityService: TestCasePrioritiesService,
    private testCaseTypeService: TestCaseTypesService,
    public dialog: MatDialog) {
    super(authGuard, notificationsService, translate, toastrService)
  }

  ngOnInit(): void {
    this.applicationService.getJiraFields(this.application.id).subscribe(projects => {
      if (projects && projects[0] && projects[0].issueTypes && projects[0].issueTypes[0]) {
        this.projects = projects;
        this.selectedProject = projects[0];
        this.issueTypes = projects[0].issueTypes;
        this.setBugIssueType();

        this.fields = this.selectedIssueType.formFields;
        this.multiSelectFields = this.filterMultiSelectFields();
        this.selectedIssue = undefined;
        this.initFormControl();
      }
    });

    
    this.testStepService.currentTestCaseId.subscribe(id => {
      this.testStepsId = id;
      
    });
    this.fetchSteps()

    this.testCasePriorityService.show(this.testCase.priorityId).subscribe(res => {
      this.testCase.testCasePriority = res;
    })
    this.testCaseTypeService.show(this.testCase.type).subscribe(res => {
      this.testCase.testCaseType = res;
    })
  }

  ngOnChange(namespace :SimpleChanges) : void {
    this.fetchSteps()
  }

  fetchSteps() {
    let query = "testCaseId:" + this.testStepsId;
    // let query = "testCaseId:" + this.testStepsId;
    this.testStepService.findAll(query, 'position').subscribe(res => {
      this.testSteps = res;
    })
  }

  filterMultiSelectFields() {
    return this.fields.filter((f) => (f?.schema["custom"] == "com.atlassian.jira.plugin.system.customfieldtypes:multiselect"));
  }

  setBugIssueType() {
    this.selectedIssueType = this.issueTypes.find(type => type.name == 'Test') || this.issueTypes[0];
  }

  initFormControl() {
    const group: any = {};
    this.fields.forEach(field => {
      if(field)
        group[field.key.toString()] = field.required ? new FormControl(undefined, Validators.required)
          : new FormControl(undefined);
    })
    this.form = new FormGroup(group);
    let mapping = new TestCaseResultExternalMapping();
    mapping.fields = this.form.getRawValue();
    mapping.fields = this.formatMultiSelectFields(mapping.fields);
    if(this.testCase){
      this.form.controls.summary.setValue("[Automated Test Case]"+this.testCase.name);
      this.form.controls.description.setValue(this.getTestJiraDescription(''));
    }else{
      this.form.controls.description.setValue(this.getJiraDescription('')+"\n");
      this.form.controls.summary.setValue("[Automated Test Failed]"+this.testCaseResult.testCase.name);
    }
  }

  fetchIssuesByTerm(term?: any) {
    return this.applicationService.searchJiraIssues(this.application.id, this.selectedProject.id, this.selectedIssueType.id, term)
  }

  displayIssueAutocomplete(issue): string {
    if (issue) {
      return '[' + issue['key'] + '] ' + issue['fields']['summary'];
    }
  }

  formatMultiSelectFields(mappingFields: Map<String, Object>) {
    this.multiSelectFields.forEach(field => {
      const fieldKey = field?.key.toString();
      if (mappingFields.hasOwnProperty(fieldKey) && (mappingFields[fieldKey] instanceof Array)) {
        const values = <Array<string>>mappingFields[fieldKey];
        const selectedValues = [];
        values.forEach(value => {
          selectedValues.push({
            "value": value
          });
        });
        mappingFields[fieldKey] = selectedValues;
      }
    });
    return mappingFields;
  }

  onSubmit() {
    if(this.testCaseResult){
      let mapping = new TestCaseResultExternalMapping();
      if (this.searchIssuesFormCtrl.value) {
        mapping.linkToExisting = true;
        mapping.externalId = this.searchIssuesFormCtrl.value.key;
        mapping.workspaceId = <number>this.application.id;
        mapping.testCaseResultId = this.testCaseResult.id;
      } else {
        mapping.workspaceId = <number>this.application.id;
        mapping.testCaseResultId = this.testCaseResult.id;
        mapping.fields = this.form.getRawValue();
        mapping.fields = this.formatMultiSelectFields(mapping.fields);
        mapping.fields["priority"] = {"name": this.selectedPriority.name};
        mapping.fields["project"] = {"id": this.selectedProject.id};
        mapping.fields["issuetype"] = {"id": this.selectedIssueType.id};
        var description="";
        mapping.fields["description"] = this.form.controls.description.value || '';
        mapping.fields["summary"] = this.form.controls.summary.value || '';
      }
      this.createCallBack.emit(mapping);
    }else {
      let mappings = new TestCaseExternalMapping();
      if (this.searchIssuesFormCtrl.value) {
        mappings.linkToExisting = true;
        mappings.externalId = this.searchIssuesFormCtrl.value.key;
        mappings.workspaceId = <number>this.application.id;
        mappings.testCaseId = this.testCase.id;
      } else {
        mappings.workspaceId = <number>this.application.id;
        mappings.testCaseId = this.testCase.id;
        mappings.fields = this.form.getRawValue();
        mappings.fields = this.formatMultiSelectFields(mappings.fields);
        mappings.fields["priority"] = {"name": this.selectedPriority.name};
        mappings.fields["project"] = {"id": this.selectedProject.id};
        mappings.fields["issuetype"] = {"id": this.selectedIssueType.id};
        var description="";
        mappings.fields["description"] = this.form.controls.description.value || '';
        mappings.fields["summary"] = this.form.controls.summary.value || '';

      }
      this.createCallBackTestCase.emit(mappings);
    }




  }

  getJiraDescription(description){

    var JIRA_DESCRIPTION="Test Description";
    var JIRA_IS_DATA_DRIVEN="Data Driven";
    var JIRA_TEST_CASE_RESULT="Test Case Result";
    var JIRA_RUN_NAME="Run Name";
    var JIRA_RUN_ID="Run Id";
    var JIRA_RUN_CONFIG="Run Config";
    var JIRA_START_TIME="Start Time";
    var JIRA_DURATION="Duration";
    var JIRA_SUMMARY="Summary";
    var JIRA_STEPS="steps";
    var JIRA_MESSAGE="Message";

    var desc = this.form.value.description == null ? "' '" : this.form.value.description;
    // var desc = this.form.value.description

    description = JIRA_DESCRIPTION+" : "+" "+desc+"\n";
    var isDataDriven = (this.testCaseResult.testCase.isDataDriven)? 'Yes,' : 'No,';
    description =  description + JIRA_IS_DATA_DRIVEN + " : " + isDataDriven + "\n" ;
    description =  description +  JIRA_TEST_CASE_RESULT + " : " +  " " + this.testCaseResult.result +"\n" ;
    description =  description + JIRA_RUN_NAME + " : " + this.testCaseResult?.testDeviceResult.testPlanResult.testPlan.name + ", " + JIRA_RUN_ID + " : "+ this.testCaseResult?.testDeviceResult.testPlanResult.id +"\n";
    description =  description + JIRA_RUN_CONFIG + " : " + this.testCaseResult?.testDeviceResult.testPlanResult.testPlan.testPlanLabType + "\n"
      + this.testCaseResult?.testDeviceResult.testDevice.platform + '(' + this.testCaseResult?.testDeviceResult.testDevice.osVersion + ')' + ", "
      + this.testCaseResult?.testDeviceResult.testDevice.browser + '(' + this.testCaseResult?.testDeviceResult.testDevice.browserVersion +')' +" ";

    var duration = this.testCaseResult.duration;

    description =  description + JIRA_START_TIME + " : " + new Date(this.testCaseResult.startTime) + ", " + JIRA_DURATION + " : " + duration + " ms"+"\n";
    description =  description + JIRA_SUMMARY + " : " + this.testCaseResult.totalCount + "  " + JIRA_STEPS + ", " + this.getStatusString() +"\n";
    var link = window.location.href
    description =  description + JIRA_MESSAGE + " : " + this.replaceMsgSpecialCharcter(this.replaceHtml(this.testCaseResult.message))
      +", Click here to see the below link  "+ link +"\n"+"\n";
    
    description = description + "Test Steps:"+"\n";

    for (let i = 0; i < this.testSteps.content.length; i++) {
      const position = this.testSteps.content[i].position;
      const action = this.testSteps.content[i].action
      const span = document.createElement('span');
      span.innerHTML = action;
      const textContent = span.innerText;
      description = description + position +"."+ textContent +"\n";
    }
    

    return description;
  }

  extractTextContent(htmlString) {
    const parser = new DOMParser();
    const parsedHtml = parser.parseFromString(htmlString, 'text/html');
    return parsedHtml.documentElement.textContent.trim();
  }

  getTestJiraDescription(description){
    // description = this.replaceMsgSpecialCharcter(this.replaceHtml(this.testCase.description));

    var Test_Case_Priority="Test Case Priority";
    var Labels="Labels";
    var JIRA_Test_Case_Type="Test Case Type";
    var JIRA_Prerequisite="Prerequisite";
    var JIRA_Data_Profile="Data Profile";
    var JIRA_Data_Driven="Data Driven";
    var JIRA_Updated_At="Updated At";
    var JIRA_Created_At="Created At";
    var JIRA_Description="Description";

    description = Test_Case_Priority + " : " + (this.testCase?.testCasePriority?.displayName ? this.testCase?.testCasePriority?.displayName : '-')+"\n";
    description = description + JIRA_Test_Case_Type + " : " + (this.testCase?.testCaseType?.displayName ? this.testCase?.testCaseType?.displayName : '-')+"\n";
    description = description + JIRA_Prerequisite + " : " + (this.testCase?.preRequisite ? this.testCase?.preRequisiteCase?.name : '-')+"\n";
    description = description + JIRA_Data_Profile + " : " + (this.testCase?.testData?.name ? this.testCase?.testData?.name : '-')+"\n";
    description = description + JIRA_Data_Driven + " : " + (this.testCase?.isDataDriven ? 'Yes' : 'No')+"\n";
    
    let updatedTime = formatDate(this.testCase?.updatedAt, 'h:mm a MMM d, y', 'en-US');
    description = description + JIRA_Updated_At + " : " + updatedTime +"\n";

    let createdTime = formatDate(this.testCase?.createdAt, 'h:mm a MMM d, y', 'en-US');
    description = description + JIRA_Created_At + " : " + createdTime +"\n";
    description = description + JIRA_Description + " : " + (this.testCase?.description ? this.extractTextContent(this.testCase.description) : '-')+"\n"+"\n";

    description = description + "Test Steps:"+"\n";

    for (let i = 0; i < this.testSteps.content.length; i++) {
      const position = this.testSteps.content[i].position;
      const action = this.testSteps.content[i].action
      // const span = document.createElement('span');
      // span.innerHTML = action;
      // const textContent = span.innerText;
      description = description + (position+1) +"."+ this.extractTextContent(action)+"\n";
    }
    return description;
  }

  getIterationText = function(metatdata){
    if(metatdata){
      var meta = JSON.parse(metatdata);
      if(meta['for_loop']){
        return meta['for_loop'].testdata+"-"+meta['for_loop'].itearation+"("+meta['for_loop'].index+")";
      }
    }
    return "";
  }
  getStatusString(){
    var statusStr = "";
    statusStr = this.testCaseResult.passedCount ? statusStr+this.testCaseResult.passedCount+" " +ResultConstant.SUCCESS :"";
    statusStr = this.testCaseResult.failedCount ? statusStr+", "+this.testCaseResult.failedCount+" " +ResultConstant.FAILURE :"";
    statusStr = this.testCaseResult.abortedCount ? statusStr+", "+this.testCaseResult.abortedCount+" " +ResultConstant.ABORTED : "";
    statusStr = this.testCaseResult.notExecutedCount ? statusStr+", "+this.testCaseResult.notExecutedCount+" " +ResultConstant.NOT_EXECUTED : "";
    statusStr = this.testCaseResult.queuedCount ? statusStr+", "+this.testCaseResult.queuedCount+" " +ResultConstant.QUEUED : "";
    statusStr = this.testCaseResult.stoppedCount ? statusStr+", "+this.testCaseResult.stoppedCount+" " +ResultConstant.STOPPED :"";
    return statusStr;
  }
  replaceHtml(message){
    message = message.replace("<br>","");
    message = message.replace("</br>","");
    return message.replace(/(<([^>]+)>)/ig,"");
  }

  replaceMsgSpecialCharcter(text){
    return text.replace("->", "");
  }

  regexIndexOf(startpos) {
    var anchorRegex = /<a.*?href\s+=\s+"(.*?)".*?>(.*?)<\/a>/ig;
    var indexOf = startpos.substring(startpos || 0).search(anchorRegex);
    return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf;
  }

  getHrefText(text) {
    var hrefRegex = /<a.*?href\s*=\s*"/ig;
   var match = text.match(hrefRegex);
    if(match && match.length){
      var start =  text.indexOf(match[0]) + match[0].length;
      var txt = text.substring(start, text.length);
      txt = txt.substring(0, txt.indexOf("\""));
      return txt;
    }
    return text;
  }
  toggleProject(project) {
    this.selectedProject = project ? project : this.selectedProject;
    this.isProjectShow = false;
    this.issueTypes = project.issueTypes;
    this.setBugIssueType();
    this.fields = this.selectedIssueType.formFields;
    this.initFormControl();
    if (this.isLinkToIssue) {
      this.fetchIssuesByTerm().subscribe(data => {
        if (data)
          this.issueList = data['issues'];
      })
    }
  }

  toggleIssueType(issueType) {
    this.selectedIssueType = issueType ? issueType : this.selectedIssueType;
    this.fields = this.selectedIssueType.formFields;
    this.isIssueTypeShow = false;
    this.initFormControl();
    if (this.isLinkToIssue) {
      this.fetchIssuesByTerm().subscribe(data => {
        if (data)
          this.issueList = data['issues'];
      })
    }
  }

  toggleDropdown(projectShow: string) {
    this[projectShow] = !this[projectShow];
  }

  setPriority(event: any) {
    this.selectedPriority = event;
  }

  toggleLinkToIssue() {
    this.isLinkToIssue = !this.isLinkToIssue;
    if (this.isLinkToIssue) {
      this.searchIssuesFormCtrl.valueChanges
        .pipe(
          debounceTime(500),
          tap(() => {
            this.issueList = [];
            this.isFetchingIssues = true;
          }),
          switchMap(value => {
            if (value && value.key)
              return [];
            else
              return this.fetchIssuesByTerm(value)
          })
        )
        .subscribe(data => {
          if (data)
            this.issueList = data['issues'];
          this.isFetchingIssues = false;
        });
      this.searchIssuesFormCtrl.setValue('');
    }
  }
}
