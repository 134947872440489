<div [class.with-br-bottom]="true" class="api-sidedrawer">
  <mat-form-field appearance="outline">
    <input
      type="text"
      matInput
      placeholder="API Name"
      required
      [formControl]="formGroup.get('title')"
    />
  </mat-form-field>
  <button
    class="theme-overlay-close"
    type="button"
    [matTooltip]="'hint.message.common.close' | translate"
    (click)="onCancelHandler()"
  ></button>
</div>
<mat-stepper #stepper>
  <mat-step label="Request Details" [stepControl]="requestForm">
    <br />
    <app-rest-form-request-details
      [form]="requestForm"
      [formSubmitted]="formSubmitted"
      [restStep]="restStep"
      (apiResponse)="setResponse($event)"
    ></app-rest-form-request-details>
    <br />
    <div class="btn-container">
      <button
        (click)="onCancelHandler()"
        class="theme-btn-clear-default"
        [translate]="'btn.common.cancel'"
      ></button>
      <button
        [isLoading]="saving"
        [message]="'message.common.saving'"
        appAsyncBtn
        matStepperNext
        class="theme-btn-primary"
        [textContent]="'btn.common.next' | translate"
        [disabled]="requestForm.controls['url'].invalid"
      ></button>
    </div>
  </mat-step>
  <!-- <mat-step label="Verify Response" [stepControl]="verifyForm">
    <br />
    <app-rest-form-response-details
      [form]="verifyForm"
      [formSubmitted]="formSubmitted"
      [restStep]="restStep"
    ></app-rest-form-response-details>
    <br />
    <div class="btn-container">
      <button
        matStepperPrevious
        class="theme-btn-clear-default"
        [translate]="'btn.common.back'"
      ></button>
      <button
        [isLoading]="saving"
        [message]="'message.common.saving'"
        appAsyncBtn
        matStepperNext
        class="theme-btn-primary"
        [textContent]="'btn.common.next' | translate"
      ></button>
    </div>
  </mat-step> -->
  <mat-step label="Store Response" [stepControl]="storeForm">
    <!-- <div style="display: flex;justify-content: center;padding-bottom: 22px;">
      <mat-slide-toggle>Store Response</mat-slide-toggle>
    </div> -->
    <input
        type="text"
        style="height: 42px;"
        class="form-control border-1 w-85 px-12 d-inline-block"
        [formControl]="storeForm.controls['variableName']"
        [placeholder]="'rest.step.store_variable' | translate"
        required>
        <mat-error *ngIf="storeForm.controls['variableName'].invalid && (storeForm.controls['variableName'].dirty || storeForm.controls['variableName'].touched)">
          Variable Name is required.
      </mat-error>
      <br />
      <br />
        <div class="btn-container">
          <button
          matStepperPrevious
          class="theme-btn-clear-default"
          [translate]="'btn.common.back'"
        ></button>
          <button
            [isLoading]="saving"
            [message]="'message.common.saving'"
            appAsyncBtn
            matStepperNext
            [disabled]="storeForm.controls['variableName'].invalid"
            class="theme-btn-primary"
            [textContent]="'btn.common.next' | translate"
          ></button>
        </div>

  </mat-step>
  <mat-step label="validation" [stepControl]="validationForm" >
    <app-rest-form-validation
    (varificationData)="handleData($event)"
    (varificationData1)="handleData1($event)"
    (jsonPathValid)="jsonPathValid($event)"
    [testStep] = "testStep.eventMap"
    >
    </app-rest-form-validation>

    <div class="btn-container" style="padding-top: 22px;">
      <button
        matStepperPrevious
        class="theme-btn-clear-default"
        [translate]="'btn.common.back'"
      ></button>
      <!-- isJsonPathValid removed json valid  -->
      <button
        [isLoading]="saving"
        [message]="'message.common.saving'"
        appAsyncBtn
        class="theme-btn-primary"
        [textContent]="'btn.common.create' | translate"
        [disabled]="requestForm.controls['url'].invalid ||
        storeForm.controls['variableName'].invalid"
        (click)="onSaveHandler()"
      ></button>
    </div>
  </mat-step>
</mat-stepper>

<!-- <div class="btn-container">
  <button
    (click)="cancel()"
    class="theme-btn-clear-default"
    [translate]="'btn.common.cancel'"
  ></button>
  <button
    *ngIf="!option?.testStep?.id"
    [isLoading]="saving"
    [message]="'message.common.saving'"
    appAsyncBtn
    (click)="save()"
    class="theme-btn-primary"
    [textContent]="'btn.common.create' | translate"
  ></button>
  <button
    *ngIf="option?.testStep?.id"
    [isLoading]="saving"
    [message]="'message.common.updating'"
    appAsyncBtn
    (click)="update()"
    class="theme-btn-primary"
    [textContent]="'btn.common.update' | translate"
  ></button>
</div> -->
<br />
<div
  class="rest-response-view pb-30 ts-col-100"
  [style]="{ height: '30rem', overflow: 'scroll' }"
>
  <div class="sub-title" [translate]="'rest.step.preview'"></div>
  <div class="response-container">
    <ngx-codemirror
      [(ngModel)]="response"
      [options]="{
        lineNumbers: true,
        lineWrapping: true,
        foldGutter: true,
        gutters: [
          'CodeMirror-linenumbers',
          'CodeMirror-foldgutter',
          'CodeMirror-lint-markers'
        ],
        mode: {
          name: 'javascript',
          json: true
        }
      }"
    ></ngx-codemirror>
    <iframe
      *ngIf="!isJSONResponse && false"
      class="response-iframe"
      [srcdoc]="response"
    ></iframe>
  </div>
</div>
