<!--
  ~ /*
  ~  * *****************************************************************************
  ~  *  Copyright (C) 2020 Testsigma Technologies Inc.
  ~  *  All rights reserved.
  ~  *  ****************************************************************************
  ~  */
  -->

<div class="theme-overlay-container">
  <div class="theme-overlay-header">
    <div
      class="theme-overlay-title"
      [translate]="'testcase.list.filter.title'">
    </div>
    <button
      class="close"
      type="button"
      [matTooltip]="'hint.message.common.close' | translate"
      mat-dialog-close>
    </button>
  </div>

  <div class="theme-overlay-filter-content theme-overlay-content">
    <form
      class="ts-form pt-30" id="testcaseStatusForm"
      (keydown.enter)="false"
      novalidate="novalidate">
      <input type="hidden" [value]="filterWorkspaceVersionId"/>
      <input type="hidden" [value]="filterDeleted"/>
      <div
        class="form-group ts-col-100 hide">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill">
          <mat-select
            disableOptionCentering
            (selectionChange)="constructQueryString()"
            [placeholder]="'message.common.any' | translate"
            [(value)]="filterStepGroup">
            <mat-option [value]=true [textContent]="'common.yes' | translate"></mat-option>
            <mat-option [value]=false [textContent]="'common.no' | translate"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.list.hint.step_group'"></label>
      </div>
      <div
        class="form-group ts-col-100">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill"
         [style]="disableStatus ? {'opacity':'0.4 !important','cursor':'not-allowed'} : null"  >
          <mat-select
            [disabled]="disableStatus"
            (selectionChange)="constructQueryString()"
            multiple
            disableOptionCentering panelClass="multiple"
            [placeholder]="'message.common.any' | translate"
            [(value)]="filterStatuses">
            <mat-option
              *ngFor="let testcaseStatus of statuses"
              [value]="testcaseStatus"
              [textContent]="'testcase.status_'+testcaseStatus | translate"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.summary.test_case_status'"></label>
      </div>
      <div class="form-group">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill"
          [style]="disableTestcaseType ? {'opacity':'0.4 !important','cursor':'not-allowed'} : null" >
          <mat-select
            [disabled]="disableTestcaseType"
            (selectionChange)="constructQueryString()"
            disableOptionCentering panelClass="multiple"
            [placeholder]="'message.common.any' | translate"
            multiple [(value)]="filterTestCaseTypes">
            <mat-option
              *ngFor="let type of testCaseTypesList?.content"
              [value]="type.id"
              [textContent]="type.displayName"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.summary.test_case_type'"></label>
      </div>
      <div class="form-group">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill"
          [style]="disablePriority ? {'opacity':'0.4 !important','cursor':'not-allowed'} : null" >
          <mat-select
            [disabled]="disablePriority"
            (selectionChange)="constructQueryString()"
            disableOptionCentering panelClass="multiple"
            [placeholder]="'message.common.any' | translate"
            multiple [(value)]="filterTestCasePriorities">
            <mat-option
              *ngFor="let result of testCasePrioritiesList?.content"
              [value]="result.id"
              [textContent]="result.displayName"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.summary.test_case_priority'"></label>
      </div>

      <div class="form-group" *ngIf="!isStepGroup">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill">
          <mat-select
            (selectionChange)="constructQueryString()"
            [placeholder]="'message.common.any' | translate"
            disableOptionCentering panelClass="multiple"
            multiple [(value)]="filterByResult">
            <mat-option
              *ngFor="let result of resultConstant"
              [value]="result"
              [textContent]="convertToResultTypeFormat(result)"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.summary.latest_run_result'"></label>
      </div>
      <!-- [disabled]="disableRunResult" -->
        <!-- [style]="disableRunResult ? {'opacity':'0.4 !important','cursor':'not-allowed'} : null" -->


      <div class="form-group" *ngIf="!isStepGroup">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill">
          <mat-select
            (selectionChange)="constructQueryString()"
            [placeholder]="'message.common.any' | translate"
            disableOptionCentering [(value)]="filterIsMappedToSuite">
            <mat-option
              *ngFor="let result of suiteMappingStatus"
              [value]="result"
              [textContent]="result"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.summary.test_suite_mapping'"></label>
      </div>

      <div class="form-group">
        <mat-form-field class="mat-date-custom w-100" appearance="fill">
          <mat-label [translate]="'testcase.list.created_date_placeholder'"></mat-label>
          <mat-date-range-input [rangePicker]="createdDateRangePicker" [formGroup]="createdDateRange" [max]="maxDate">
            <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>
            <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>
        </mat-form-field>
        <label class="control-label" [translate]="'message.common.createdDate'"></label>
        <div class="error"
             *ngIf="dateInvalid(createdDateRange) && !createdDateRangePicker.opened"
             [translate]="'form.validation.common.invalid' | translate:{FieldName: 'Created Date'}" ></div>
      </div>

      <div class="form-group">

        <mat-form-field class="mat-date-custom w-100" appearance="fill">
          <mat-label [translate]="'testcase.list.updated_date_placeholder'"></mat-label>
          <mat-date-range-input [rangePicker]="updatedDateRangePicker" [formGroup]="updatedDateRange" [max]="maxDate">
            <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>
            <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="updatedDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #updatedDateRangePicker></mat-date-range-picker>
        </mat-form-field>
        <label class="control-label" [translate]="'message.common.updatedDate'"></label>
        <div class="error"
             *ngIf="dateInvalid(updatedDateRange) && !updatedDateRangePicker.opened"
             [translate]="'form.validation.common.invalid' | translate:{FieldName: 'Updated Date'}" ></div>
      </div>

      <div class="form-group">
        <mat-form-field class="mat-select-custom w-100 example-full-width" appearance="fill">
          <mat-select
            (selectionChange)="constructQueryString()"
            [placeholder]="'message.common.any' | translate"
            disableOptionCentering panelClass="multiple"
            multiple [(value)]="filterTagIds">
            <mat-option
              *ngFor="let tag of tags"
              [value]="tag.id"
              [textContent]="tag.name"></mat-option>
          </mat-select>
        </mat-form-field>
        <label class="control-label" [translate]="'testcase.list.tags'"></label>
      </div>
    </form>
  </div>
  <div class="theme-overlay-footer">
    <button
      class="theme-btn-clear-default"
      mat-dialog-close
      [translate]="'btn.common.close'"></button>
    <button
      *ngIf="filterApplied"
      class="theme-btn-clear-default"
      [translate]="'btn.common.reset'"
      (click)="reset();"></button>
    <button
      class="theme-btn-primary"
      [translate]="'btn.common.filter'"
      [disabled]="disableFilter()"
      (click)="filter()"></button>
  </div>
</div>
