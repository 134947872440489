import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TestStep } from "../../models/test-step.model";
import { TestStepService } from "../../services/test-step.service";
import { ResultConstant } from "../../enums/result-constant.enum";
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestCase } from "../../models/test-case.model";
import { MobileRecorderEventService } from "../../services/mobile-recorder-event.service";
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { BaseComponent } from 'app/shared/components/base.component';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
interface TransformedObject {
  [key: string]: {
    [key: string]: string;
  };
}

@Component({
  selector: 'app-step-summary',
  templateUrl: './step-summary.component.html',
  styles: []
})
export class StepSummaryComponent extends BaseComponent implements OnInit {
  @Optional() @Input('stepDetails') stepDetails;
  public testStep: TestStep;
  public conditionIf: ResultConstant[] = [ResultConstant.SUCCESS, ResultConstant.FAILURE];
  public editedElement: String;
  stepsGroupVariable: any = [];
  isSpinner: boolean = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public options: {
      testStep: TestStep, version: WorkspaceVersion, testCase: TestCase,
      steps: TestStep[], isStepRecordView?: boolean
    },
    private testStepService: TestStepService,
    private matDialogRef: MatDialogRef<StepSummaryComponent>,
    private mobileRecorderEventService: MobileRecorderEventService,
    public notificationsService: NotificationsService,
    public authGuard: AuthenticationGuard,
    public toastrService:ToastrService,
    public translate: TranslateService,
  ) {
    super(authGuard,notificationsService,translate,toastrService)
    this.testStep = this.options.testStep;
    // this.getChildStep()
    this.getStepsVariable()
  }

  ngOnInit(): void {
    if (this.stepDetails) {
      this.testStep = this.stepDetails.testStep;
      this.options = this.stepDetails;
    }
    if (this.testStep && this.testStep.preRequisiteStepId) {
      this.testStep.preRequisiteStep = this.options.steps.find(step => step.id == this.testStep.preRequisiteStepId);
      if (!this.testStep.preRequisiteStep)
        this.testStepService.findAll("id:" + this.testStep.preRequisiteStepId).subscribe(resonse => {
          this.testStep.preRequisiteStep = resonse.content[0];
        });
    }
  }

  getStepsVariable() {
    this.testStepService
      .getStepsVariable(this.testStep.id)
      .subscribe((steps) => {
        this.stepsGroupVariable = steps;
        if (this.testStep.testDataFunctionArgs) {
          this.updateStepsGroupVariable();
        }
        this.isSpinner = false;
      }, error => {
        this.isSpinner = false;
      });
  }

  updateStepsGroupVariable() {
    this.stepsGroupVariable.forEach(group => {
      if (this.testStep.testDataFunctionArgs[group.id]) {
        group.variables?.forEach(variable => {
          const paramName = variable.name;
          if (this.testStep.testDataFunctionArgs[group.id][paramName]) {
            variable['parameterValue'] = this.testStep.testDataFunctionArgs[group.id][paramName];
          }
        });
      }
    });
  }

  // async getChildStep() {
  //   this.testStep.nestedStepGroup = [];
  //   if (this.testStep.stepGroupId == null) {
  //     return false
  //   }
  //   await this.testStepService
  //     .findAll("testCaseId:" + this.testStep.stepGroupId, "position")
  //     .subscribe((steps) => {
  //       console.log(this.testStep)
  //       const runtimeSteps = steps.content.filter((childStep: any) => childStep.testDataType === 'runtime');
  //       console.log("runtimeSteps>>>>>>>>", runtimeSteps);
  //       if (runtimeSteps.length > 0) {
  //         const childDataObject: any = {
  //           stepName: this.testStep.action,
  //           stepGroupId: this.testStep.stepGroupId,
  //           steps: runtimeSteps
  //         };
  //         this.testStep.nestedStepGroup.push(childDataObject);
  //       }
  //       console.log("this.testStep.nestedStepGroup", this.testStep.nestedStepGroup)
  //       // const testDataVal = childStep.testDataVal;
  //       // if (this.testStep.testDataFunctionArgs && this.testStep.testDataFunctionArgs.hasOwnProperty(testDataVal)) {
  //       //   steps.content[index].inputValue = this.testStep.testDataFunctionArgs[testDataVal];
  //       // }
  //       steps.content.forEach((childStep: any, index) => {
  //         if (childStep.actionName === 'STEP_GROUP') {
  //           this.getChildStepGroupData(childStep)
  //         }
  //       })
  //       // this.testStep.stepGroupSteps = steps;
  //       // this.testStep.stepGroupSteps.content.forEach((data: any) => {
  //       //   if (data.testDataType === 'runtime') {
  //       //     this.testStep.isRunTime = true;
  //       //   }
  //       // });
  //     });
  // }

  /** Get Child Step Group Data */
  // getChildStepGroupData(childData: any) {
  //   this.testStep.nestedStepGroup;
  //   this.testStepService
  //     .findAll("testCaseId:" + childData.stepGroupId, "position")
  //     .subscribe((steps: any) => {
  //       var childDataOject: any = {
  //         stepName: childData.action,
  //         steps: steps.content,
  //         stepGroupId: this.testStep.stepGroupId,
  //       }
  //       this.testStep.nestedStepGroup.push(childDataOject);
  //     });
  // }
  saveInputs(): void {
    const arr = this.stepsGroupVariable;
    const result: TransformedObject = {};
    arr.forEach(item => {
      const nestedObject: { [key: string]: string } = {};
      if (item.variables) {
        item.variables.forEach(step => {
          nestedObject[step.name] = step.parameterValue ? step.parameterValue : '';
        });
        result[item.id] = nestedObject;
      }
    });
    this.testStep.testDataFunctionArgs = JSON.parse(JSON.stringify(result));
    this.update();
  }

  update() {
    this.testStepService.update(this.testStep).subscribe((step) => {
      step.stepGroup = this.testStep.stepGroup;
      step.parentStep = this.testStep.parentStep;
      step.siblingStep = this.testStep.siblingStep;
      step.stepDisplayNumber = this.testStep.stepDisplayNumber;
      this.showNotification(NotificationType.Success, 'Runtime Variable  Updated successfully');
      this.closeSuggestion();
    }, error => {
    })
  }
  updateElement(element) {
    if (this.editedElement == this.testStep.element)
      this.testStep.element = element;
  }
  closeSuggestion() {
    this.stepDetails ? this.mobileRecorderEventService.setEmptyAction() : this.matDialogRef.close();
  }
}