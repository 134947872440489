import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {
    // Initialize Mixpanel with your project token
    mixpanel.init('f1666000b4ebe6d90d7727523b6f8e57', {
      debug: false, // Optional: Enable debugging
      track_pageview: true,
      property_blacklist: ['$device_id'],
      disable_persistence: true,
    });
  }
    // Method to identify the user
    identify(userId: string) {
      mixpanel.identify(userId);
    }

  // Method to track page views or custom events
  track(event: string, properties?: any) {
    const userId = window.location.hostname.split('.')[0] + "-" + localStorage.getItem('useremail')
    if (userId) {
      this.identify(userId); // Call identify method if userId is provided
    }
    mixpanel.track(event, properties);
  }

  
  // Method to set user properties
  setUserProperties(properties: any) {
    mixpanel.people.set(properties);
  }
}
