/*
 *
 *  * *****************************************************************************
 *  *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  *  All rights reserved.
 *  *  ****************************************************************************
 *
 */

import { Base } from "../shared/models/base.model";
import { PageObject } from "../shared/models/page-object";
import { custom, deserialize, object, serializable } from "serializr";
import { WorkspaceType } from "../enums/workspace-type.enum";
import { NaturalTextActionData } from "./natural-text-action-data.model";
import { TestStepConditionType } from "../enums/test-step-condition-type.enum";
import { StepActionType } from "../enums/step-action-type.enum";

export class NaturalTextActions extends Base implements PageObject {
  @serializable
  public workspaceType: WorkspaceType;
  @serializable
  public naturalText: String;
  @serializable
  public displayName: String;
  @serializable(
    custom(
      (v) => v,
      (v) => v
    )
  )
  public variables: Record<
    string,
    { path: string; default: string; mutable: boolean }
  >;
  @serializable
  public action: String;
  @serializable(
    custom(
      (v) => v,
      (v) => v
    )
  )
  public allowedValues: string[];
  @serializable(object(NaturalTextActionData))
  public data: NaturalTextActionData;
  @serializable(
    custom(
      (v) => v,
      (v) => v
    )
  )
  public stepActionType: StepActionType;

  get isConditionalWhileLoop(): Boolean {
    return this.stepActionType == StepActionType.WHILE_LOOP;
  }

  public displayOrder: number;
  public isAddon: boolean = false;

  deserialize(input: any): this {
    return Object.assign(this, deserialize(NaturalTextActions, input));
  }

  get extractTestDataString(): string {
    let testDataVar = this.naturalText.match(/\#\{([^}]+)\}/g);
    if (testDataVar != null)
      return testDataVar[0].replace(/[&#,+()$~%.'":*?<>{}]/g, "");
    else return "";
  }
  get htmlGrammar(): String {
    let naturalText = this.naturalText;
    const eventVars = {};
    const variables = {};
    if (this.variables) {
      Object.entries(
        this.variables as Record<string, { path: string }>
      ).forEach(([key, field]) => {
        variables[key] = field;
        eventVars[key] = field.path.split(".")[1];
      });
    }
    if (this.displayName === "restApi") {
      naturalText = naturalText
        .replace(
          /#{title}/g,
          `&nbsp;<span data-key="title" data-event-key="${
            eventVars["title"] || "title"
          }" class="rest">#{title}</span>&nbsp;`
        )
        .replace(
          /#{method}/g,
          `&nbsp;<span data-key="method" data-event-key="${
            eventVars["method"] || "method"
          }" class="rest">#{method}</span>&nbsp;`
        );
      return naturalText;
    }
    if (
      naturalText.match(/#{elementnameTo}/g) ||
      naturalText.match(/#{from-element}/g)
    ) {
      naturalText = naturalText.replace(
        /#{elementname}|#{from-element}/g,
        `<span data-key="from-element" data-event-key="${
          eventVars["from-element"] || "from-element"
        }" class="element">from element</span>`
      );
      naturalText = naturalText.replace(
        /#{elementnameTo}|#{to-element}/g,
        `<span data-key="to-element" data-event-key="${
          eventVars["to-element"] || "to-element"
        }" class="element">to element</span>`
      );

    }
    if (
      naturalText.match(/#{username}/g) ||
      naturalText.match(/#{password}/g)
    ) {
      naturalText = naturalText.replace(
        /#{elementname}|#{username}/g,
        `<span data-key="username" data-event-key="${
          eventVars["username"] || "username"
        }" class="test_data">username</span>`
      );
      naturalText = naturalText.replace(
        /#{elementnameTo}|#{password}/g,
        `<span data-key="password" data-event-key="${
          eventVars["password"] || "password"
        }" class="test_data">password</span>`
      );
    }
    if (
      naturalText.match(/#{source_value}/g) ||
      naturalText.match(/#{target_value}/g)
    ) {
      naturalText = naturalText.replace(
        /#{elementname}|#{source_value}/g,
        `<span data-key="source_value" data-event-key="${
          eventVars["source_value"] || "source_value"
        }" class="test_data">source_value</span>`
      );
      naturalText = naturalText.replace(
        /#{elementnameTo}|#{target_value}/g,
        `<span data-key="target_value" data-event-key="${
          eventVars["target_value"] || "target_value"
        }" class="test_data">target_value</span>`
      );
    }
    let span_class = this.allowedValues ? "selected_list" : "test_data";
    naturalText = naturalText
      .replace(
        /#{elementname}/g,
        `<span data-key="element" data-event-key="${
          eventVars["element"] || "element"
        }" class="element">element</span>`
      )
      .replace(
        /#{element}/g,
        `<span data-key="element" data-event-key="${
          eventVars["element"] || "element"
        }" class="element">element</span>`
      )
      .replace(
        /#{label}/g,
        `<span data-key="label" data-event-key="${
          eventVars["label"] || "label"
        }" class="element">label</span>`
      )
      .replace(
        /@{attribute}/g,
        `<span data-key="attribute" data-event-key="${
          eventVars["attribute"] || "attribute"
        }" class="attribute">attribute</span>`
      )
      .replace(
        /#{description}/g,
        `<span data-key="description" data-event-key="${
          eventVars["description"] || "description"
        }" class="element">description</span>`
      )
      .replace(
        /#{ai_task}/g,
        `<span data-key="element" data-event-key="${
          eventVars["ai_task"] || "ai_task"
        }" class="element">ai_task</span>`
      )
      .replace(
        /#{custom_code}/g,
        `<span data-key="custom_codecustom_code" data-event-key="${
          eventVars["custom_code"] || "custom_code"
        }" class="element">custom_code</span>`
      )
      .replace(
        /\#\{([^}]+)\}/g,
        `<span data-key="test-data" data-event-key="${
          eventVars["test-data"] || "test-data"
        }" class="${span_class}">${this.extractTestDataString}</span>`
      );
    return naturalText;
  }

  get searchableGrammar(): string {
    return this.naturalText
      .replace(/#{elementname}/g, "Element")
      .replace(/#{elementnameTo}|#{to-element}/g, "Element")
      .replace(/#{from-element}/g, "Element")
      .replace(/#{element}/g, "Element")
      .replace(/#{rest}/g, "Rest")
      .replace(/\$\{([^}]+)\}/g, this.extractTestDataString)
      .replace(/\${testdata}/g, this.extractTestDataString)
      .replace(/\#\{([^}]+)\}/g, this.extractTestDataString)
      .replace(/\#{testdata}/g, this.extractTestDataString)
      .replace(/\#{testdata}/g, this.extractTestDataString)
      .replace(/\#{username}/g, this.extractTestDataString)
      .replace(/\#{password}/g, this.extractTestDataString)
      .replace(/@{attribute}/g, "attribute");
  }

  actionText(element?: string, testData?: string) {
    return this.naturalText
      .replace(/#{elementname}/g, element)
      .replace(/#{elementnameTo}|#{to-element}/g, element)
      .replace(/#{from-element}/g, element)
      .replace(/#{element}/g, element)
      .replace(/\$\{([^}]+)\}/g, testData)
      .replace(/\${testdata}/g, testData)
      .replace(/\#\{([^}]+)\}/g, testData)
      .replace(/\#{testdata}/g, testData)
      .replace(/\#{username}/g, testData)
      .replace(/\#{password}/g, testData);
  }
}
