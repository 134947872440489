<div class="ai-test-case-container" *ngIf="testcaseDetails">
    <div class="ai-header">
        <mat-icon class="ai-icon">psychology</mat-icon>
        <h2>{{ testcaseDetails.name }}</h2>
    </div>
    <mat-card class="ai-card" [@cardAnimation]>
        <mat-card-content>
            <mat-accordion *ngIf="isTestPlanButton">
                <mat-expansion-panel *ngFor="let testSteps of testcaseDetails.testCases; let i = index"
                    [expanded]="i === expandedIndex" (opened)="expandedIndex = i">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="test-icon">description</mat-icon>
                            {{ testSteps.name }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="steps-container">
                        <div class="step" *ngFor="let data of testSteps.steps; let j = index" [@stepAnimation]>
                            <span class="step-number">{{ j + 1 }}</span>
                            <span *ngIf="!data.isEditing" class="step-name">{{ data.name }}</span>
                            <input *ngIf="data.isEditing" [(ngModel)]="data.name" class="editable-step-name" />
                            <a class="mat-tooltip-trigger action-icon py-10 fa-pencil-on-paper  "
                                (click)="data.isEditing = !data.isEditing"></a>
                        </div>
                    </div>

                    <mat-action-row>
                        <button mat-raised-button color="primary" (click)="createTestcase(testSteps)"
                            [disabled]="isLoadingCreateTestCase">
                            <mat-icon>add</mat-icon> Create Test Case
                            <div *ngIf="isLoadingCreateTestCase" class="loader"></div>
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion *ngIf="!isTestPlanButton">
                <mat-expansion-panel [expanded]="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <mat-icon class="test-icon">description</mat-icon>
                            {{ testcaseDetails.name }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div class="steps-container">
                        <div class="step" *ngFor="let data of testcaseDetails.steps; let j = index" [@stepAnimation]>
                            <span class="step-number">{{ j + 1 }}</span>
                            <span *ngIf="data.type == 'Step_GROUP'"
                                class="mat-tooltip-trigger theme-t-secondary mr-5 text-nowrap fa-plus-square-solid ng-star-inserted"></span>
                            <span *ngIf="!data.isEditing" class="step-name">{{ data.type == 'Step' ? data.name :
                                data.label}}</span>

                            <input *ngIf="data.isEditing && data.type == 'Step'" [(ngModel)]="data.name"
                                class="editable-step-name" />
                            <a *ngIf="data.type == 'Step'"
                                class="mat-tooltip-trigger action-icon py-10 fa-pencil-on-paper  "
                                (click)="data.isEditing = !data.isEditing"></a>
                        </div>
                    </div>

                    <mat-action-row>
                        <button mat-raised-button color="primary" (click)="createsingleTestcase(testcaseDetails)"
                            [disabled]="isLoadingCreateTestCase">
                            <mat-icon>add</mat-icon> Create Test Case
                            <div *ngIf="isLoadingCreateTestCase" class="loader"></div>
                        </button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>

            <div class="create-plan-container" *ngIf="isTestPlanButton">
                <button mat-raised-button color="accent" (click)="createTestplan()"
                    [disabled]="isLoadingCreateTestPlan">
                    <mat-icon>playlist_add</mat-icon> Create Test Plan
                    <div *ngIf="isLoadingCreateTestPlan" class="loader"></div>
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</div>