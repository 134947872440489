import {Component, Inject, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TestStepResultService} from "../../services/test-step-result.service";
import {ToastrService} from "ngx-toastr";
import {ActionStepResultDetailsComponent} from "../webcomponents/action-step-result-details.component";
import {TestStepResultDetailsComponent} from "../results/test-step-result-details.component";
import {ElementService} from "../../shared/services/element.service";
import {TestStepService} from "../../services/test-step.service";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {BaseComponent} from "../../shared/components/base.component";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationGuard} from "../../shared/guards/authentication.guard";

@Component({
  selector: 'app-popupOpen',
  templateUrl: './popupOpen.component.html',
  styleUrls: ['./popupOpen.component.scss']
})
export class PopupOpenComponent extends BaseComponent implements OnInit {
  popupForm: FormGroup;
  formValues: any[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<PopupOpenComponent>,
    private formBuilder: FormBuilder,
    public authGuard: AuthenticationGuard,
    public toastrService: ToastrService,
    public translate: TranslateService,
    private testStepResultService: TestStepResultService,
    private elementService: ElementService,
    private testStepService: TestStepService,
    public notificationsService: NotificationsService,
  ) {  super(authGuard, notificationsService, translate,toastrService); }

  ngOnInit(): void {
    this.testStepService.testCaseResultData$.subscribe(res=>{
      if(res){
        this.modalData.testStepResult = res;
      }
    })
    this.popupForm = this.formBuilder.group({
      status: [this.modalData?.selectedvalue, Validators.required],
      message: [this.modalData?.resultData?.message || ''],
      assignTo: [''],
      expectedResult: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.expected_result || ''],
      actualResult: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.actual_result || ''],
      reason: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.reason || ''],
      suggestions: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.suggestions || ''],
      version: [''],
      elapsed: [''],
      defects: [''],
      image: [null]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  save(): void {
    if (this.popupForm.valid) {
      const formValue = this.popupForm.value;
      this.formValues.push(formValue);
      this.modalData.resultData.result = this.formValues[0].status;
      this.modalData.resultData.message = this.formValues[0].message;
      const index = this.modalData.testStepResult.content.findIndex(x=>x.id === this.modalData.resultData.id);
      const failedStep = [{
        expected_result: this.formValues[0].expectedResult,
        actual_result: this.formValues[0].actualResult,
        reason: this.formValues[0].reason,
        suggestions: this.formValues[0].suggestions,
        failed_step_index: index+1}];

      this.modalData.resultData.executedResult = {failed_test_steps: failedStep};
      if(this.modalData.isTestCaseResult){
        this.testStepResultService.updateNonexecuteTestCase({id: this.modalData.resultData.id, resultData: this.modalData.resultData}).subscribe(res=>{
          if(res){
            this.elementService.updateData(this.formValues[0].message)
            this.showNotification(NotificationType.Success, 'Updated successfully')
            this.dialogRef.close();
          }
        })
        return;
      }
      this.testStepResultService.updateNonexecute({resultId: this.modalData.resultData.id, resultData: this.modalData.resultData}).subscribe(res=>{
        if(res){
          this.elementService.updateData(this.formValues[0].status)
          this.showNotification(NotificationType.Success, 'Updated successfully')
          this.dialogRef.close();
        }
      })

    }
  }
}
