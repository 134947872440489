import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../shared/components/base.component";
import {ActivatedRoute, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Workspace } from "../models/workspace.model";
import {WorkspaceService} from "../../app/services/workspace.service";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {TranslateService} from "@ngx-translate/core";
import {AuthenticationGuard} from "../shared/guards/authentication.guard";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'workspaceApp-form',
    templateUrl: './workspace-form.component.html',
  })
  export class WorkspaceFormComponent extends BaseComponent implements OnInit {
    public showDescription = false;
    public workspaceForm: FormGroup;
    public formSubmitted = false;
    public saving = false;
    public Workspace = new Workspace();

    constructor(
      private workspaceService: WorkspaceService,
      public authGuard: AuthenticationGuard,
      public translate: TranslateService,
      public notificationsService: NotificationsService,
      public toastrService:ToastrService,
      private router: Router){
        super(authGuard, notificationsService, translate, toastrService)
    }
    ngOnInit(): void {
        this.initFormControls()
        this.workspaceForm.get('workspaceType').setValue('WebApplication');
    }
    goBack(){
        this.router.navigate(['workspaces'])
    }

    initFormControls() {
      this.workspaceForm = new FormGroup({
        name: new FormControl(this.Workspace.name, [Validators.required, Validators.minLength(4), Validators.maxLength(250),this.noWhitespaceValidator]),
        description: new FormControl(this.Workspace.description),
        workspaceType: new FormControl(this.Workspace.workspaceType)
      })
    }

    Create(){
      this.formSubmitted = true;
      if (this.workspaceForm.invalid) return;
      this.saving = true;
      this.Workspace.name =  this.workspaceForm.controls.name.value
      this.Workspace.description =  this.workspaceForm.controls.description.value
      this.Workspace.workspaceType =  this.workspaceForm.controls.workspaceType.value

      this.workspaceService.create(this.Workspace).subscribe((res) =>{
        this.saving = false;
          this.translate.get('message.common.created.success', {FieldName: 'Workspace'}).subscribe((res) => {
            this.showNotification(NotificationType.Success, res);
            this.router.navigate(['workspaces'])
            // this.router.navigate(['/td', 'cases', testcase.id, 'steps'])
          },
          error => {
            this.saving = false;
            this.translate.get('message.common.created.failure', {FieldName: 'Workspace'}).subscribe((res) => {
              this.showAPIError(error, res,'Test Case or Step Group');
            })
          })
      })
      
    }
  }