<div class="graphs-container">
    <div class="graphs-grid">
        <div class="usage-details-content">
            <mat-tab-group>
                <mat-tab *ngFor="let url of graphUrls; let i = index"
                    [disabled]="graphTypes[i] === 'Redundancy Eliminator'">
                    <ng-template mat-tab-label>
                        <div class="tab-label">
                            <mat-icon>{{ getGraphIcon(graphTypes[i]) }}</mat-icon>
                            <span>{{ graphTypes[i] === 'default' ? 'Test Flow Explorer' : (graphTypes[i] | titlecase)
                                }}</span>
                        </div>
                    </ng-template>
                    <div class="graph-card-header d-flex">
                        <h3>{{ graphTypes[i] === 'default' ? 'Test Flow Explorer' : (graphTypes[i] | titlecase) }}</h3>
                        <mat-icon class="ml-10" matTooltip="{{ getTooltipDescription(graphTypes[i]) }}">info</mat-icon>
                    </div>
                    <div class="graph-card-content" [ngClass]="'aspect-ratio-' + graphTypes[i]">
                        <iframe [src]="url" frameborder="0"></iframe>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>