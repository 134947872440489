<div class="run-history-detail">
    <!-- <div class="close-icon">
        <div mat-button  class="video-control">
            <mat-icon class="img">close</mat-icon>
        </div>
    </div> -->
    <div class="d-flex ts-col-100">
        <div class="col-md-4">
            <div class="info-section">
                <div class="d-flex ts-col-100">
                    <div class="col--md-3">
                        <div class="success-count">
                            <img src="/assets/images/checkmark.svg" alt="">
                            <span>{{testStepDataRunResult?.passedCount}}</span>
                        </div>
                    </div>
                    <div class="col--md-3">
                        <div class="failed-count">
                            <img src="/assets/images/remove_4.png" alt="">
                            <span>{{testStepDataRunResult?.failedCount}}</span>
                        </div>
                    </div>
                    <div class="col--md-3">
                        <div class="duration">
                            <span>
                                <app-duration-format [duration]="testStepDataRunResult?.duration"
                                    [icon]="false"></app-duration-format>
                            </span>
                        </div>
                    </div>
                    <div class="col--md-3" *ngIf="cqaOrigin == 'dtest01' || cqaOrigin == 'lululemonand'">
                        <mat-menu #animals="matMenu" class="run-history-more-option">
                            <button mat-menu-item (click)="getSummary()">
                                <mat-icon>
                                    summarize
                                </mat-icon>
                                Test Case Summary
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="reports">
                                <mat-icon>
                                    description
                                </mat-icon>
                                Reports
                            </button>
                        </mat-menu>

                        <mat-menu #reports="matMenu">
                            <button mat-menu-item [matMenuTriggerFor]="performance"
                                *ngIf="testStepDataRunResult?.executedResult?.performanceTesting">
                                <mat-icon>
                                    speed
                                </mat-icon>
                                Performance Testing
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="security"
                                *ngIf="testStepDataRunResult?.executedResult?.securityTesting">
                                <mat-icon>
                                    verified_user
                                </mat-icon>
                                Security Testing
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="load"
                                *ngIf="testStepDataRunResult?.executedResult?.loadTesting">
                                <mat-icon>
                                    cloud
                                </mat-icon>
                                Load Testing
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="accessibility"
                                *ngIf="testStepDataRunResult?.executedResult?.accessibilityTesting?.files?.length">
                                <mat-icon>
                                    accessibility
                                </mat-icon>
                                Accessibility Testing
                            </button>

                            <button mat-menu-item
                                *ngIf="!testStepDataRunResult?.executedResult?.performanceTesting && !testStepDataRunResult?.executedResult?.securityTesting && !testStepDataRunResult?.executedResult?.loadTesting && !testStepDataRunResult?.executedResult?.accessibilityTesting">
                                No Reports
                            </button>
                        </mat-menu>

                        <mat-menu #accessibility="matMenu">
                            <button mat-menu-item (click)="downloadAndViewReport(report)"
                                *ngFor="let report of testStepDataRunResult?.executedResult?.accessibilityTesting?.files;let i = index;">
                                <mat-icon>
                                    description
                                </mat-icon>
                                Report {{i + 1}}
                            </button>
                        </mat-menu>

                        <mat-menu #load="matMenu">
                            <button mat-menu-item (click)="downloadAndViewReport(report)"
                                *ngFor="let report of testStepDataRunResult?.executedResult?.loadTesting?.files;let i = index;">
                                <mat-icon>
                                    description
                                </mat-icon>
                                Report {{i + 1}}
                            </button>
                        </mat-menu>

                        <mat-menu #security="matMenu">
                            <button mat-menu-item (click)="downloadAndViewReport(report)"
                                *ngFor="let report of testStepDataRunResult?.executedResult?.securityTesting?.files;let i = index;">
                                <mat-icon>
                                    description
                                </mat-icon>
                                Report {{i + 1}}
                            </button>
                        </mat-menu>

                        <mat-menu #performance="matMenu">
                            <button mat-menu-item (click)="downloadAndViewReport(report)"
                                *ngFor="let report of testStepDataRunResult?.executedResult?.performanceTesting?.files;let i = index;">
                                <mat-icon>
                                    description
                                </mat-icon>
                                Report {{i + 1}}
                            </button>
                        </mat-menu>

                        <div class="video-control" [matMenuTriggerFor]="animals">
                            <mat-icon class="img white">more_horiz</mat-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="accordian-steps">
                <mat-accordion multi>
                    <mat-expansion-panel hideToggle [expanded]='true' class="remove-mat-expansion-panel-padding">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <span class="material-icons">expand_more</span>
                                {{testCaseData?.name}}
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div [ngClass]="{'success-step': steps.teststepResult?.result == 'SUCCESS', 'failed-step': steps.executionData?.result == 'FAILURE', 'failed-step-section-main': steps.executionData?.result == 'FAILURE'}"
                            *ngFor="let steps of test_steps_executed; let i = index;">
                            <ng-container *ngIf="steps.teststepResult?.result == 'SUCCESS'">
                                <mat-expansion-panel hideToggle [expanded]="expandedIndex === i"
                                    (opened)="getStepDetailData(steps, i)">
                                    <mat-expansion-panel-header class="expansion-panel-custom">
                                        <mat-panel-title>
                                            <div class="steps-more-info">
                                                <div class="sucessc-icon-with-text">
                                                    <img *ngIf="steps.teststepResult?.result == 'SUCCESS'"
                                                        src="/assets/images/checkmark.svg" alt="">
                                                    <img *ngIf="steps.teststepResult?.result == 'FAILURE'"
                                                        src="/assets/images/remove_4.png" alt="">
                                                    <span [innerHtml]="steps.action" class="fz-14"></span>
                                                </div>
                                            </div>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <i class="fa fa-warning mr-10" style="color:red;" *ngIf="steps.teststepResult?.executedResult?.messageLog?.length ||
                                            steps.teststepResult?.executedResult?.networkLog?.length"
                                                title="Console log or Network log detected"></i>
                                            <mat-icon
                                                *ngIf="steps.teststepResult?.hasImageDifference">visibility</mat-icon>
                                            <mat-icon class="ml-5"
                                                *ngIf="steps.teststepResult?.executedResult?.stepResult">smart_toy</mat-icon>
                                            <span *ngIf="steps.stepGroupId"
                                                class="material-icons indicate-stepgroup">expand_more</span>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>
                                    <div class="panel-description"
                                        *ngIf="(steps.teststepResult?.executedResult?.messageLog?.length ||
                                    steps.teststepResult?.executedResult?.networkLog?.length) || steps.teststepResult?.executedResult?.stepResult">
                                        <div class="description-list mb-10">
                                            <div class="description-list-option">
                                                <div>
                                                    <span [ngClass]="{active : stepsActivedOption === 'console'}"
                                                        (click)="stepsActivedOption = 'console'">
                                                        Console
                                                    </span>
                                                </div>
                                                <div class="network-option">
                                                    <span [ngClass]="{active : stepsActivedOption === 'network'}"
                                                        (click)="stepsActivedOption = 'network'">
                                                        Network
                                                    </span>
                                                </div>
                                                <div class="ai-result-option">
                                                    <span [ngClass]="{active : stepsActivedOption === 'ai-result'}"
                                                        (click)="stepsActivedOption = 'ai-result'">
                                                        AI Result
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- AI Result -->
                                        <div class="ai-result"
                                            *ngIf="steps.teststepResult?.executedResult?.stepResult && stepsActivedOption === 'ai-result'">
                                            {{selectedStepData?.executedResult?.stepResult}}
                                        </div>
                                        <!-- Console Section -->
                                        <div class="network"
                                            *ngIf="steps.teststepResult?.executedResult?.messageLog?.length && stepsActivedOption === 'console'">
                                            <div class="network-list"
                                                *ngFor="let network of steps.teststepResult?.executedResult?.messageLog">
                                                <div class="network-data"
                                                    [ngClass]="{'put':network.type === 'WARNING','delete':network.type === 'ERROR',
                                                'post':network.type === 'INFO','get':network.type === 'LOG','debug':network.type === 'DEBUG' }">
                                                    <div class="api-method">
                                                        {{network.type}}
                                                    </div>
                                                    <div class="url">
                                                        <div>
                                                            <span>
                                                                Location :
                                                            </span> {{network.location}}
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Text
                                                            </span> : {{network.text || '-'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Network Section -->
                                        <div class="network"
                                            *ngIf="steps.teststepResult?.executedResult?.networkLog?.length && stepsActivedOption === 'network'">
                                            <div class="network-list"
                                                *ngFor="let network of steps.teststepResult?.executedResult?.networkLog">
                                                <div class="network-data" [ngClass]="{'get':network.method === 'GET','post':network.method === 'POST',
                                                'delete':network.method === 'DELETE','put':network.method === 'PUT' }">
                                                    <div class="api-method">
                                                        {{network.method}}
                                                    </div>
                                                    <div class="url">
                                                        <div>
                                                            <span>
                                                                URL :
                                                            </span> {{network.url}}
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Request
                                                            </span> : {{network.reqData || '-'}}
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Status
                                                            </span> : {{network.status || '-'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container
                                            *ngIf="!steps.teststepResult?.executedResult?.stepResult && stepsActivedOption === 'ai-result'">
                                            <div class="not-data-foung">
                                                AI - Result not found
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!steps.teststepResult?.executedResult?.messageLog?.length && stepsActivedOption === 'console'">
                                            <div class="not-data-foung">
                                                Console log not found
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!steps.teststepResult?.executedResult?.networkLog?.length && stepsActivedOption === 'network'">
                                            <div class="not-data-foung">
                                                Network log not found
                                            </div>
                                        </ng-container>
                                    </div>
                                    <!-- Step Group Inside Steps -->
                                    <ng-container *ngIf="!stepGroupSteps.empty && steps.stepGroupId">
                                        <div
                                            style="color: white;padding-bottom: 20px;font-size: 16px;padding-left: 20px;">
                                            StepGroup Steps
                                        </div>
                                        <ng-container *ngFor="let groupStep of stepGroupSteps?.content;let i = index;">
                                            <mat-expansion-panel-header class="expansion-panel-custom step-group-panel">
                                                <mat-panel-title>
                                                    <div class="steps-more-info">
                                                        <div class="sucessc-icon-with-text">
                                                            <img src="/assets/images/checkmark.svg" alt="">
                                                            <span [innerHtml]="groupStep.action" class="fz-14"></span>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                        </ng-container>
                                    </ng-container>
                                </mat-expansion-panel>
                            </ng-container>
                            <ng-container *ngIf="steps.teststepResult?.result == 'FAILURE'">
                                <mat-expansion-panel hideToggle [expanded]='false'
                                    class="remove-mat-expansion-panel-padding" (opened)="getStepDetailData(steps,i)">
                                    <mat-expansion-panel-header class="failed-mat-header expansion-panel-custom">
                                        <mat-panel-title class="failed-accordian">
                                            <div class="steps-more-info">
                                                <div class="sucessc-icon-with-text">
                                                    <img *ngIf="steps.teststepResult?.result == 'FAILURE'"
                                                        src="/assets/images/remove_4.png" alt="">
                                                    <span [innerHtml]="steps.action" class="fz-14"></span>
                                                </div>
                                            </div>
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            <div class="view-more">
                                                View More
                                            </div>
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="panel-description"
                                        *ngIf="(steps.teststepResult?.executedResult?.messageLog?.length ||
                                    steps.teststepResult?.executedResult?.networkLog?.length) || steps.teststepResult?.executedResult?.stepResult">
                                        <div class="description-list mb-10">
                                            <div class="description-list-option">
                                                <div>
                                                    <span [ngClass]="{active : stepsActivedOption === 'console'}"
                                                        (click)="stepsActivedOption = 'console'">
                                                        Console
                                                    </span>
                                                </div>
                                                <div class="network-option">
                                                    <span [ngClass]="{active : stepsActivedOption === 'network'}"
                                                        (click)="stepsActivedOption = 'network'">
                                                        Network
                                                    </span>
                                                </div>
                                                <div class="ai-result-option">
                                                    <span [ngClass]="{active : stepsActivedOption === 'ai-result'}"
                                                        (click)="stepsActivedOption = 'ai-result'">
                                                        AI Result
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- AI Result -->
                                        <div class="ai-result"
                                            *ngIf="steps.teststepResult?.executedResult?.stepResult && stepsActivedOption === 'ai-result'">
                                            {{selectedStepData?.executedResult?.stepResult}}
                                        </div>
                                        <!-- Console Section -->
                                        <div class="network"
                                            *ngIf="steps.teststepResult?.executedResult?.messageLog?.length && stepsActivedOption === 'console'">
                                            <div class="network-list"
                                                *ngFor="let network of steps.teststepResult?.executedResult?.messageLog">
                                                <div class="network-data"
                                                    [ngClass]="{'put':network.type === 'WARNING','delete':network.type === 'ERROR',
                                                'post':network.type === 'INFO','get':network.type === 'LOG','debug':network.type === 'DEBUG' }">
                                                    <div class="api-method">
                                                        {{network.type}}
                                                    </div>
                                                    <div class="url">
                                                        <div>
                                                            <span>
                                                                Location :
                                                            </span> {{network.location}}
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Text
                                                            </span> : {{network.text || '-'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Network Section -->
                                        <div class="network"
                                            *ngIf="steps.teststepResult?.executedResult?.networkLog?.length && stepsActivedOption === 'network'">
                                            <div class="network-list"
                                                *ngFor="let network of steps.teststepResult?.executedResult?.networkLog">
                                                <div class="network-data" [ngClass]="{'get':network.method === 'GET','post':network.method === 'POST',
                                                'delete':network.method === 'DELETE','put':network.method === 'PUT' }">
                                                    <div class="api-method">
                                                        {{network.method}}
                                                    </div>
                                                    <div class="url">
                                                        <div>
                                                            <span>
                                                                URL :
                                                            </span> {{network.url}}
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Request
                                                            </span> : {{network.reqData || '-'}}
                                                        </div>
                                                        <div>
                                                            <span>
                                                                Status
                                                            </span> : {{network.status || '-'}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-container
                                            *ngIf="!steps.teststepResult?.executedResult?.stepResult && stepsActivedOption === 'ai-result'">
                                            <div class="not-data-foung">
                                                AI - Result not found
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!steps.teststepResult?.executedResult?.messageLog?.length && stepsActivedOption === 'console'">
                                            <div class="not-data-foung">
                                                Console log not found
                                            </div>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="!steps.teststepResult?.executedResult?.networkLog?.length && stepsActivedOption === 'network'">
                                            <div class="not-data-foung">
                                                Network log not found
                                            </div>
                                        </ng-container>
                                    </div>

                                    <!-- Step Group Inside Steps -->
                                    <ng-container *ngIf="!stepGroupSteps.empty && steps.stepGroupId">
                                        <div
                                            style="color: white;padding-top: 20px;padding-bottom: 20px;font-size: 16px;padding-left: 20px;">
                                            StepGroup Steps
                                        </div>
                                        <ng-container *ngFor="let groupStep of stepGroupSteps?.content;let i = index;">
                                            <mat-expansion-panel-header class="expansion-panel-custom">
                                                <mat-panel-title>
                                                    <div class="steps-more-info">
                                                        <div class="sucessc-icon-with-text">
                                                            <img *ngIf="i < steps.teststepResult?.executedResult?.failed_step_index"
                                                                src="/assets/images/checkmark.svg" alt="">
                                                            <img *ngIf="i > steps.teststepResult?.executedResult?.failed_step_index"
                                                                src="/assets/images/pending.svg" alt="">
                                                            <img *ngIf="i == steps.teststepResult?.executedResult?.failed_step_index"
                                                                src="/assets/images/remove_4.png" alt="">
                                                            <span [innerHtml]="groupStep.action" class="fz-14"></span>
                                                        </div>
                                                    </div>
                                                </mat-panel-title>
                                            </mat-expansion-panel-header>
                                        </ng-container>
                                    </ng-container>

                                    <div class="failed-step-section">
                                        <div class="failed-reason">
                                            <h6 *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps">

                                                Errors

                                                <span
                                                    *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps[0]?.stepScreenshotUrl"
                                                    (click)="openScreenShort()">Screen Shot</span>

                                            </h6>
                                            <div class="failed-step-reason"
                                                *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps">
                                                <mat-list>
                                                    <mat-list-item
                                                        style="display: flex;padding: 12px;color: white;font-family:'inter';align-items: center;">
                                                        <mat-icon matListItemIcon style="background-color: red;color: white;font-size: 15px;cursor: pointer;    border-radius: 5px;
                                                        padding: 4px;
                                                        margin: 5px">visibility</mat-icon>
                                                        <div matListItemTitle class="list">Failed Step :
                                                            {{testStepDataRunResult?.executedResult?.failed_test_steps[0]?.failed_step_index}}
                                                        </div>
                                                    </mat-list-item>
                                                    <mat-list-item
                                                        *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps[0]?.reason"
                                                        style="display: flex;padding: 12px;color: white;font-family:'inter';align-items: center;">
                                                        <mat-icon matListItemIcon style="background-color: blueviolet;color: white;font-size: 15px;cursor: pointer;    border-radius: 5px;
                                                        padding: 4px;
                                                        margin: 5px">visibility</mat-icon>
                                                        <div matListItemTitle class="list">Reason :
                                                            {{testStepDataRunResult?.executedResult?.failed_test_steps[0]?.reason}}
                                                        </div>
                                                    </mat-list-item>
                                                    <mat-list-item
                                                        *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps[0]?.actual_result"
                                                        style="display: flex;padding: 12px;color: white;font-family:'inter';align-items: center;">
                                                        <mat-icon matListItemIcon style="background-color:blueviolet;color: white;font-size: 15px;cursor: pointer;    border-radius: 5px;
                                                        padding: 4px;
                                                        margin: 5px">visibility</mat-icon>
                                                        <div matListItemTitle class="list">Actual Result :
                                                            {{testStepDataRunResult?.executedResult?.failed_test_steps[0]?.actual_result}}
                                                        </div>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                        </div>
                                        <div class="success-solution">
                                            <h5>
                                                Solution
                                                <span
                                                    *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps[0]?.type  === 'TS_TIMEOUT' || testStepDataRunResult?.executedResult?.failed_test_steps[0]?.type  === 'LOCATOR_ND'"
                                                    (click)="openQuickFix()">Quick Fix</span>
                                            </h5>
                                            <div class="success-step-reason">
                                                <mat-list
                                                    *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps">
                                                    <mat-list-item
                                                        *ngIf="testStepDataRunResult?.executedResult?.failed_test_steps[0]?.suggestions"
                                                        style="display: flex;padding: 12px;color: white;font-family:'inter';align-items: center;">
                                                        <mat-icon matListItemIcon style="background-color:cornflowerblue;color: white;font-size: 15px;cursor: pointer;    border-radius: 5px;
                                                        padding: 4px;
                                                        margin: 5px">visibility</mat-icon>
                                                        <div matListItemTitle class="list">Suggestions :
                                                            <span
                                                                *ngFor="let suggestion of testStepDataRunResult?.executedResult?.failed_test_steps[0]?.suggestions.split('\n')">
                                                                {{ suggestion }} <br>
                                                            </span>
                                                        </div>
                                                    </mat-list-item>
                                                </mat-list>
                                            </div>
                                        </div>
                                    </div>
                                </mat-expansion-panel>
                            </ng-container>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>
        <div class="col-md-8">
            <div class="tabs">
                <span [ngClass]="{'active-tab': currentActivetab === 'video'}" (click)="currentActivetab = 'video'">
                    Video View
                </span>
                <span [ngClass]="{'active-tab': currentActivetab === 'trace'}" (click)="currentActivetab = 'trace'">
                    Trace View
                </span>
                <!-- <div class="form-group test-report" *ngIf="testStepDataRunResult?.executedResult?.performanceTesting?.files">
                    <mat-form-field class="report-dropdown" appearance="outline">
                        <mat-label>Test Reports</mat-label>
                        <mat-select (selectionChange)="onReportChange($event)">
                            <mat-option *ngFor="let report of testStepDataRunResult?.executedResult?.performanceTesting?.files;let i = index;" [value]="report">
                                Report {{i + 1}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </div>
            <div class="video-section" *ngIf="testStepDataRunResult">
                <ng-container *ngIf=" currentActivetab === 'video'">
                    <div class="controls" *ngIf="testStepDataRunResult.screenRecordings && testStepDataRunResult.screenRecordings.length > videosPerPage">
                        <button (click)="prevPage()" [disabled]="currentStartIndex === 0">Previous</button>
                        <button (click)="nextPage()" [disabled]="currentStartIndex + videosPerPage >= testStepDataRunResult.screenRecordings.length">Next</button>
                      </div>
                    <ng-container *ngFor="let video of currentVideos">
                    <video width="100%" height="100%" *ngIf="testStepDataRunResult?.screenRecordings"
                        [src]="video" type="video/webm" autoplay controls>
                    </video>
                    </ng-container>
                    <div class="msg" *ngIf="!testStepDataRunResult.screenRecordings || testStepDataRunResult.screenRecordings.length === 0">
                        No video recording found
                    </div>
                </ng-container>

                <ng-container
                    *ngIf="currentActivetab === 'trace' && testStepDataRunResult?.traceViewers && testStepDataRunResult?.traceViewers != null">
                    <div *ngFor="let traceViewers of traceViewer; let i = index;">
                        <div class="iframe-container-run-detail">
                            <ng-container>
                                <iframe style="width: 100%; height: 4500px; border: none;border-radius: 15px;"
                                    [src]="traceViewers" title="">
                                </iframe>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="logs-section">
                <div class="tabs light-dark">
                    <span [ngClass]="{'active-tab': currentActivetabLight === 'ai'}"
                        (click)="currentActivetabLight = 'ai'">AI Self-healing</span>
                    <span [ngClass]="{'active-tab': currentActivetabLight === 'visual-regression'}"
                        (click)="currentActivetabLight = 'visual-regression'">Visual Regression</span>
                    <span [ngClass]="{'active-tab': currentActivetabLight === 'impact'}"
                        (click)="currentActivetabLight = 'impact';fetchImapctDataTestCases()">Impact Analysis</span>
                    <span *ngIf="cqaOrigin == 'dtest01' || cqaOrigin == 'lululemonand' || cqaOrigin == 'clari'"
                        [ngClass]="{'active-tab': currentActivetabLight === 'root'}"
                        (click)="currentActivetabLight = 'root';">Root-Cause</span>
                </div>
                <ng-container *ngIf="currentActivetabLight == 'ai'">
                    <ng-container *ngIf="testStepDataRunResult?.executedResult?.autoHealedSteps">
                        <div class="table">
                            <table class="w-100 parameter-border">
                                <tr>
                                    <th translate="message.common.auto_HealedSteps_Index"></th>
                                    <th translate="message.common.auto_HealedSteps_accuracy"></th>
                                    <th translate="message.common.auto_HealedSteps_beforeLocator"></th>
                                    <th translate="message.common.auto_HealedSteps_afterLocator"></th>
                                </tr>
                                <tr
                                    *ngFor="let DIsplayAutoHedalSteps of testStepDataRunResult?.executedResult?.autoHealedSteps">
                                    <td>{{ DIsplayAutoHedalSteps.stepIndex }}</td>
                                    <td>{{ DIsplayAutoHedalSteps.aiAccuracyScore | number: '1.2-2' }}</td>
                                    <td>{{ DIsplayAutoHedalSteps.beforeLocator }}</td>
                                    <td>{{ DIsplayAutoHedalSteps.afterLocator }}</td>
                                </tr>
                            </table>
                        </div>
                    </ng-container>
                    <div class="msg" *ngIf="!testStepDataRunResult?.executedResult?.autoHealedSteps">
                        No AI-healing applied
                    </div>
                </ng-container>


                <ng-container *ngIf="currentActivetabLight == 'visual-regression'">
                    <div class="compare-images">
                        <div class="images">
                            <h6> Screenshot (Original) </h6>
                            <img *ngIf="selectedStepData?.screenshotName" [src]="selectedStepData?.screenshotName"
                                alt="screenshot" (error)="setBrokenImage()" />
                            <p *ngIf="!selectedStepData?.screenshotName" class="text-light ">
                                No Visual Difference Available
                            </p>
                        </div>
                        <div class="images">
                            <h6> Screenshot (Visual Regression) </h6>
                            <img *ngIf="selectedStepData?.imageDifferenceUrl"
                                [src]="selectedStepData?.imageDifferenceUrl" (error)="setBrokenImage()"
                                alt="screenshot" />
                            <p *ngIf="!selectedStepData?.imageDifferenceUrl" class="text-light">
                                No Visual Difference Available
                            </p>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="currentActivetabLight == 'impact' ">
                    <div class="table" *ngIf="!testCases?.empty">
                        <table class="w-100 parameter-border">
                            <tr>
                                <th>Title</th>
                                <th>Result</th>
                                <th>Status</th>
                            </tr>
                            <tr *ngFor='let testCase of testCases?.content'>
                                <td>
                                    <div style="display: flex;gap: 10px;align-items: center;">
                                        {{testCase?.name}}
                                        <picture (click)="openInNewTab($event,testCase.id)">
                                            <img src="/assets/images/new-tab.svg" alt="new-tab" width="20px"
                                                style="cursor: pointer;">
                                        </picture>
                                    </div>
                                </td>
                                <td>
                                    <a *ngIf="testCase?.lastRun">
                                        <app-result-pie-chart-column [width]="30" [height]="30"
                                            [resultEntity]="testCase?.lastRun"></app-result-pie-chart-column>
                                    </a>
                                </td>
                                <td [textContent]="'testcase.status_'+testCase?.status| translate"></td>
                            </tr>
                        </table>
                    </div>
                    <div class="msg" *ngIf="testCases?.empty">
                        No Impact found
                    </div>
                </ng-container>

                <ng-container *ngIf="currentActivetabLight == 'root'">
                    <div style="position: relative;">
                        <div class="text-center mt-10 ml-10">
                            <button mat-raised-button color="primary" (click)="analyze()">Analyze</button>
                        </div>
                        <div *ngIf="rootAnalize && !testStepDataRunResult?.message" [innerHTML]="rootAnalize"
                            class="analyze-content">
                            <!-- {{rootAnalize}} -->
                        </div>
                        <div *ngIf="testStepDataRunResult?.message" [innerHTML]="testStepDataRunResult?.message"
                            class="analyze-content">
                        </div>
                        <div class="spinner-loading" *ngIf="loader">
                            <mat-spinner [diameter]="50" [color]="'#6366F1'"></mat-spinner>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>