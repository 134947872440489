<div class="login-card bg-white border-rds-12 d-flex flex-column shadow-all-b10 p-40"
     xmlns="http://www.w3.org/1999/html">
      <div style="text-align: center">
        <img style="text-align: center; margin-right: 70px" class="login-logo"
             src="/assets/color_icons/cqa-logo-with-text.svg"/>
      </div>
      <form
        method="POST"
        class="ts-form" autocomplete="off" action="/login" #loginFormElm
        [formGroup]="loginForm">
        <div *ngIf="!isGoogleAuth" class="mb-20" style="width: 50%;margin-top: 2rem;margin-left: 13rem;">
          <button
            [disabled]="inTransit"
            [translate]="inTransit ? 'btn.common.please_wait': 'login_form.sign_in'"
            (click)="login();"
            class="login-primary-btn" type="submit"></button>
        </div>
        <div class="mt-auto mb-20" *ngIf="isGoogleAuth">
          <button
            class="login-google-btn"
            onclick="window.location.href='/oauth2/authorization/google'">
            <span class="login-google-logo"></span>
            <span [translate]="'login_form.sign_in_with_google'"></span>
          </button>
        </div>
      </form>
<!--      <div style="margin-top: 1rem;">-->
<!--        <div style=" font-size: 25px; font-weight: 700;text-align: center">With your new ContextQA Account, you get</div>-->
<!--        <div style="text-align: center; margin-top: 1rem;">-->
<!--          <ul style="text-align: justify">-->
<!--            <li> Test Case Recorder - <a href="https://chrome.google.com/webstore/detail/contextqa-test-automation/pgadpooodaipbhbchnojijmlkhpamadh" target="_blank">Chrome Extension</a></li>-->
<!--            <li> Advanced Trace Viewer</li>-->
<!--            <li> AI-Powered Auto-Healing </li>-->
<!--            <li> Dynamic Environments </li>-->
<!--            <li> Data Driven Testing</li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
      <div class="mt-5 mat-hint" style="text-align: center;margin-top: 1rem;">Copyright © 2023 <a href="https://contextqa.com/" target="_blank"> ContextQA Inc.</a> Inc. All Rights Reserved</div>
  </div>
