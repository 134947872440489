import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, Optional, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TestCaseStatus } from 'app/enums/test-case-status.enum';
import { WorkspaceVersion } from 'app/models/workspace-version.model';
import { TestCaseService } from 'app/services/test-case.service';
import { UserPreferenceService } from 'app/services/user-preference.service';
import { WorkspaceVersionService } from 'app/shared/services/workspace-version.service';
import { MixpanelService } from '../../../mixpanel.service';
@Component({
  selector: 'app-model-content',
  templateUrl: './model-content.component.html',
  styleUrls: ['./model-content.component.scss']
})
export class ModelContentComponent implements OnInit {

  public versionId: any;
  public version: any;
  public urlForm: FormGroup;
  public origin: any;

  @ViewChild('dialogTemplate') dialogTemplate!: TemplateRef<any>;
  dialogReff!: MatDialogRef<any>;
  tabs: chrome.tabs.Tab[] = [];
  private extensionId = 'pgadpooodaipbhbchnojijmlkhpamadh';
  isExtensionInstalled = false;
  createdTestCaseData: any;
  openTabs: string[] = [];
  isLoading = false;
  testcaseListLength;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public userPreferenceService: UserPreferenceService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ModelContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public testCaseService: TestCaseService,
    public translate: TranslateService,
    private mixpanelService: MixpanelService,
    private versionService: WorkspaceVersionService,
  ) {
    this.urlForm = this.formBuilder.group({
      url: ['', [Validators.required, this.urlValidator]]
    });
  }

  urlValidator(control: { value: string; }) {
    const url = control.value;
    if (!url) return null;

    const pattern = /^https:\/\/([a-zA-Z0-9\-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[a-zA-Z0-9\-\._~:/?#[\]@!$&'()*+,;=%]*)?$/;
    return pattern.test(url) ? null : { invalidUrl: true };
  }

  ngOnInit(): void {
    this.origin = window.location.hostname.split('.')[0];
    this.fetchVersion();
    this.fetchTestcase();
  }

  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      this.versionId = res?.versionId;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
  fetchTestcase(){
    const query = "deleted"
    const short = "createdDate,desc"
    const page = "undefined"
    this.testCaseService.findAll(query, short).subscribe((testcaseList) => {
      console.log("testcaseList ::", testcaseList)
      this.testcaseListLength = testcaseList.content.length
    })
  }
  onSubmit() {
    if (this.urlForm.valid) {
      this.isLoading = true;
      let testCase = {
        "isExtensionUsed": false,
        "name": formatDate(new Date(), 'yyyy-MM-dd hh:mm', 'en-US').toString() + "_test_case",
        "description": null,
        "status": TestCaseStatus.READY,
        "sendMailNotification": false,
        "isStepGroup": false,
        "priorityId": 1,
        "type": 1,
        "preRequisite": null,
        "isDataDriven": false,
        "workspaceVersionId": this.versionId,
        "deleted": false,
        "testDataStartIndex": 0,
        "tags": []
      };
      let fieldName = 'Test Case';
      this.testCaseService.autocreate(testCase).subscribe(
        (testcase) => {
          this.mixpanelService.setUserProperties({"Number_of_test_cases" : this.testcaseListLength + 1});
          this.mixpanelService.track('Test Case Created', {
            "id": testcase.id,
            "title": testcase.name,
            "status": TestCaseStatus.READY,
            "method": "Recorder",
          });
          this.isLoading = false;
          if (chrome && chrome.runtime) {
            let data = {
              type: "test_case",
              id: testcase.id,
              result: testcase,
              action: "openSidePanelFromPortal",
              origin: window.location.hostname.split(".")[0],
              jwt: localStorage.getItem("_t"),
              userEmail: localStorage.getItem('useremail')
            };
            chrome.runtime.sendMessage(
              this.extensionId,
              { message: "openSidePanelFromPortal", data: data },
              (data: any) => {
              }
            );
            this.closeDialog();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url = this.urlForm.get('url').value;
            window.open(url, '_blank');
          } else {
            this.closeDialog();
            this.router.navigate(['/td', 'cases', testcase.id, 'steps']);
            const url = this.urlForm.get('url').value;
            window.open(url, '_blank');
          }
        },
        error => {
          this.isLoading = false;
          this.translate.get('message.common.created.failure', { FieldName: fieldName }).subscribe((res) => {
            // Handle error display
          })
        }
      )
    }
  }
}