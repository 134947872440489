<div mat-dialog-title style="background-color: #3f51b5; color: white;">
  <b>Update Result</b>
  <mat-icon class="close-icon" style="cursor: pointer;" (click)="closeDialog()">close</mat-icon>
</div>
<div mat-dialog-content>
  <form [formGroup]="popupForm">
    <div class="flex-container" style="margin-left: 25px;">
      <div class="flex-item with-divider">
        <p style="font-size: 20px; margin-bottom: 8px;"><b>Status*</b></p>
        <mat-form-field class="sssss" appearance="outline">
          <mat-select formControlName="status" >
            <mat-option value="NOT_EXECUTED" >Not Executed</mat-option>
            <mat-option value="SUCCESS">Passed</mat-option>
            <mat-option value="FAILURE" >Failed</mat-option>
          </mat-select>

        </mat-form-field>
        <p style="font-size: 17px; color: gray; margin-top: -4px;">Set the Test status (<i>passed, failed etc..</i>)</p>

        <p style="font-size: 20px; margin-bottom: 8px;"><b>Comment</b></p>
        <textarea formControlName="message" class="sssss" style="height: 120px;border: 1px solid #ccc; border-radius: 4px;" matInput></textarea>
        <p style="font-size: 17px; color: gray; margin-top: 3px;">Describe your test result.</p>

<!--        <p style="font-size: 20px; margin-bottom: 8px;"><b>Upload an image</b></p>-->
<!--        <input type="file" formControlName="image" class="sssss" matInput>-->
      </div>

      <!--  -->

      <div class="flex-item small-width">

        <p style="font-size: 20px; margin-bottom: 8px;"><b>Actual Result</b></p>
        <input formControlName="expectedResult" class="yyyyy" style="height: 40px;border: 1px solid #ccc;
        border-radius: 4px;" matInput>

        <p style="font-size: 20px; margin-bottom: 8px;"><b>Expected Result</b></p>
        <input formControlName="actualResult" class="yyyyy" style="height: 40px;border: 1px solid #ccc;
        border-radius: 4px;"
               matInput>

        <p style="font-size: 20px; margin-bottom: 8px;"><b>Reason</b></p>
        <input formControlName="reason" class="yyyyy"
               style="height: 40px;border: 1px solid #ccc; border-radius: 4px;" matInput>

        <p style="font-size: 20px; margin-bottom: 8px;"><b>Suggestions</b></p>
        <input formControlName="suggestions" class="yyyyy" style="margin-bottom: 18px; height: 40px;border: 1px solid
         #ccc; border-radius: 4px;" matInput>


      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions style="display: flex; justify-content: flex-start;">
  <button mat-raised-button style="background-color: #3f51b5; color: white; width: 150px;" (click)="save()">
    <mat-icon>check</mat-icon>
    Update
  </button>
  <button mat-stroked-button style="color: red; border: 2px solid red;" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
    Cancel
  </button>
</div>
