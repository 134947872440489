import { Component, Input, OnInit } from '@angular/core';
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestPlanResultService } from "../../services/test-plan-result.service";
import { InfiniteScrollableDataSource } from "../../data-sources/infinite-scrollable-data-source";
import { FilterTimePeriod } from "../../enums/filter-time-period.enum";
import * as moment from 'moment';
import { Page } from "../../shared/models/page";
import { TestDeviceResult } from "../../models/test-device-result.model";
import { TestPlanResult } from "../../models/test-plan-result.model";
import { TestDeviceResultService } from "../../shared/services/test-device-result.service";
import { Router } from '@angular/router';
import { TestCaseResultService } from "../../services/test-case-result.service";
import { TestCaseResult } from "../../models/test-case-result.model";
import { OnBoardingSharedService } from "../../services/on-boarding-shared.service";
import { UserPreferenceService } from "../../services/user-preference.service";
import { LicenceSevices } from "../../shared/services/license.service";

@Component({
  selector: 'app-latest-runs',
  templateUrl: './latest-runs.component.html',
  styles: []
})
export class LatestRunsComponent implements OnInit {
  @Input('version') version: WorkspaceVersion;
  @Input() lastRun: any;
  @Input() lastRunId: any;
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  public testPlanResults: InfiniteScrollableDataSource;
  public dayResults: InfiniteScrollableDataSource;
  public weekResults: InfiniteScrollableDataSource;
  public monthResults: InfiniteScrollableDataSource;
  public quarterResults: InfiniteScrollableDataSource;
  public filterTimePeriod = FilterTimePeriod;
  public environmentResults: Page<TestDeviceResult>;
  public activeExecutionResult: TestPlanResult;
  items = Array.from({ length: 100000 }).map((_, i) => `Item #${i}`);
  filterObj: any;

  constructor(
    public router: Router,
    private environmentResultService: TestDeviceResultService,
    private testPlanResultService: TestPlanResultService,
    private testCaseResultService: TestCaseResultService,
    private userPreferenceService: UserPreferenceService,
    public LicenceSevices: LicenceSevices,
    private onBoardingSharedService: OnBoardingSharedService,) {
  }

  async ngOnInit(): Promise<any> {
    this.testPlanResults = this.dayResults = await this.fetchTestPlanResults(this.period);
    this.LicenceSevices.dayFilter$.subscribe((data: any) => {
      this.filterObj = data;
      if (data?.customDays) {
        this.period = data?.customDays;
        this.testPlanResults = this.dayResults = this.fetchTestPlanResults(this.period);
      }
      this.setFirstActiveResult();
    });
    await this.setFirstActiveResult();
  }

  fetchTestPlanResults(period: any) {
    let query;
    //let query = "entityType:TEST_PLAN,workspaceVersionId:" + this.version.id;
    if (period != FilterTimePeriod.All_TIME && period != 'CUSTOM') query += ",startTime>" + this.getFormattedDate(period);
    // if (this.filterObj?.createdDateRange?.valid) {
    //   query += ",startTime>" + moment.utc(this.filterObj?.createdDateRange.getRawValue().start).local().format("YYYY-MM-DD")
    //   query += ",startTime<" + moment.utc(this.filterObj?.createdDateRange.getRawValue().end).local().format("YYYY-MM-DD")
    // } else {
    //   if (period != FilterTimePeriod.All_TIME) query += ",startTime>" + this.getFormattedDate(period);
    // }
    // if (this.filterObj?.tags?.length)
    //   query += ",tagId@" + this.filterObj.tags.join("#")
    // query += ",startTime>" + this.getFormattedDate(period);
//    return new InfiniteScrollableDataSource(this.testPlanResultService, query, "id,desc");
    return new InfiniteScrollableDataSource(this.testCaseResultService, query, "id,desc");

  }

  filter(period: FilterTimePeriod) {
    this.period = period;
    const date = this.getFormattedDate(period);
    this.userPreferenceService.setDaysFilter({ period: this.period, date: date })
    switch (this.period) {
      case FilterTimePeriod.TODAY:
        this.testPlanResults = this.dayResults;
        break;
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        this.testPlanResults = this.weekResults;
        break;
      case FilterTimePeriod.LAST_30_DAYS:
        this.testPlanResults = this.monthResults;
        break;
      case FilterTimePeriod.LAST_90_DAYS:
        this.testPlanResults = this.quarterResults;
        break;
    }
    this.setFirstActiveResult();
  }

  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.All_TIME:
        return null;
    }
  }

  private setFirstActiveResult(): void {
    if (this.testPlanResults.isFetching)
      setTimeout(() => this.setFirstActiveResult(), 500);
    else
      if (this.testPlanResults.isEmpty) this.environmentResults = null;
    this.activeExecutionResult = <TestPlanResult>this.testPlanResults['cachedItems'][0];
    // if (this.activeExecutionResult)
      // this.fetchEnvironmentResults();
  }

  private fetchEnvironmentResults(): void {
    this.environmentResults = null;
    this.environmentResultService.findAll("testPlanResultId:" + this.activeExecutionResult.id).subscribe(res => {
      this.environmentResults = res;
    });
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });

  }

  public setActiveResult(result: TestPlanResult) {
    this.activeExecutionResult = result;
    this.fetchEnvironmentResults();
    this.testCaseResultService.findAll(`iteration:null,testPlanResultId:${this.activeExecutionResult.id}`).subscribe((res: Page<TestCaseResult>) => {
      this.onBoardingSharedService.setLastRunId(res.content[0].id);
    });
    // this.router.navigate(['/td','runs',result.id])
  }

  /** Test case result redirect to the new page */
  onClickTestResultData(data:any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/run-history-view'], {
        queryParams: {
          testCaseId: data.testCase.id,
          runResultId: data.id,
          isManualExecution: data.isManualExecution
        }
      })
    );
    window.open(url, '_blank');
    // this.router.navigate(['run-history-view?testCaseId=', data.testCase.id, '&runResultId=', data.id,'&isManualExecution=false']);
  }
}
