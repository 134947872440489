import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { TestStepResultService } from 'app/services/test-step-result.service';
import { TestStepService } from 'app/services/test-step.service';
import { BaseComponent } from 'app/shared/components/base.component';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { ElementService } from 'app/shared/services/element.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-run',
  templateUrl: './add-run.component.html',
  styleUrls: ['./add-run.component.scss']
})
export class AddRunComponent extends BaseComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<AddRunComponent>,
    private formBuilder: FormBuilder,
    public authGuard: AuthenticationGuard,
    public toastrService: ToastrService,
    public translate: TranslateService,
    private testStepResultService: TestStepResultService,
    private elementService: ElementService,
    private testStepService: TestStepService,
    public notificationsService: NotificationsService,
  ) {  super(authGuard, notificationsService, translate,toastrService); }

  addRunForm: FormGroup;
  formValues: any[] = [];

  ngOnInit(): void {
    this.addRunForm = this.formBuilder.group({
      status: ['Manual', Validators.required],
      message: [this.modalData?.resultData?.message || ''],
      assignTo: [''],
      expectedResult: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.expected_result || ''],
      actualResult: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.actual_result || ''],
      reason: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.reason || ''],
      suggestions: [this.modalData?.resultData?.executedResult?.failed_test_steps[0]?.suggestions || ''],
      version: [''],
      elapsed: [''],
      defects: [''],
      image: [null]
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
  save(){

  }

}
