<div class="subscription-main tooltip" *ngIf="IsLicenceDataDisplay">
    <a href="#" class=" for-subscription " [routerLink]="['/pricing']">Click here for Subscription</a>
    <!-- <h1>Welcome to Subscription.........</h1> -->
    <div class="tooltiptext">
        <div class="flex-container">
            <div>Total Execution Time :<b> {{getTimeinMinutefromsec(dataValue.totalRunExecutionTime)}} Min </b></div>
            <div>Remaining Execution Time :<b> {{getTimeinMinutefromsec(dataValue.remainingRunExecutionTime)}} Min</b></div>
            <div>Total Code GenerateStep :<b> {{dataValue.totalCodeGenerateStep}}</b></div>
            <div></div>
            <div>Current Month's Usage :<b>
                    {{getTimeinMinutefromms(dataValue.currentMonthUsage)}} Min</b></div>
            <div>Last Month's Usage :<b>
                        {{getTimeinMinutefromms(dataValue.prevMonthUsage) }} Min</b></div>
            <div>Current Month's Ad-hoc Usage :<b>
                    {{getTimeinMinutefromms(dataValue.currentMonthAdhocUsage)}} Min</b></div>
            <div>Last Month's Ad-hoc Usage :<b>
                {{getTimeinMinutefromms(dataValue.prevMonthAdhocUsage) }} Min</b></div>
            <div>Current Month's Other Usage :<b>
                    {{getTimeinMinutefromms(dataValue.currentMonthOtherUsage)}} Min</b></div>
            <div>Last Month's Other Usage :<b>
                    {{getTimeinMinutefromms(dataValue.prevMonthOtherUsage) }} Min</b></div>

            <!-- <div>UsedCodeAiStep :<b> {{dataValue.}}</b></div> -->
        </div>
        <!-- <div class="bg-white border-rds-4  ng-scope p-15 shadow-all2-b4">
            <div class="text-uppercase text-list-header fz-11 pb-5" [translate]="'global.add_title'"></div>
        </div> -->
    </div>
</div>