import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from "@angular/core";
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { BaseComponent } from "app/shared/components/base.component";
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from "ngx-toastr";
import { NotificationsService } from "angular2-notifications";
import { TestDataType } from "../../enums/test-data-type.enum";
import { AuthenticationGuard } from "../../shared/guards/authentication.guard";
import { TestStepService } from "../../services/test-step.service";
import { TestStep } from "app/models/test-step.model";


@Component({
    selector: 'app-rest-form-validation',
    templateUrl: './rest-form-validation.component.html',
    styleUrls: ['rest-form-validation.component.scss']
})
export class RestFormValidationComponent extends BaseComponent implements OnInit, OnChanges {

    activeTabIndex = 0; // set the default active tab to 0 (the first tab)
    // @Input('forms') forms: FormGroup
    @Input("testStep") testStep;
    @Output() varificationData = new EventEmitter<any>();
    @Output() varificationData1 = new EventEmitter<any>();
    isjsonPathValid: boolean = false;
    @Output() jsonPathValid = new EventEmitter<boolean>();
    bodyForm: Array<any> = [];
    statusForm:Array<any> = [];
    expectedType = 'string';
    placeHolder = 'Expected Value in string'

    form: FormGroup = new FormGroup({
        body: new FormArray([
            new FormGroup({
                jsonPath: new FormControl('',Validators.required),
                verificationType: new FormControl('equals'),
                expectedType: new FormControl(this.expectedType),
                expectedValue: new FormControl('', [Validators.required, this.expectedValueValidator]),
            })
        ])
    });

    form1: FormGroup = new FormGroup({
        status: new FormArray([
            new FormGroup({
                verificationType: new FormControl('equals'),
                expectedType: new FormControl(this.expectedType),
                expectedValue: new FormControl(['', [Validators.required, this.expectedValueValidator]],),
            })
        ])
    });


    constructor(
        public authGuard: AuthenticationGuard,
        public notificationsService: NotificationsService,
        public translate: TranslateService,
        public toastrService: ToastrService,
        private testStepService: TestStepService,
        private formBuilder: FormBuilder
    ) {
        super(authGuard, notificationsService, translate, toastrService);

        this.saveFormData();
    }

    ngOnChanges(changes) {
        if (changes?.testStep?.currentValue) {
            // let validationForms = this.testStep.find(val => val.key == 'validationEvent')
            let validationForms = this.testStep.get('validationEvent')
            this.bodyForm = validationForms?.body ? validationForms.body : []
            this.statusForm = validationForms?.status ? validationForms.status : []

            if (this.bodyForm?.length > 1) {
                for (let i = 1; i < this.bodyForm.length; i++) {
                    this.onVarificationFormAdd()
                }

            }
            this.bodyForm?.forEach((val,i)=>{
                const newValue = val;
                if(val.expectedType == 'number'){
                  newValue.expectedValue = Number(val.expectedValue);
                }
                if(val.expectedType == 'string' || val.expectedType == 'boolean'){
                  newValue.expectedValue = String(val.expectedValue);
                }
                if(val.expectedType == 'array'){
                  newValue.expectedValue = JSON.stringify(val.expectedValue);
                }
                if(val.expectedType == 'object'){
                  newValue.expectedValue = JSON.stringify(val.expectedValue);
                }
                this.getValidationForm().at(i).patchValue(newValue);
                this.getValidationForm().updateValueAndValidity();
            })

            if (this.statusForm?.length > 1) {
                for (let i = 1; i < this.statusForm.length; i++) {
                    this.onVarificationFormAdd1()
                }

            }
            this.statusForm?.forEach((val,i)=>{
                this.getValidationForm1().at(i).patchValue(val);
                this.getValidationForm1().updateValueAndValidity();
            })

        }
    }



  onDataTypeChange(index, value) {
    const body = this.form.get('body') as FormArray;
    body.at(index).get('expectedValue').reset();
    this.placeHolder = 'Expected Value in ' + value
    if(value === 'boolean'){
      // set the default value true as a boolean value
      body.at(index).get('expectedValue').setValue('true');
    }

  }


  expectedValueValidator(control: AbstractControl) {
    const expectedType = control.parent?.get('expectedType')?.value;
    let newValue = control.value;
    if(expectedType === 'number'){
      newValue = Number(control.value)
    }
    if(expectedType === 'boolean'){
      newValue = Boolean(control.value)
    }
    if(expectedType === 'array'){
      try {
        newValue = JSON.parse(control.value);
      }catch (e) {
        return { invalidType: true, message: 'Invalid Array value' };
      }
    }
    if(expectedType === 'object'){
      try {
        newValue = JSON.parse(control.value);
      }catch (e) {
        return { invalidType: true, message: 'Invalid Object value' };
      }
    }
    if (expectedType) {
      switch (expectedType) {
        case 'string':
          if (typeof newValue !== 'string') {
            return { invalidType: true, message: 'Invalid String value' };
          }
          break;
        case 'number':
          if (isNaN(Number(newValue))) {
            return { invalidType: true, message: 'Invalid Number value' };
          }
          break;
        case 'boolean':
          if (typeof newValue !== 'boolean') {
            return { invalidType: true, message: 'Invalid Boolean value' };
          }
          break;
        case 'array':
          if (!Array.isArray(newValue)) {
            return { invalidType: true, message: 'Invalid Array value' };
          }
          break;
        case 'object':
          if (typeof newValue !== 'object') {
            return { invalidType: true, message: 'Invalid object value' };
          }
          break;
      }
    }
    return null;
  }
  expectedValueValidatorData(value: any, expectedType: any) {
    let newValue = value;
    if(expectedType === 'number'){
      newValue = Number(value)
    }
    if(expectedType === 'boolean'){
      newValue = Boolean(value)
    }
    if(expectedType === 'array'){
      try {
        newValue = JSON.parse(value);
      }catch (e) {
        return false;
      }
    }
    if(expectedType === 'object'){
      try {
        newValue = JSON.parse(value);
      }catch (e) {
        return false;
      }
    }
    if (expectedType) {
      switch (expectedType) {
        case 'string':
          if (typeof newValue !== 'string') {
              return false;
          }
          break;
        case 'number':
          if (isNaN(Number(newValue))) {
              return false;
          }
          break;
        case 'boolean':
          if (typeof newValue !== 'boolean') {
              return false;
          }
          break;
        case 'array':
          if (!Array.isArray(newValue)) {
              return false;
          }
          break;
        case 'object':
          if (typeof newValue !== 'object') {
              return false;
          }
          break;
      }
    }
    return true;
  }

    saveFormData() {
        this.form.valueChanges.subscribe(formData => {
            for (let i = 0; i < formData.body.length; i++) {
            let jsn = formData.body[i].jsonPath;
            let ev = formData.body[i].expectedValue;
            let et = formData.body[i].expectedType;
            if(!ev || !jsn){
                this.isjsonPathValid = false;
                this.jsonPathValid.emit(this.isjsonPathValid)
            }else{
                const isValid = this.expectedValueValidatorData(ev, et);
                if(!isValid){
                    this.isjsonPathValid = false;
                    this.jsonPathValid.emit(this.isjsonPathValid)
                }else {
                    this.isjsonPathValid = true;
                    this.jsonPathValid.emit(this.isjsonPathValid)
                }
            }
        }
        this.varificationData.emit(formData);
        });
        this.form1.valueChanges.subscribe(formData1 => {
            this.varificationData.emit(formData1);
        });
    }

    deleteRow(id) {
        const body = this.form.get('body') as FormArray;
        body.removeAt(id);
    }
    deleteRow1(id) {
        const status = this.form1.get('status') as FormArray;
        status.removeAt(id);
    }

    getValidationForm() {
        return this.form.get("body") as FormArray;
    }

    pushValidationForm() {
        return new FormGroup({
            jsonPath: new FormControl('',Validators.required),
            verificationType: new FormControl('equals'),
            expectedType: new FormControl(this.expectedType),
            expectedValue: new FormControl('', [Validators.required, this.expectedValueValidator]),
        })
    }

    onVarificationFormAdd() {
        this.getValidationForm().push(this.pushValidationForm())
    }

    addVarification() {
        this.isjsonPathValid = false;
        this.jsonPathValid.emit(this.isjsonPathValid)
        // this.activeTabIndex = this.tabGroup.selectedIndex;
        this.onVarificationFormAdd();
    }

    //second Form

    getValidationForm1() {
        return this.form1.get('status') as FormArray;
    }

    pushValidationForm1() {
        return new FormGroup({
            verificationType: new FormControl('equals'),
            expectedType: new FormControl(this.expectedType),
            expectedValue: new FormControl('', [Validators.required, this.expectedValueValidator]),
        })
    }

    onVarificationFormAdd1() {
        this.getValidationForm1().push(this.pushValidationForm1())
    }

    addVarification1() {
        // this.activeTabIndex = this.tabGroup.selectedIndex;
        this.onVarificationFormAdd1();
    }

  protected readonly event = event;
}
