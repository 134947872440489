/*
 * *****************************************************************************
 *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  All rights reserved.
 *  ****************************************************************************
 */

import {deserialize, serializable} from 'serializr';
import {Base} from "../../shared/models/base.model";
import { PageObject } from "app/shared/models/page-object";

export class NormalBrowser extends Base implements PageObject {
  @serializable
  public deviceName: String;
  @serializable
  public deviceKey: String;
  @serializable
  public deviceType: String;

  deserialize(input: any): this {
    return Object.assign(this, deserialize(NormalBrowser, input));
  }

  get isChrome() {
    return this.deviceName.toUpperCase() == 'GOOGLECHROME' || this.deviceName.toUpperCase() == 'CHROME';
  }

  get isFirefox() {
    return this.deviceName.toUpperCase() == 'FIREFOX';
  }

  get isSafari() {
    return this.deviceName.toUpperCase() == 'SAFARI';
  }

  get isEdge() {
    return this.deviceName.toUpperCase() == 'EDGE';
  }
}
