<div style="text-align: center" class="login-card bg-white border-rds-12 d-flex flex-column shadow-all-b10 p-40"
     xmlns="http://www.w3.org/1999/html">
  <img class="login-logo" src="/assets/color_icons/cqa-logo-with-text.svg"/>

  <div style="text-align: center">
    <h1 style="font-size: 82px; color: #5f5fbc">Oops....!!</h1>
    <h4 style="font-size: 30px">{{error}}</h4>
  </div>

  <!-- <div class="login-label rb-medium mb-35 fz-20 pt-10"
       [class.pt-50]="isGoogleAuth"
       [translate]="'login_form.sign_in_to_ContextQA'"></div> -->

</div>
