<div class="h-100">
  <div class="theme-overlay-container">
    <div class="theme-overlay-header">
      <div class="theme-overlay-title">
        <span [translate]="'test.step.details.modal.title'"></span>
        <span [textContent]="'(' + (testStep.stepDisplayNumber) + ')'"></span>
        <span class="pl-4" [translate]="'test.step.details.modal.title.details'"></span>
      </div>
      <button class="close" type="button" [matTooltip]="'hint.message.common.close' | translate"
        (click)="closeSuggestion()">
      </button>
    </div>
    <div class="theme-overlay-content without-footer mt-30" *ngIf="testStep">
      <div class="details-container value-md md">
        <div class="details-items ts-col-100" *ngIf="!testStep?.isRestStep">
          <div class="ts-col-100 d-flex">
            <label class="details-title ts-col-25" [translate]="'test_step.results.action'"></label>
            <div class="rb-medium" [class.pointer]="(testStep?.action)"
              [translate]="testStep?.type === 'STEP_GROUP' ? testStep?.stepGroup?.name : testStep?.action || testStep?.stepGroup?.name">
            </div>
          </div>
        </div>
        <div class="details-items ts-col-100" *ngIf="!testStep?.isRestStep">
          <div class="ts-col-100 d-flex">
            <label class="details-title ts-col-25" [translate]="'test_step.results.metadata'"></label>
            <div class="rb-medium" [class.pointer]="(testStep?.metadata)" [translate]="testStep?.metadata">
            </div>
          </div>
        </div>

        <div class="details-items ts-col-100" *ngIf="!testStep?.isRestStep">
          <div class="ts-col-100 d-flex">
            <label class="details-title ts-col-25" [translate]="'test_step.results.specs'"></label>
            <div class="rb-medium" [class.pointer]="(testStep?.specs)" [translate]="testStep?.specs ">
            </div>
          </div>
        </div>

        <div class="details-items ts-col-100 mt-3" *ngIf="!testStep?.isRestStep">
          <div class="ts-col-100 d-flex">
            <label class="details-title ts-col-25" [translate]="'test_step.results.description'"></label>
            <div class="rb-medium" [class.pointer]="(testStep?.description)" [translate]="testStep?.description  ">
            </div>
          </div>
        </div>

        <div class="details-items ts-col-100 mt-3" *ngIf="!testStep?.isRestStep">
          <div class="ts-col-100 d-flex">
            <label class="details-title" [translate]="'test_step.results.pw_locator'"></label>

            <div>
              <ul *ngFor="let pwLocatorList of testStep.event?.pwLocator">
                <li [translate]="pwLocatorList"></li>
              </ul>

            </div>
          </div>
        </div>
      </div>
      
      
      <div class="details-container value-md md" *ngIf="testStep?.stepGroupId">
        <div class="details-items-paramater ts-col-100">
          <h5>
            Set Parameters Default Value
          </h5>
          <hr>
          <div class="parameters-loading" *ngIf="isSpinner">
            <mat-spinner color="accent" diameter="50"></mat-spinner>
          </div>
          <div class="step-group-table" *ngFor="let childGroup of stepsGroupVariable; let i = index;">
            <h5>
            ( {{i + 1}} )  <span class="rb-semi-bold">{{ childGroup.name }}</span>
            </h5>
            <ng-container *ngIf="childGroup.variables && childGroup.variables.length > 0; else noVariables">
              <table class="w-100 parameter-border">
                <tr>
                  <th>Parameter Name</th>
                  <th>Parameter Value</th>
                </tr>
                <tr *ngFor="let childStep of childGroup.variables; let j = index;">
                  <td>{{ childStep?.name }}</td>
                  <td>
                    <input type="text" class="default-value" id="actionInput{{ j }}"
                      [(ngModel)]="childStep.parameterValue">
                  </td>
                </tr>
              </table>
            </ng-container>
            <ng-template #noVariables>
              <p class="error-message">Runtime variable not available</p>
            </ng-template>
          </div>
          
          <button class="border-rds-left-2 py-7 mt-10 pl-14 pr-14 btn-primary mr-0 fz-13" (click)="saveInputs()">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>