import { Component, OnInit } from '@angular/core';
import { BaseComponent } from "../../shared/components/base.component";
// import {LicenceSevices} from "../../../shared/services/LicenceSevices.service";
import { MobileInspectionService } from '../../shared/services/mobile-inspection-service';

import { LicenceSevices } from '../../shared/services/license.service'

@Component({
  selector: 'subscription-template',
  templateUrl: './subscription.componet.html',
  styleUrls: ["./subscription.componet.scss"]
})
export class SubscriptionComponet implements OnInit {
  public dataValue: any;
  public monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  public IsLicenceDataDisplay = false;
  constructor(public LicenceSevices: LicenceSevices) { }
  ngOnInit(): void {
    this.get_SubscriptionData()
  }
  get_SubscriptionData() {
    this.LicenceSevices.getAll().subscribe(data => {
      this.dataValue = data
      if (this.dataValue) {
        if (this.dataValue.totalRunExecutionTime * 0.25 < this.dataValue.remainingRunExecutionTime) {
          this.IsLicenceDataDisplay = false
        } else {
          this.IsLicenceDataDisplay = true
        }
      }
      // if(data.totalRunExecutionTime)
      // }
    })
  }

  getTimeinMinutefromms(timeInMs = 1) {
    let timestamp = timeInMs / 1000;  // Convert milliseconds to seconds
    let minutes = timestamp / 60;  // Calculate total minutes
    return Math.round(minutes);  // Round to nearest whole minute
}

  getTimeinMinutefromsec(timeInms = 1) {
    let timestamp = timeInms;  // Convert milliseconds to seconds
    let minutes = timestamp / 60;  // Calculate total minutes
    return Math.round(minutes);
  }
}
