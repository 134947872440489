// ai-test-case.service.ts
import { Injectable, ComponentFactoryResolver, ApplicationRef, Injector, EmbeddedViewRef } from '@angular/core';
import { AiTestCasePanelComponent } from 'app/components/webcomponents/ai-test-case-panel.component';

@Injectable({
    providedIn: 'root'
})
export class AiTestCaseService {
    private componentRef: any;

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) { }

    openPanel() {
        // Create component
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AiTestCasePanelComponent);
        this.componentRef = componentFactory.create(this.injector);

        // Attach component to the appRef so that it's inside the ng component tree
        this.appRef.attachView(this.componentRef.hostView);

        // Get DOM element from component
        const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

        // Append to body
        document.body.appendChild(domElem);

        // Listen to the closePanel event
        this.componentRef.instance.closePanel.subscribe(() => {
            this.closePanel();
        });
    }

    closePanel() {
        this.appRef.detachView(this.componentRef.hostView);
        this.componentRef.destroy();
    }
}
