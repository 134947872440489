import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ChromeExtensionService {
    constructor() { }

    isExtensionInstalled(extensionId: string): Promise<boolean> {
        return new Promise((resolve) => {
            if (typeof chrome !== 'undefined' && chrome.runtime && chrome.runtime.sendMessage) {
                chrome.runtime.sendMessage(extensionId, 'ping', response => {
                    resolve(!!response);
                });
            } else {
                resolve(false);
            }
        });
    }
}