<!-- eslint-disable @angular-eslint/template/eqeqeq -->
<div class="container" *ngIf="!loader">
    <div class="card first-card">
        <div class="toggle-button">
            <div class="action-border">
                <div class="actions">
                    {{Prop.status == "START" ? "STARTING" : Prop.status}}
                    <!-- <mat-icon>ads_click</mat-icon> Action -->
                </div>
            </div>
            <div class="success-count">
                <img src="/assets/images/checkmark.svg" alt="">
                <span>{{successSteps}}</span>
            </div>
            <div class="failed-count">
                <img src="/assets/images/remove_4.png" alt="">
                <span>{{failSteps}}</span>
            </div>
            <div class="duration">
                <span>{{time}}</span>
            </div>
            <div class="video-control" [matTooltip]="'Re Run'" (click)="reRun(true)">
                <img class="img" src="/assets/images/play-button.svg" alt="">
            </div>
        </div>
        <div class="accordian-steps">
            <mat-accordion multi>
                <mat-expansion-panel hideToggle [expanded]='true'>
                    <mat-expansion-panel-header class="expansion-panel-custom">
                        <mat-panel-title>
                            {{TestCase.name}}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                        <!-- <span class="material-icons">expand_more</span> -->
                    </mat-panel-description>
                    <!-- <div class="steps-more-info"
                        [ngClass]="{'success-step': testStep.executionData?.stepStatus == 'SUCCESS', 'failed-step': testStep.executionData?.stepStatus == 'FAILURE'}"
                        *ngFor="let testStep of TestSteps; let i = index;">
                        <div class="sucessc-icon-with-text">
                            <img *ngIf="testStep.executionData?.stepStatus == 'SUCCESS'"
                                src="/assets/images/checkmark.svg" alt="">
                            <img *ngIf="testStep.executionData?.stepStatus == 'FAILURE'"
                                src="/assets/images/remove_4.png" alt="">
                                <img *ngIf="testStep.executionData?.stepStatus != 'SUCCESS' && testStep.executionData?.stepStatus != 'FAILURE'" src="/assets/images/pending.svg" alt="">
                            <span [innerHtml]="testStep.action" class="fz-14"></span>
                        </div>
                        <div>
                            <mat-icon *ngIf="testStep.teststepResult?.hasImageDifference">visibility</mat-icon>
                        </div>
                    </div> -->
                    <div [ngClass]="{'success-step': steps.executionData?.stepStatus == 'SUCCESS', 'failed-step': steps.executionData?.stepStatus == 'FAILURE', 'failed-step-section-main': steps.executionData?.stepStatus == 'FAILURE'}"
                        *ngFor="let steps of TestSteps; let i = index;">
                        <mat-expansion-panel hideToggle [expanded]="expandedIndex === i"
                            (opened)="getStepDetailData(steps, i)">
                            <mat-expansion-panel-header class="expansion-panel-custom">
                                <mat-panel-title>
                                    <div class="steps-more-info">
                                        <div class="sucessc-icon-with-text">
                                            <img *ngIf="steps.executionData?.stepStatus == 'SUCCESS'"
                                                src="/assets/images/checkmark.svg" alt="">
                                            <img *ngIf="steps.executionData?.stepStatus == 'FAILURE'"
                                                src="/assets/images/remove_4.png" alt="">
                                            <img *ngIf="steps.executionData?.stepStatus != 'SUCCESS' && steps.executionData?.stepStatus != 'FAILURE'"
                                                src="/assets/images/pending.svg" alt="">
                                            <span [innerHtml]="steps.action" class="fz-14"></span>
                                        </div>
                                    </div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <mat-icon *ngIf="steps.teststepResult?.hasImageDifference">visibility</mat-icon>
                                    <span *ngIf="steps.stepGroupId"
                                        class="material-icons indicate-stepgroup">expand_more</span>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <!-- Step Group Inside Steps -->
                            <ng-container *ngIf="!stepGroupSteps.empty && steps.stepGroupId">
                                <div
                                    style="color: white;padding-top: 20px;padding-bottom: 20px;font-size: 16px;padding-left: 20px;">
                                    StepGroup Steps
                                </div>
                                <ng-container *ngFor="let groupStep of stepGroupSteps?.content;let i = index;">
                                    <mat-expansion-panel-header class="expansion-panel-custom step-group-panel">
                                        <mat-panel-title>
                                            <div class="steps-more-info">
                                                <div class="sucessc-icon-with-text">
                                                    <img *ngIf="steps.executionData?.stepStatus == 'SUCCESS'"
                                                        src="/assets/images/checkmark.svg" alt="">
                                                    <img *ngIf="steps.executionData?.stepStatus == 'FAILURE'"
                                                        src="/assets/images/remove_4.png" alt="">
                                                    <img *ngIf="steps.executionData?.stepStatus != 'SUCCESS' && steps.executionData?.stepStatus != 'FAILURE'"
                                                        src="/assets/images/pending.svg" alt="">
                                                    <span [innerHtml]="groupStep.action" class="fz-14"></span>
                                                </div>
                                            </div>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                </ng-container>
                            </ng-container>
                        </mat-expansion-panel>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </div>
    <div class="card first-card">
        <div class="toggle-button-img">
            <!-- <div class="video-control">
                <mat-icon class="img">more_horiz</mat-icon>
            </div>
            <div class="actions-button">
                Send to App
            </div>
            <div class="actions-button">
                Invite
            </div> -->
            <div [class.run_result_execution]="Prop.status == 'COMPLETED'" class="video-control"
                (click)="redirectToRunDetail()" [matTooltip]="'Execution may take a moment to complete.'">
                <mat-icon class="img">open_in_new</mat-icon>
            </div>
            <div class="video-control" (click)="fullModel()">
                <mat-icon class="img">info</mat-icon>
            </div>
            <div mat-button (click)="closeDialog()" class="video-control" *ngIf="isFull_Model">
                <mat-icon class="img">close</mat-icon>
            </div>
        </div>
        <div class="video-url">
            <!-- <div>
                <input type="text" class="custom-input" matInput [(ngModel)]="url" name="url">
            </div>
            <div class="video-resolution">
                <img src="/assets/images/trace 4.svg" alt="">
                <span>
                    1400*1410
                </span>
            </div> -->
        </div>
        <div *ngIf="Prop && Prop.imageUrl" width="50vw">
            <img [src]="Prop.imageUrl" width="100%">
        </div>
    </div>
    <div class="card" *ngIf="!isFull_Model">
        <div class="console-section">
            <div class="console-list-heading mr-10" [ngClass]="{'console-active': activeLog == 'log'}"
                (click)="activeLog = 'log'">
                <div class="actions">
                    AI logs
                </div>
            </div>
            <div class="console-list-heading mr-10" [ngClass]="{'console-active': activeLog == 'console'}"
                (click)="activeLog = 'console'">
                <div class="actions">
                    Console
                </div>
            </div>
            <div class="console-list-heading" (click)="activeLog = 'network'"
                [ngClass]="{'console-active': activeLog == 'network'}">
                <div class="actions">
                    Network
                </div>
            </div>
            <div mat-button mat-dialog-close class="close-heading">
                <div class="actions">
                    <mat-icon>close</mat-icon>
                </div>
            </div>
        </div>

        <div class="console-list">
            <ng-container *ngIf="activeLog == 'log'">
                <div #logContainer class="log">
                    <div class="log-step" *ngFor="let log of displayedLogs; let i = index">
                        <div class="log-number">Log Entry {{i + 1}}</div>
                        <div class="log-content" [class.complete]="log.complete">
                            {{log.text}}
                            <span *ngIf="!log.complete" class="typing-indicator">|</span>
                        </div>
                    </div>
                </div>

                <div style="padding: 15px; background-color: #1E1E1E; border-top: 1px solid #5B5B5B;">
                    <div *ngIf="!locatorNotDetect && !Conversation"
                        style="text-align: center; margin-bottom: 10px; color: #587d9a; background-color: #1E1E1E; border: 1px solid #5B5B5B; border-radius: 5px; padding: 10px;">
                        If we detect any metadata issues or other problems, you will have the option to enter a new
                        value directly. Please review and submit the corrected information. </div>

                    <div *ngIf="locatorNotDetect && !Conversation">
                        <div *ngIf="Conversation && Conversation.stepStatus != 'LOCATOR_NOT_DETECT' && Prop.status != 'COMPLETED'"
                            style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;gap: 10px;">
                            <div *ngIf="(remainingTime$ | async) as remainingTime" class="mini-timer-container">
                                <svg class="mini-progress-ring" width="44" height="44">
                                    <circle class="mini-progress-ring__circle" stroke="#3e98c7" stroke-width="4"
                                        fill="transparent" r="20" cx="22" cy="22"
                                        [style.strokeDasharray]="CIRCLE_CIRCUMFERENCE"
                                        [style.strokeDashoffset.px]="getDashOffset(remainingTime)" />
                                </svg>
                                <div class="mini-timer-text">
                                    <span class="mini-time">{{ formatTime(remainingTime) }}</span>
                                </div>
                            </div>
                            <input [disabled]="!locatorNotDetect" [(ngModel)]="message"
                                [type]="Conversation.stepStatus == 'TIMEOUT' ? 'number' : 'text'"
                                [placeholder]="Conversation.stepStatus == 'TIMEOUT' ? 'Enter Timeout in sec' : 'Enter Input'"
                                class="form-control" style="
                        height: 36px;
                        width: 75%;
                        padding: 17px;
                        
                        border-radius: 10px;
                        border: 1px solid rgb(128 128 128 / 32%);
                      " />
                            <button (click)="sentevent()" [disabled]="!message || message.trim() === ''"
                                class="theme-btn-primary" style="height: 36px; width: 20%; border-radius: 10px;">
                                Resume
                            </button>
                        </div>
                    </div>

                    <div *ngIf="Conversation && Prop.status != 'COMPLETED'">
                        <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 10px;gap: 10px;">
                            <div *ngIf="(remainingTime$ | async) as remainingTime" class="mini-timer-container">
                                <svg class="mini-progress-ring" width="44" height="44">
                                    <circle class="mini-progress-ring__circle" stroke="#3e98c7" stroke-width="4"
                                        fill="transparent" r="20" cx="22" cy="22"
                                        [style.strokeDasharray]="CIRCLE_CIRCUMFERENCE"
                                        [style.strokeDashoffset.px]="getDashOffset(remainingTime)" />
                                </svg>
                                <div class="mini-timer-text">
                                    <span class="mini-time">{{ formatTime(remainingTime) }}</span>
                                </div>
                            </div>
                            <input *ngIf="Conversation.stepStatus != 'LOCATOR_NOT_DETECT'" [(ngModel)]="message"
                                [disabled]="!Conversation || !Conversation?.replyBack_id"
                                [type]="Conversation.stepStatus == 'TIMEOUT' ? 'number' : 'text'"
                                class="form-control w-full d-inline-block mr-15" style="
                          height: 36px;
                          width: 70%;
                          padding: 17px;
                          border-radius: 10px;
                          border: 1px solid rgb(128 128 128 / 32%);
                        " [placeholder]="Conversation.stepStatus == 'TIMEOUT' ? 'Enter Timeout in sec' : 'Enter Input'" />
                            <div *ngIf="Conversation.stepStatus == 'LOCATOR_NOT_DETECT'"
                                class="" (click)="redirectToCE()"
                                [matTooltip]="'Redircet to Chrome Extenstion.'">
                                <button class="theme-btn-clear-default nlp-record-default-button mr-10 position-relative py-6 text-nowrap">
                                    <div class="rounded-circle btn mr-5 p-4 my-2 result-status-0"></div>
                                    <span>Record</span>
                                </button>
                                <!-- <mat-icon class="img">open_in_new</mat-icon> -->
                                <!-- <i class="fa fa-microphone"> Recording</i> -->
                                <!-- <button class="theme-btn-clear-default nlp-record-default-button mr-10 position-relative py-6 text-nowrap">
                                    <div class="rounded-circle btn mr-5 p-4 my-2 result-status-0"></div>
                                    <span>Record</span>
                                </button> -->
                            </div>
                            <button
                                [disabled]="Conversation.stepStatus != 'LOCATOR_NOT_DETECT' && (!message || message.trim() === '')"
                                class="theme-btn-primary" style="height: 36px; width: 20%" (click)="sentevent()">
                                Resume
                            </button>
                        </div>
                    </div>
                </div>


            </ng-container>

            <ng-container *ngIf="activeLog == 'console'">
                <div class="network" *ngIf="Prop?.messageLog">
                    <div class="network-list" *ngFor="let network of Prop?.messageLog">
                        <div class="network-data"
                            [ngClass]="{'put':network.type === 'WARNING','delete':network.type === 'ERROR',
                                                            'post':network.type === 'INFO','get':network.type === 'LOG','debug':network.type === 'DEBUG' }">
                            <div class="api-method">
                                {{network.type}}
                            </div>
                            <div class="url">
                                <div>
                                    <span>
                                        Location :
                                    </span> {{network.location}}
                                </div>
                                <div>
                                    <span>
                                        Text
                                    </span> : {{network.text || '-'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 *ngIf="!Prop?.messageLog">
                        No console log found!
                    </h5>
                </div>
            </ng-container>

            <ng-container *ngIf="activeLog == 'network'">
                <div class="network">
                    <div class="network-list" *ngFor="let network of Prop?.networkLog">
                        <div class="network-data"
                            [ngClass]="{'get':network.method === 'GET','post':network.method === 'POST',
                                                                'delete':network.method === 'DELETE','put':network.method === 'PUT' }">
                            <div class="api-method">
                                {{network.method}}
                            </div>
                            <div class="url">
                                <div>
                                    <span>
                                        URL :
                                    </span> {{network.url}}
                                </div>
                                <div>
                                    <span>
                                        Request
                                    </span> : {{network.reqData || '-'}}
                                </div>
                                <div>
                                    <span>
                                        Status
                                    </span> : {{network.status || '-'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 *ngIf="!Prop?.networkLog">
                        No network log found!
                    </h5>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<!--  -->
<div *ngIf="loader" class="execution-start-container">
    <div class="execution-start-content">
        <h1 class="execution-title">Execution Started</h1>
        <div class="animation-container">
            <svg class="animated-svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="45" stroke="#e0e0e0" stroke-width="10" fill="none" />
                <path class="animated-circle" d="M5 50a45 45 0 0 1 90 0" stroke="#007bff" stroke-width="10"
                    fill="none" />
            </svg>
        </div>
        <p class="execution-message">Please wait while we prepare your test execution.</p>
        <button class="close-button" mat-icon-button mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>