<div class="ts-col-100 h-100">
  <cdk-virtual-scroll-viewport
    itemSize="59"
    *ngIf="isSuiteCaseFetchComplete"
    class="bg-default theme-section-container card-container virtual-scroll-viewport viewport-height results-list">
    <a
      [routerLink]="['/td/test_case_results', testCaseResult.id]"
      class="list-card bordered sm-pm"
      *cdkVirtualFor='let testCaseResult of testCaseResults'>
      <div
        class="d-flex fz-12 ts-col-15 align-items-center">
        <app-re-run-icon [resultEntity]="testCaseResult"></app-re-run-icon>
        <app-result-pie-chart-column
          [width]="24"
          [height]="24"
          [resultEntity]="testCaseResult.lastRun || testCaseResult"></app-result-pie-chart-column>
      </div>
      <div
        class="d-flex flex-wrap ts-col-85">
        <div
          class="ts-col-100 list-title text-break"
          [textContent]="testCaseResult?.testCase?.name"></div>
        <div class="ts-col-100 pt-4 text-t-secondary fz-12 d-flex align-items-center">
          <div
            class="theme-breadcrumb ts-col-40">
            <span
              *ngIf="testCaseResult.testDeviceResult?.testDeviceSettings?.platform ||
              testCaseResult.testDeviceResult?.testDeviceSettings?.osVersion"
              class="theme-breadcrumb-item mw-100"
              [matTooltip]="('platform.name.'+testCaseResult.testDeviceResult.testDeviceSettings.platform | translate)  +
           ' ' + testCaseResult.testDeviceResult.testDeviceSettings.osVersion"
              [textContent]="('platform.name.'+testCaseResult.testDeviceResult.testDeviceSettings.platform | translate)  +
           ' ' + testCaseResult.testDeviceResult.testDeviceSettings.osVersion "></span>
          </div>
          <div
            class="text-nowrap px-10 text-center ts-col-30">
            <app-test-machine-info-column
              [environmentResult]="testCaseResult?.environmentResult"
              [testPlanResult]="testCaseResult?.testDeviceResult?.executionResult"
              [testDevice]="testCaseResult?.testDeviceResult?.testDevice"></app-test-machine-info-column>
          </div>
          <div
            class="d-flex justify-content-end text-nowrap ts-col-30">
            <div
              [textContent]="testCaseResult.startTime | date:'MMM d, h:mm:ss'"></div>
            <div class="pl-8">
              <app-duration-format [duration]="testCaseResult.duration"></app-duration-format>
            </div>
          </div>
        </div>
      </div>
    </a>
    <div *ngIf="testCaseResults.isEmpty" class="empty-full-container-transparent-bg">
      <div class="empty-full-content">
        <div class="empty-run-xs"></div>
        <div
          class="text-t-secondary pt-30 pb-18"
          [translate]="'message.common.search.not_found'"></div>
      </div>
    </div>
    <app-placeholder-loader *ngIf="testCaseResults?.isFetching"></app-placeholder-loader>
  </cdk-virtual-scroll-viewport>
</div>
