<div>
    <div style="width: 100vw;" *ngIf="isFetching">
        <app-placeholder-loader></app-placeholder-loader>
    </div>
    
    <div *ngIf="!isFetching && test_steps_executed && test_steps_executed.length"
        class="ts-col-100 h-100 overflow-hidden ts-col-100">
        <div class="d-flex pt-14 ng-star-inserted">
            <div class="list-container w-100 mt-n14 ng-star-inserted">
                <div class="w-100 scroll-item flex-wrap ng-star-inserted"
                    *ngFor="let data of test_steps_executed; let i = index">
                    <div class="ts-col-100 action-list-item-container hover pl-50">
                        <a class="list-view green-highlight lg-pm pointer pl-5 ts-col-100 py-16 text-break" style="width: 100vw;">
                            <div class="d-flex align-items-center ts-col-70" style="padding-left: 0px;">
                                <div class="align-items-center d-flex">
                                    <div class="d-flex justify-content-end pt-10 " style="padding-right: 7px;">
                                        <div class="result-status-8 list-number ng-binding x-sm-size text-white">{{i+1}}

                                        </div>
                                    </div>
                                    <div *ngIf="data?.teststepResult?.screenshotName">
                                        <img (click)="openScreenShort(data?.teststepResult?.screenshotName)" width="80px"
                                            height="70px" [src]="data?.teststepResult?.screenshotName">
                                    </div>
                                    <div class="pl-5">
                                        <span class="lh-1point4 ng-star-inserted" *ngIf="data.description">
                                            {{data.description}}
                                        </span>
                                        <span class="lh-1point4 ng-star-inserted" [innerHTML]="data.action" *ngIf="!data.description">
                                            
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class=" d-flex align-items-center ts-col-30">
                                <div class="align-items-center d-flex">
                                    <div class="pl-5">
                                        <button (click)="onSelectChange('SUCCESS' , data)"
                                            [class.passed-button]="data.teststepResult.result == 'SUCCESS'"
                                            class="exe-btn py-7 pl-14 pr-14 fz-13 ng-star-inserted mr-4" tabindex="0">
                                            <span>Passed</span>
                                        </button>
                                        <button (click)="onSelectChange('FAILURE', data)"
                                            [class.fail-button]="data.teststepResult.result == 'FAILURE'"
                                            class="exe-btn py-7 pl-14 pr-14  mr-4 fz-13 ng-star-inserted" tabindex="0">
                                            <span>Failed</span>
                                        </button>
                                        <button (click)="onSelectChange('NOT_EXECUTED', data)"
                                            [class.notexe-button]="data.teststepResult.result == 'NOT_EXECUTED'"
                                            class="exe-btn py-7 pl-14 pr-14  fz-13 ng-star-inserted mr-2" tabindex="0">
                                            <span>Not Executed</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isFetching && test_steps_executed && !test_steps_executed.length" class="h-100 ng-star-inserted " style="width: 79vw;">
        <div class="empty-full-container">
            <div class="empty-text">You currently have no results</div>
        </div>
    </div>
</div>