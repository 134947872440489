import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SharedDataService {
    isRunningValidate:boolean = false;
    public testCaseResultSubject = new Subject<any>();

    // Observable for other components to subscribe to
    testCaseResult$ = this.testCaseResultSubject.asObservable();

    // Method to update the value
    setTestCaseResult(data: any) {
        this.testCaseResultSubject.next(data);
    }
}
