import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TestPlanResult} from "../../models/test-plan-result.model";
import {TranslateService} from '@ngx-translate/core';
import {ToastrService} from "ngx-toastr";
import {ResultConstant} from "../../enums/result-constant.enum";
import {NotificationsService, NotificationType} from "angular2-notifications";
import {BaseComponent} from "../../shared/components/base.component";
import {AuthenticationGuard} from "../../shared/guards/authentication.guard";
import {TestPlanResultService} from "../../services/test-plan-result.service";
import {TestDeviceResultService} from "../../shared/services/test-device-result.service";
import {TestSuiteResultService} from "../../services/test-suite-result.service";
import {Page} from "../../shared/models/page";
import {TestDeviceResult} from "../../models/test-device-result.model";
import {TestSuiteResult} from "../../models/test-suite-result.model";
import {Pageable} from "../../shared/models/pageable";

@Component({
  selector: 'app-run-details-quick-info',
  templateUrl: './run-details-quick-info.component.html',
  styles: []
})
export class RunDetailsQuickInfoComponent extends BaseComponent implements OnInit {
  @Input('testPlanResult') testPlanResult: TestPlanResult;
  @Input('showList') showList: String;
  @Output('toggleDetailsAction') toggleDetailsAction = new EventEmitter<Boolean>();
  @Output('filterAction') filterAction = new EventEmitter<any>();
  @ViewChild('buildNoInput') buildNoInput: ElementRef;

  public results: Page<TestDeviceResult | TestSuiteResult>;
  public showRunDetails: Boolean;
  public resultConstant: typeof ResultConstant = ResultConstant;
  public isEditBuildNo: boolean = false;
  failed_test_steps;
  duration: string;


  constructor(
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private testPlanResultService: TestPlanResultService,
    private environmentResultService: TestDeviceResultService,
    private testSuiteResultService: TestSuiteResultService) {
    super(authGuard, notificationsService, translate, toastrService);
  }

  ngOnInit() {
    this.failed_test_steps = [{
      "actual_result": "\u001b[31mTest timeout of 30000ms exceeded.\u001b[39m",
      "expected_result": "Click on Continue",
      "failed_step_id": 8683,
      "failed_step_index": 11,
      "gif_url": "https://cqa-media.s3.us-east-2.amazonaws.com/d9a19d20-d9ab-4534-aa44-894280ac2bbe.gif",
      "screen_recording_url": "https://cqa-media.s3.us-east-2.amazonaws.com/ef2e8076-cd81-4e3f-b49c-fa6eca4b25ad.webm",
      "steps": ["Go to https://www.mcdonalds.com/us/en-us.html", "Click on menu", "Click on Our Menu ", "Click on Breakfast", "Go to https://www.mcdonalds.com/us/en-us/full-menu/breakfast.html", "Click on Bacon, Egg & Cheese Biscuit", "Go to https://www.mcdonalds.com/us/en-us/product/bacon-egg-cheese-biscuit.html", "Click on label", "Click on label", "Click on Order Now", "Check the radio button UberEats", "Click on Continue"],
      "trace_viewer_url": "https://cqa-media.s3.us-east-2.amazonaws.com/8ca82bad-e3d0-4c21-a6ca-765f963b4038.zip"
    }]
    // this.userService.show(this.testPlanResult.executedById).subscribe(user => this.testPlanResult.executedBy = user);
    if (this.testPlanResult.startTime && this.testPlanResult.endTime) {
      this.calculateDuration();
    }
  }

  calculateDuration() {
    const startTime:any = this.testPlanResult.startTime;
    const endTime: any = this.testPlanResult.endTime;
    const durationMs = endTime - startTime;
    this.duration = this.formatDuration(durationMs);
  }

  formatDuration(durationMs: number): string {
    const seconds = Math.floor((durationMs / 1000) % 60);
    const minutes = Math.floor((durationMs / (1000 * 60)) % 60);
    const hours = Math.floor((durationMs / (1000 * 60 * 60)) % 24);
    return `${hours}h ${minutes}m ${seconds}s`;
  }

  testStepsResultObject(){
    let failed_test_steps = [{
      "actual_result": "\u001b[31mTest timeout of 30000ms exceeded.\u001b[39m",
      "expected_result": "Click on Continue",
      "failed_step_id": 8683,
      "failed_step_index": 11,
      "gif_url": "https://cqa-media.s3.us-east-2.amazonaws.com/d9a19d20-d9ab-4534-aa44-894280ac2bbe.gif",
      "screen_recording_url": "https://cqa-media.s3.us-east-2.amazonaws.com/ef2e8076-cd81-4e3f-b49c-fa6eca4b25ad.webm",
      "steps": ["Go to https://www.mcdonalds.com/us/en-us.html", "Click on menu", "Click on Our Menu ", "Click on Breakfast", "Go to https://www.mcdonalds.com/us/en-us/full-menu/breakfast.html", "Click on Bacon, Egg & Cheese Biscuit", "Go to https://www.mcdonalds.com/us/en-us/product/bacon-egg-cheese-biscuit.html", "Click on label", "Click on label", "Click on Order Now", "Check the radio button UberEats", "Click on Continue"],
      "trace_viewer_url": "https://cqa-media.s3.us-east-2.amazonaws.com/8ca82bad-e3d0-4c21-a6ca-765f963b4038.zip"
    }]
  }

  ngOnChanges() {
    if (this.testPlanResult.startTime && this.testPlanResult.endTime) {
      this.calculateDuration();
    }
    if (this.showList == 'TCR') {
      this.testPlanResult.consolidateCount()
    }
    if (this.showList == 'TMR') {
      let page = new Pageable();
      page.pageSize = 100;
      this.environmentResultService.findAll("testPlanResultId:" + this.testPlanResult.id, undefined, page).subscribe(res => {
        this.results = res;
        if (this.testPlanResult?.lastRun) new TestPlanResult().consolidateListCount(res);
      });
    } else if (this.showList == 'TSR') {
      let page = new Pageable();
      page.pageSize = 1000;
      this.testSuiteResultService.findAll("testPlanResultId:" + this.testPlanResult.id, undefined, page).subscribe(res => {
        this.results = res
        if (this.testPlanResult.childResult) new TestPlanResult().consolidateListCount(res);
      });
    }
  }

  toggleDetails() {
    this.showRunDetails = !this.showRunDetails;
    this.toggleDetailsAction.emit(this.showRunDetails);
  }

  filter(query) {
    this.filterAction.emit({
      applyFilter: true,
      filterResult: [query]
    });
  }

  updateBuildId() {
    this.testPlanResultService.update(this.testPlanResult).subscribe({
      next: () => {
        this.translate.get("message.common.update.success", {FieldName: 'Build Number'}).subscribe((res: string) => {
          this.showNotification(NotificationType.Success, res);
          this.toggleBuildNo();
        });
      }, error:(error) => {
        this.translate.get("message.common.update.failure", {FieldName: 'Build Number'}).subscribe((res: string) => {
          this.showAPIError(error, res);
        });
    }
    })
  }

  toggleBuildNo() {
    this.isEditBuildNo = !this.isEditBuildNo;
    if (this.isEditBuildNo) {
      setTimeout(() => {
        this.buildNoInput.nativeElement.focus();
      }, 10);
    }
  }

  get passedPercentage(): Number {
    return Math.round(((this.results.content.filter(res => res.isPassed).length) / this.results.totalElements) * 100);
  }

  get failedPercentage(): Number {
    return Math.round(((this.results.content.filter(res => res.isFailed).length) / this.results.totalElements) * 100);
  }

  get notExecutedPercentage(): Number {
    return Math.round(((this.results.content.filter(res => res.isNotExecuted).length) / this.results.totalElements) * 100);
  }

  get abortedPercentage(): Number {
    return Math.round(((this.results.content.filter(res => res.isAborted).length) / this.results.totalElements) * 100);
  }

  get queuedPercentage(): Number {
    return Math.round(((this.results.content.filter(res => res.isQueued).length) / this.results.totalElements) * 100);
  }

  get stoppedPercentage(): Number {
    return Math.round(((this.results.content.filter(res => res.isStopped).length) / this.results.totalElements) * 100);
  }

  get passedCount(): Number {
    return this.results.content.filter(res => res.isPassed).length;
  }

  get failedCount(): Number {
    return this.results.content.filter(res => res.isFailed).length;
  }

  get notExecutedCount(): Number {
    return this.results.content.filter(res => res.isNotExecuted).length;
  }

  get abortedCount(): Number {
    return this.results.content.filter(res => res.isAborted).length;
  }

  get queuedCount(): Number {
    return this.results.content.filter(res => res.isQueued).length;
  }

  get stoppedCount(): Number {
    return this.results.content.filter(res => res.isStopped).length;
  }

  get totalCount(): Number {
    return this.results.content.length;
  }
}
