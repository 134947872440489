<div class="page-header d-flex fz-20 align-items-center">
  <ul class="tab-nav" role="tablist">
    <li class="nav-items" role="presentation">
      <a [routerLink]="['/td', versionId, 'plans']" [routerLinkActive]="'active'" class="normal-text">
        <span class="theme-text fz-18" [translate]="'test_run.title'"></span>
      </a>
    </li>
    <!-- <li
      *ngIf="testPlans?.content?.length"
      class="nav-items" role="presentation">
      <a
        [routerLink]="['/td', versionId, 'plans', 'schedules']"
        [routerLinkActive]="'active'"
        class="normal-text">
        <span class="theme-text fz-18" [translate]="'test_plan.schedule.title'"></span>
      </a>
    </li> -->
  </ul>
  <div *ngIf="testPlans?.content?.length || searchQuery.length || query?.length"
    class="align-items-center ml-auto d-flex flex-row">
    <app-toggle-search-button (searchAction)="search($event)"></app-toggle-search-button>
    <button (click)="fetchRunPlans(searchQuery)" [matTooltip]="'result.hint.click.here.refresh' | translate"
      class="btn icon-btn border-rds-2 ml-14">
      <i class="fa-refresh-thick"></i> </button>
    <!-- <button (click)="addmodelOpen()" class="theme-btn-primary ml-14"
      [textContent]="'+ '+('test_run.title'| translate)">
    </button> -->
    <app-sort-by-button class="pr-15 mt-n2" *ngIf="testPlans?.totalElements" [sortByColumns]="sortByColumns"
      [sortedBy]="sortedBy" [direction]="direction" [translatePreFix]="'test_suites.list.sort_by.'"
      (sortAction)="sortBy($event.sortBy, $event.direction)"></app-sort-by-button>
    <button (click)="openFilter()" [matTooltip]="'hint.message.common.filter' | translate"
      class="btn icon-btn border-rds-2 filter-icon-with-reset mr-15 ml-14">
      <i class="filter-icon" [class.filtered]="!!this.query"></i>
    </button>
    <div class="pl-15 theme-border-l">
      <app-pagination *ngIf="testPlans?.totalElements" [paginationData]="testPlans" [currentPage]="currentPage"
        (paginationAction)="fetchRunPlans()"></app-pagination>
    </div>
  </div>
</div>
<div class="page-content" style="overflow: auto">
  <div *ngIf="testPlans?.content.length != 0" class="list-header flex-wrap">
    <div class="ts-col-10 px-20">
      <app-inline-sort [heading]="'Id'">
      </app-inline-sort>
    </div>

    <div class="pr-10 d-flex ts-col-30">
      <input class="d-none" type="checkbox" />
      <span class="pl-10">
        <app-inline-sort [ascending]="sortedBy!='id'? undefined : direction == ',asc'"
          (click)="sortBy('id', ((sortedBy!='id' || direction == ',desc')?',asc':',desc'))"
          [heading]="'message.common.label.name'">
        </app-inline-sort>
      </span>
    </div>
    <div class="ts-col-20 px-20">
      <app-inline-sort [ascending]="sortedBy!='Author'? undefined : direction == ',asc'"
        (click)="sortBy('Author', ((sortedBy!='Author' || direction == ',desc')?',asc':',desc'))" [heading]="'Author'">
      </app-inline-sort>
    </div>
    <!-- <div class="ts-col-10 px-20">
      <app-inline-sort [ascending]="sortedBy!='environment'? undefined : direction == ',asc'" [heading]="'environment'"
        (click)="sortBy('environment', ((sortedBy!='environment' || direction == ',desc')?',asc':',desc'))"></app-inline-sort>
    </div> -->
    <div class="ts-col-10 px-20">
      <app-inline-sort [ascending]="sortedBy!='duration'? undefined : direction == ',asc'" [heading]="'duration'"
        (click)="sortBy('duration', ((sortedBy!='duration' || direction == ',desc')?',asc':',desc'))"></app-inline-sort>
    </div>
    <div class="ts-col-25 px-20">
      <app-inline-sort [ascending]="sortedBy!='status'? undefined : direction == ',asc'" [heading]="'status'"
        (click)="sortBy('status', ((sortedBy!='status' || direction == ',desc')?',asc':',desc'))"></app-inline-sort>
    </div>
  </div>
  <div class="list-container" style="overflow: auto">
    <div *ngFor="let testPlan of testPlans?.content; let i = index">
      <div class="list-view green-highlight lg-pm pl-10 pointer align-items-center">
        <a class="d-flex w-100">
          <!-- [routerLink]="['/td', 'testruns', testPlan.id]" -->
          <div class="ts-col-10 px-10">
            <span class="list-title">
              <a target="_blank" [routerLink]="['/td', 'testruns', testPlan.id]" [textContent]="'R - ' + testPlan.id">
              </a>
            </span>
          </div>
          <div class="ts-col-30 text-break d-flex">
            <div class="pr-30 d-none">
              <input type="checkbox" [(ngModel)]="testPlan.selected" [ngModelOptions]="{standalone: true}" />
            </div>
            <div class="pl-10 ts-col-100">
              <span class="list-title" [textContent]="testPlan?.testPlan?.name"></span>
              <div class="pt-4 text-t-secondary description-ellipsis" [innerHTML]="testPlan?.createdDate ? humanizedDate(testPlan?.createdDate) : '-'  ||
                ('results.list_view.no_description' | translate) "></div>
            </div>
          </div>
          <div class="ts-col-20 px-10">
            <span class="list-title" [textContent]="'Contex QA'"></span>

          </div>
          <!-- <div class="ts-col-10 px-10">
            <span class="list-title" [textContent]="testPlan.environment"></span>

          </div> -->
          <div class="ts-col-10 px-10">
            <span class="list-title" [textContent]="durationAsString(testPlan.duration)"></span>

          </div>
          <div class="ts-col-25 px-10">
            <!-- <button class="result_button list-btn py-7 pl-14 pr-14 fz-13 ng-star-inserted mr-4" [ngClass]="{
                            'success': testPlan.result === 'SUCCESS',
                            'failure': testPlan.result === 'FAILURE',
                            'not-executed': testPlan.result === 'NOT_EXECUTED'
                          }" tabindex="0">
              <span>
                {{ testPlan.result === 'SUCCESS' ? 'Passed' : testPlan.result === 'FAILURE' ? 'Failed' : 'Not Executed'
                }} &nbsp; ({{testPlan.totalCount}})
              </span>
            </button> -->

            <div class="progress custom-progress" [style.height]="'25px'">
              <div *ngIf="testPlan.failedCount" class="progress-bar" role="progressbar" [matTooltip]="'Failed'"
                [style.width.%]="getPercentage(testPlan.failedCount,testPlan.totalCount)"
                [style.background-color]="getStatusColor('Failed')">
                {{ testPlan.failedCount }}
              </div>
              <div class="progress-bar" role="progressbar" *ngIf="testPlan.stoppedCount" [matTooltip]="'Stopped'"
                [style.width.%]="getPercentage(testPlan.stoppedCount,testPlan.totalCount)"
                [style.background-color]="getStatusColor('Stopped')">
                {{ testPlan.stoppedCount }}
              </div>
              <div class="progress-bar" role="progressbar" *ngIf="testPlan.passedCount"
                [matTooltip]="'Execution Initiated successfully'"
                [style.width.%]="getPercentage(testPlan.passedCount,testPlan.totalCount)"
                [style.background-color]="getStatusColor('Execution Initiated successfully')">
                {{ testPlan.passedCount }}
              </div>
              <div class="progress-bar" role="progressbar" *ngIf="testPlan.abortedCount" [matTooltip]="'Aborted'"
                [style.width.%]="getPercentage(testPlan.abortedCount,testPlan.totalCount)"
                [style.background-color]="getStatusColor('Aborted')">
                {{ testPlan.abortedCount }}
              </div>
              <div class="progress-bar" role="progressbar" *ngIf="testPlan.notExecutedCount"
                [matTooltip]="'Not Executed'"
                [style.width.%]="getPercentage(testPlan.notExecutedCount,testPlan.totalCount)"
                [style.background-color]="getStatusColor('Not Executed')">
                {{ testPlan.notExecutedCount }}
              </div>
              <div class="progress-bar" role="progressbar" *ngIf="testPlan.totalQueuedCount" [matTooltip]="'Queued'"
                [style.width.%]="getPercentage(testPlan.totalQueuedCount,testPlan.totalCount)"
                [style.background-color]="getStatusColor('Queued')">
                {{ testPlan.totalQueuedCount }}
              </div>
            </div>

          </div>
        </a>
      </div>
    </div>


    <app-placeholder-loader *ngIf="!fetchingCompleted"></app-placeholder-loader>
  </div>
  <div *ngIf="!testPlans?.content.length" class="h-100">
    <div *ngIf="fetchingCompleted" class="empty-full-container">
      <div class="empty-run-md"></div>
      <div class="empty-text"
        [translate]="searchQuery ? 'message.common.search.not_found' : 'message.common.empty_page'| translate: {categoryType: 'Test Plans'}">
      </div>
      <button *ngIf="!searchQuery" [routerLink]="['new']" target="_parent" class="theme-btn-primary">
        +
        <span [translate]="'test_plan.run_results.create_test_plan'"></span>
      </button>
    </div>
  </div>
</div>