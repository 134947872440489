/*
 *
 *  * *****************************************************************************
 *  *  Copyright (C) 2020 Testsigma Technologies Inc.
 *  *  All rights reserved.
 *  *  ****************************************************************************
 *
 */
export enum TestStepType {
  AI_ACTION_TEST = "AI_ACTION",
  ACTION_TEXT = "ACTION_TEXT",
  STEP_GROUP = "STEP_GROUP",
  REST_STEP = "REST_STEP",
  FOR_LOOP = "FOR_LOOP",
  WHILE_LOOP= "WHILE_LOOP",
  BREAK_LOOP= "BREAK_LOOP",
  CONTINUE_LOOP= "CONTINUE_LOOP",
  CUSTOM_FUNCTION="CUSTOM_FUNCTION",
  AI_INTIGRATION="AI_INTIGRATION"
}

