import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActionRestApiFormComponent } from "app/components/webcomponents/action-rest-api-form/action-rest-api-form.component";
import { TestStepType } from "app/enums/test-step-type.enum";
import { TestStep } from "app/models/test-step.model";

@Injectable({
  providedIn: "root",
})
export class RestService {
  constructor(private matModal: MatDialog) {}
  openPopup(testStep: TestStep) {
    // console.warn(testStep);
    testStep.type = TestStepType.REST_STEP;
    return new Promise((resolve, reject) => {
      this.matModal
        .open(ActionRestApiFormComponent, {
          height: "100vh",
          width: "70%",
          position: { top: "0", right: "0" },
          // panelClass: ["mat-dialog", "rds-none"],/
          data: { testStep },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((event) => {
          if (!event) {
            return resolve(null);
          }
          const { title, request, store,validation,validationFinal } = event;
          resolve({
            validationFinal,
            validation,
            title,
            url: request.url,
            method: request.method,
            payload: request.payload,
            followRedirects: request.followRedirects ?? false,
            authorizationType: request.authorizationType ?? "NONE",
            authorizationValue: request.authorizationValue ?? {},
            variableName: store.variableName ?? '',
            requestHeaders:
              request.requestHeaders?.reduce((store, header) => {
                if (!header.key || !header.value) {
                  return store;
                }
                return {
                  ...store,
                  [header.key]: header.value,
                };
              }, {}) || {},
          });
        });
    });
  }
}
