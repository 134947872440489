<div class="details-page-container" style="height:165px">
  <app-placeholder-loader class="ts-col-100 d-flex-wrap" [isDetails]=true *ngIf="!isTestCaseFetchingCompleted"></app-placeholder-loader>
  <div
    *ngIf="isTestCaseFetchingCompleted"
    class="ts-col-100 d-flex-wrap">
    <div class="pt-15 d-flex align-items-start">
      <app-td-overlay-menu-button
        [versionId]="testCaseResult?.testDeviceResult?.testPlanResult?.testPlan?.workspaceVersionId"></app-td-overlay-menu-button>
      <!-- <a class="go-back-icon pl-10" *ngIf="!isDry"
         [matTooltip]="'hint.message.common.back' | translate"
         [routerLink]="['/td', 'cases', testCaseResult?.testCaseId, 'dry_runs']"></a>
      <a class="go-back-icon pl-10" *ngIf="isDry"
         [routerLink]="['/td', 'cases', testCaseResult?.testCaseId]"
         [matTooltip]="'hint.message.common.back' | translate"></a> -->
    </div>
    <div class="ts-col-100-77 ml-15">
      <div class="d-flex ts-col-100">
        <div class="ts-col-50 text-truncate">
          <div class="theme-breadcrumb" *ngIf="!isDry">
            <a
              class="theme-breadcrumb-item mw-25"
              [routerLink]="['/td/runs/', testCaseResult?.testPlanResultId]"
              [matTooltip]="testCaseResult?.testDeviceResult?.testPlanResult?.testPlan?.name"
              [textContent]="testCaseResult?.testDeviceResult.testPlanResult?.testPlan?.name"></a>
            <a
              class="theme-breadcrumb-item mw-25"
              [routerLink]="['/td/runs/', testCaseResult?.testPlanResultId]"
              [textContent]="'result.run_id' | translate: {id: testCaseResult?.testPlanResultId}"></a>
            <a
              class="theme-breadcrumb-item mw-25"
              [routerLink]="['/td/machine_results', testCaseResult?.environmentResultId]"
              [matTooltip]="testCaseResult?.testDeviceResult?.testDeviceSettings?.title"
              [textContent]="testCaseResult?.testDeviceResult?.testDeviceSettings?.title"></a>
            <a
              class="theme-breadcrumb-item mw-25"
              [routerLink]="['/td/suite_results', testCaseResult?.suiteResultId]"
              [matTooltip]="testCaseResult?.testSuite?.name"
              [textContent]="testCaseResult?.testSuite?.name"></a>
          </div>
          <div class="theme-details-header" [class.pt-15]="isDry">
            <a
              *ngIf="testCaseResult"
              [routerLink]="['/td', 'cases', testCaseResult?.testCaseId]"
              class="title-name"
              [matTooltip]="testCaseResult?.testCase?.name"
              [textContent]="testCaseResult?.testCase?.name"></a>
          </div>
        </div>
        <div class="ts-col-50">
        <div class="d-flex align-items-baseline justify-content-end pl-10 ml-auto mt-10 ">
          <button
            (click)="createTestCase()"
            [matTooltip]="'Create Testcase'"
            class="btn icon-btn border-rds-2 mr-5">
            <i class="fa-plus"></i>
          </button>
          <button
            (click)="refreshBtn()"
            [matTooltip]="'result.hint.click.here.refresh' | translate"
            class="btn icon-btn border-rds-2 mr-14">
            <i class="fa-refresh-thick"></i>
          </button>
          <app-test-case-result-details-pagination
            *ngIf="!isDry && !testCaseResult.isQueued"
            [testCaseResult]="testCaseResult"></app-test-case-result-details-pagination>
          <!-- <button class="theme-btn-primary ml-14" (click)="executionBtn()">Execution</button> -->
          <a (click)="openSummary()"
            *ngIf="!isDry"
            [matTooltip]="'result.hint.details' | translate"
            class="btn icon-btn border-rds-2 ml-14">
            <i class="fa-info"></i>
          </a>
          <div
            [class.ml-14]="testCaseResult?.testCase?.preRequisite"
            [class.dropdown]="testCaseResult?.testCase?.preRequisite"
            [class.mouse-over]="testCaseResult?.testCase?.preRequisite">
            <button
              [disabled]="startSync"
              *ngIf="(testCaseResult.iteration && !testCaseResult.parentResult?.isExecuting||!testCaseResult.iteration) && !testCaseResult?.isExecuting && isDry"
              (click)=" startExecution(getTestCaseResult()?.testDeviceResult?.testPlanResult?.dryTestPlan)"
              class="theme-btn-primary py-6">
              <i class="fa-filled-circled-play pr-4"></i>
              <span [translate]="startSync ? 'btn.common.please_wait' :'dry.testcase.btn.re_run'"></span>
            </button>
          </div>
          <button
            *ngIf="testCaseResult?.isExecuting && isDry"
            (click)="stopExecution(getTestCaseResult()?.testDeviceResult?.testPlanResult)"
            class="btn btn-delete">
            <i class="fa-stop-circle-solid pr-4"></i>
            <span [translate]="'hint.message.common.stop'"></span>
          </button>


          <button
            *ngIf="!isDry"
            [disabled]="testCaseResult?.isExecuting"
            (click)="reportBug()"
            [matTooltip]="'result.hint.report.bug' | translate"
            class="btn icon-btn border-rds-2 ml-14">
            <img src="/assets/images/jira-software-blue.svg" width="34px"/>
          </button>
          <button
            [disabled]="testCaseResult?.isExecuting"
            (click)="openUpdateStatus()"
            [translate]="'testcase.status.update.btn'"
            class="btn icon-btn border-rds-2 ml-14">
          </button>
          <button
            (click)="executionBtn()"
            [matTooltip]="'result.hint.extension' | translate"
            class="btn icon-btn border-rds-2 ml-14 text-danger">
            <!-- <i class="fa-trash mr-10"></i> -->
            <svg xmlns="http://www.w3.org/2000/svg" height="10" width="10" viewBox="0 0 512 512">
              <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
            </svg>
            <!-- <span [translate]="'btn.common.delete_permanently'"></span> -->
          </button>
          <div
            class="dropdown section-title mouse-over d-inline-block">
            <!-- <div class="btn-group">
              <button
                class="btn icon-btn border-rds-2 ml-14 mt-n2">
                <i class="fa-export-thin"></i>
              </button>
            </div> -->
            <div
              class="dropdown-menu drop-down-transparent min-w left-md">
              <ul
                class="bg-white border-rds-4 ng-scope p-15 shadow-all2-b4">
                <li
                  class="border-rds-10 btn rb-medium grey-on-hover text-dark">
                  <a
                    [href]="( isDry? '/dry_test_case_results':'/test_case_results')+ '/export/'+ testCaseResult?.id"
                    class="text-dark text-decoration-none"
                    target="_blank" [translate]="'runs.details.export_as_xls'"></a>
                </li>
                <li
                  class="border-rds-10 btn rb-medium grey-on-hover text-dark ml-0">
                  <a
                    [href]="'/reports/junit/'+testCaseResult?.testDeviceResult?.testPlanResult?.id"
                    class="text-dark text-decoration-none"
                    target="_blank" [translate]="'runs.details.export_as_junit'"></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div class="ts-col-100 position-relative d-flex pt-18" [class.pt-30]="isDry">
        <div class="details-container ts-col-70 flex-nowrap no-margin align-items-center">
          <div class="pr-20 position-relative" *ngIf="!isRoutedThroughRunManually">
            <div class="highChart ml-auto">
              <app-result-donut-chart
                *ngIf="testCaseResult && getTestCaseResult()"
                [width]="68"
                [height]="68"
                [resultEntity]="getTestCaseResult()"></app-result-donut-chart>
            </div>
            <i
              (click)="openSummary()"
              [matTooltip]="'hint.message.common.expand_summary' | translate"
              class="expand-icon-slope"></i>
          </div>
          <div>
            <div class="details-items text-nowrap mt-n5">
              <div class="pb-4 details-info align-items-center d-flex mt-n5">
                <div *ngIf="testCase?.preRequisite && isDry" class="mr-10">
                  <a
                    class="pr-4"
                    (click)="preReqNavigate()"
                    [matTooltip]="'result.pre_requisite_navigate' | translate">
                    <i class='fas fa-eye fa-2x text-t-secondary'></i>
                  </a>
                </div>
                <app-result-status-label-info *ngIf="testCaseResult && getTestCaseResult()" && !isRoutedThroughRunManually
                                              [result]="getTestCaseResult()"></app-result-status-label-info>
                <div
                  class="align-items-center d-flex text-theme pointer"
                  *ngIf="(getTestCaseResult()?.isFailed || getTestCaseResult()?.isAborted) && !(isRest)" && !isRoutedThroughRunManually
                  (click)="openResultFailedHelp()">
                  <i class="fa-idea pr-7 fz-24"></i>
                  <span [translate]="'testcase_result.why_failed'"></span>
                </div>
              </div>
            </div>
            <div
              class="details-items mt-5 text-nowrap">
              <div class="details-info">
                <div class="d-flex justify-content-center">
                  <div
                    [textContent]="getTestCaseResult()?.startTime | date:'h:mm a MMM d, y'"></div>
                  <div class="pl-10">
                    <app-duration-format
                      [duration]="getTestCaseResult()?.duration"></app-duration-format>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="!(testCaseResult?.testDeviceResult?.testPlanResult?.environment?.name == null)"
            class="details-items pl-40 mw-20 text-nowrap">
            <label class="details-title" [translate]="'runs.details.environment'"></label>
            <div
              class="details-info text-truncate mt-10"
              [matTooltip]="testCaseResult?.testDeviceResult?.testPlanResult?.environment?.name"
              [textContent]="testCaseResult?.testDeviceResult?.testPlanResult?.environment?.name || '-'"></div>
          </div>
          <app-lab-environment-screen-short-info style="width: 22vw;"
            *ngIf="testCaseResult && getTestCaseResult()"
            class="text-nowrap"
            [environmentResult]="getTestCaseResult()?.testDeviceResult"
            [testDevice]="getTestCaseResult()?.testDeviceResult?.testDevice"
            [testPlanResult]="getTestCaseResult()?.testDeviceResult?.testPlanResult"></app-lab-environment-screen-short-info>
          <div class="details-items pr-10 mt-30 mw-20 text-nowrap" [ngSwitch]="!testCaseResult?.isExecuting && !testCaseResult?.totalCount && isTestCaseFetchingCompleted">
            <div *ngSwitchCase="true"
                 class="align-items-center d-flex details-info mt-2">
              <span
                class="text-truncate pointer"
                [innerHTML]="testCaseResult.message"
                matTooltip="{{testCaseResult?.message}}"></span>
            </div>
            <div *ngSwitchDefault
                 class="align-items-center d-flex details-info mt-2 text-link pointer" (click)="openSummary()">
              <span
                [translate]="'hint.message.common.more'"
                class="text-truncate"></span>
            </div>
          </div>
          <div *ngIf="testCaseResult?.isManualExecution" style=" margin-bottom: 17px"
          class="details-items mt-30 mw-20 text-nowrap form-group ">
          
          <button 
          (click)="onSelectChange('SUCCESS')"  [class.passed-button] ="testCaseResult.result == 'SUCCESS'" class="exe-btn py-7 pl-14 pr-14 fz-13 ng-star-inserted mr-4"  tabindex="0">
            <span>Passed</span>
          </button>
          <button
          (click)="onSelectChange('FAILURE')"   [class.fail-button] ="testCaseResult.result == 'FAILURE'"  class="exe-btn py-7 pl-14 pr-14  mr-4 fz-13 ng-star-inserted"   tabindex="0">
            <span>Failed</span>
          </button>
          <button 
          (click)="onSelectChange('NOT_EXECUTED')" [class.notexe-button] ="testCaseResult.result == 'NOT_EXECUTED'"  class="exe-btn py-7 pl-14 pr-14  fz-13 ng-star-inserted mr-2"   tabindex="0">
            <span>Not Executed</span>
          </button>
          
          <!-- <mat-form-field class="mat-select-custom" appearance="fill">
            <mat-select class="custom-select" placeholder="Select Result" [(value)]="defaultValue"
                        (selectionChange)="onSelectChange($event)">
              <mat-option value="NOT_EXECUTED" >Not Executed</mat-option>
              <mat-option value="SUCCESS">Passed</mat-option>
              <mat-option value="FAILURE" >Failed</mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
        </div>
        

      </div>
    </div>
  </div>
</div>
<div class="d-flex theme-details-scroll-content" style="height: calc(100% - 225px)">
  <div class="ts-col-100">
    <div
      class="page-details-navigation-md">
      <div  *ngIf="testCaseResult?.parentId" class="ml-n45 ts-col-23">
      <app-test-case-data-driven-results
        class="bg-grey-x-light d-block pl-45 ts-col-100 py-4"
        *ngIf="testCaseResult"
        [resultEntity]="testCaseResult"></app-test-case-data-driven-results>
      </div>
      <ul class="tab-nav" role="tablist">
        <li class="nav-items" role="presentation" >
          <a
            (click)="activeTab = 'execution-details'"
            [class.md-height-border]="testCaseResult?.parentId"
            [class.active]="activeTab === 'execution-details'"
            class="normal-text">
            <i class="fa-list"></i>
            <span [translate]="'page_title.run_details'"></span>
          </a>
        </li>

        <li class="nav-items" role="presentation">
          <a
            (click)="activeTab = 'steps'"
            [class.active]="activeTab === 'steps'"
            [class.md-height-border]="testCaseResult?.parentId"
            class="normal-text">
            <i class="fa-list"></i>
            <span [translate]="'result.test_step.executed'"></span>
          </a>
        </li>
        <li class="nav-items" role="presentation">
          <a
            (click)="activeTab = 'steps_current'"
            [class.active]="activeTab === 'steps_current'"
            [class.md-height-border]="testCaseResult?.parentId"
            class="normal-text">
            <i class="fa-list"></i>
            <span [translate]="'result.test_step.current'"></span>
          </a>
        </li>

        <li class="nav-items" role="presentation" *ngIf="defaultValue =='FAILURE'">
          <a
            (click)="activeTab = 'impact_analysis'"
            [class.active]="activeTab === 'impact_analysis'"
            [class.md-height-border]="testCaseResult?.parentId"
            class="normal-text">
            <i class="fa-list"></i>
            <span [translate]="'result.test_step.impact_analysis'"></span>
          </a>
        </li>



        <!-- <li class="nav-items" role="presentation">
          <a
            (click)="activeTab = 'attachment'"
            [class.md-height-border]="testCaseResult?.parentId"
            [class.active]="activeTab == 'attachment'"
            class="normal-text">
            <i class="fa-pin-alt"></i>
            <span [translate]="'message.common.attachments'"></span>
          </a>
        </li> -->
      </ul>
      <div
        [class.d-flex]="activeTab === 'steps'"
        [class.align-items-center]="activeTab === 'steps'"
        class="ml-auto">
        <app-auto-refresh
          *ngIf="(testCaseResult?.isQueued || testCaseResult?.parentResult?.isQueued)"
          [autoRefreshIntervalTime]="autoRefreshInterval"
          (autoRefreshIntervalTime)="changeAutoRefreshTime($event)"
          (autoRefreshToggle)="toggleAutoRefresh($event)"></app-auto-refresh>
      </div>

    </div>

    <div class="d-flex ts-col-100 theme-details-scroll h-100">
      <div class="ts-col-100 h-100 bg-default" *ngIf="activeTab === 'impact_analysis'">
        <impact-analysis></impact-analysis>
        <!-- <app-steps-list
          class="h-100"
          [testCaseId]="testCaseResult?.testCase?.id"
          [headerTabListhidden]="true"
        ></app-steps-list> -->
      </div>

      <div class="ts-col-100 h-100 bg-default" *ngIf="activeTab === 'steps_current'">
        <app-steps-list
          class="h-100"
          [testCaseId]="testCaseResult?.testCase?.id"
          [headerTabListhidden]="true"
        ></app-steps-list>
      </div>
      <div *ngIf="activeTab === 'steps' && !testCaseResult?.isExecuting && !hasSteps && isTestCaseFetchingCompleted"
           class="empty-full-container w-100">
        <div class="empty-run-md" *ngIf="!testCaseResult.message"></div>
        <div class="empty-text" [translate]="'test_step.no_steps'"*ngIf="!testCaseResult.message"></div>


      </div>
      <div class="d-flex ts-col-100 h-100" *ngIf="activeTab === 'steps' && !is_Model && !testCaseResult?.isManualExecution">
        <div
          *ngIf="(testCaseResult?.isExecuting || !testCaseResult?.isQueued) "
          class="h-100 rb-regular"
          [ngStyle]="{'flex': !isEditStep? '0 0 calc(63% + 45px' : '0 0 100%', 'max-width': !isEditStep? 'calc(63% + 45px)': '100%'}">
          <app-test-step-results
            *ngIf="testCaseResult"
            (onStepEditAction)="setEditToggle($event)"
            (onStepDetails)="triggerPopup()"
            (onStepNavigate)="navigateToStepResult($event)"
            (onFirstFailedStep)="setFirstFailedStep($event)"
            (selectedtestCase)="setSelectedTestCase($event)"
            (hasSteps)="setStepsCount($event)"
            [is_Model]="is_Model"
            [resultEntity]="testCaseResult"></app-test-step-results>
          <app-placeholder-loader *ngIf="!isTestCaseFetchingCompleted||testCaseResult?.isExecuting"></app-placeholder-loader>
        </div>
        <div
          cdkOverlayOrigin #trigger="cdkOverlayOrigin"
          [class.d-none]="!isEditStep">
        </div>
        <ng-template
          cdkConnectedOverlayPanelClass="pop-hover-step-details"
          cdkConnectedOverlayHasBackdrop="true"
          cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
          #detailsRef="cdkConnectedOverlay"
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="testStepDetailsOpen">
          <div
            *ngIf="testCaseResult?.isRunning || !testCaseResult?.isQueued"
            class="rb-regular d-flex h-100 ts-col-100 step-details-pop-up">
            <router-outlet></router-outlet>
          </div>
        </ng-template>
        <div
          *ngIf="(testCaseResult?.isRunning || !testCaseResult?.isQueued) && !isEditStep && !is_Model"
          class="rb-regular d-flex h-100 ts-col-37-45 w-100">
          <router-outlet></router-outlet>
        </div>
        <div *ngIf="is_Model">
          <app-test-step-result-details  *ngIf="is_Model"
          class="rb-regular d-flex h-100 ts-col-37-45 w-100"
          [testResultId]="selectedTestCase"
          [resultId]="testCaseResultId">
          </app-test-step-result-details>
        </div>
      </div>

      <div class="d-flex ts-col-100 h-100" *ngIf="activeTab === 'steps'  && testCaseResult?.isManualExecution && !isFetching">
        <app-test-step-executed *ngIf="test_steps_executed !== undefined" style="overflow: auto;" [isFetching]="isFetching" [test_steps_executed]="test_steps_executed">
        </app-test-step-executed>
      </div>

      <div class="overflow-y-auto d-flex ts-col-100 h-100 bg-default ml-md-20" *ngIf="activeTab === 'execution-details'">
        <div class="col-md-4 details-container" *ngIf="hasDetailsData() && activeTab === 'execution-details'">
          <div [translate]="'page_title.run_details'"  *ngIf="activeTab === 'execution-details'">
            <div class="legend-container d-flex flex-wrap justify-content-between align-items-right ts-col-100"
                 style="margin-top: 10px; cursor: auto;"
                 *ngIf="testCaseResult?.executedResult?.failed_test_steps">
              <div class="legend-item ts-col-100 pb-25" style="cursor: default;"
                   *ngIf="testCaseResult?.executedResult?.failed_test_steps">
                <i
                  class="result-status-1 legend-icon  fa-show legend-icon btn result-status-active-1">
                </i>
                <span
                  [textContent]="('message.common.failed_step_index' | translate) + ' : '+
                testCaseResult?.executedResult?.failed_test_steps[0]?.failed_step_index +''"></span>
              </div>

              <div class="legend-item ts-col-100 pb-25" style="cursor: default;"
                   *ngIf="testCaseResult?.executedResult?.failed_test_steps[0]?.actual_result">
                <i
                  class="result-status-0 legend-icon  fa-show legend-icon btn">
                </i>
                <span
                  [textContent]="('message.common.actual_result' | translate) + ' : '+
                testCaseResult?.executedResult?.failed_test_steps[0]?.actual_result +''"></span>
              </div>

              <div class="legend-item ts-col-100 pb-25" style="cursor: default;"
                   *ngIf="testCaseResult?.executedResult?.failed_test_steps[0]?.expected_result">
                <i
                  class="result-status-2 legend-icon  fa-show legend-icon btn result-status-active-2">
                </i>
                <span
                  [textContent]="('message.common.expected_result' | translate) + ' : '+
                testCaseResult?.executedResult?.failed_test_steps[0]?.expected_result +''"></span>
              </div>

              <div class="legend-item ts-col-100 pb-25" style="cursor: default;"
                   *ngIf="testCaseResult?.executedResult?.failed_test_steps[0]?.reason">
                <i
                  class="result-status-3 legend-icon  fa-show legend-icon btn result-status-active-3">
                </i>
                <span
                  [textContent]="('message.common.reason' | translate) + ' : '+
                testCaseResult?.executedResult?.failed_test_steps[0]?.reason +''"></span>
              </div>

              <ng-container *ngIf="testCaseResult?.executedResult?.failed_test_steps[0]?.suggestions">
              <div class="legend-item ts-col-100" style="cursor: default; display: flex; align-items: baseline">
                <i class="result-status-5 legend-icon fa-show legend-icon btn result-status-active-5"></i>
                <span class="ts-col-100" [textContent]="('message.common.suggestions' | translate) + ' : ' "></span>&nbsp;
                <!-- Use *ngFor to iterate over suggestions and create a new span for each with a line break -->
              </div>
              <div class="ml-25 pb-25">
                <span *ngFor="let suggestion of testCaseResult?.executedResult?.failed_test_steps[0]?.suggestions.split('\n')">
                  {{ suggestion }} <br>
                </span>
              </div>
              </ng-container>
              

              <div class="legend-item ts-col-100 pb-25">
                <div   *ngIf="testCaseResult?.executedResult?.failed_test_steps[0]?.stepScreenshotUrl" class="mr-10">
                  <i
                    (click)="openScreenShort()"
                    [matTooltip]="'hint.message.common.extend' | translate"
                    class="fa-expand-alt btn icon-btn ml-14"><span >Screen Shot</span></i>
                </div>
                <div *ngIf="testCaseResult?.executedResult?.failed_test_steps[0]?.type === 'TS_TIMEOUT' || testCaseResult?.executedResult?.failed_test_steps[0]?.type === 'LOCATOR_ND'">
                  <span class="btn icon-btn" (click)="openDialog(testCaseResult?.executedResult?.failed_test_steps[0])">Quick Resolve</span>
                </div>
              </div>
              

            </div>

            <ng-template #dialogTemplate>
              <h1 mat-dialog-title>Dialog</h1>
              <div mat-dialog-content>
                <p>This is a simple dialog!</p>
              </div>
              <div mat-dialog-actions>
                <button mat-button mat-dialog-close>Close</button>
              </div>
            </ng-template>
            <!-- autoHealedSteps is looping  -->
            <div class="legend-container d-flex flex-wrap justify-content-between align-items-right ts-col-100"
                 *ngIf="testCaseResult?.executedResult?.autoHealedSteps" style="margin-top: 10px">
              <div class="table-container legend-item ts-col-100 pb-25">
                <table class="table">
                  <thead>
                  <tr>
                    <th translate="message.common.auto_HealedSteps_Index"></th>
                    <th translate="message.common.auto_HealedSteps_accuracy"></th>
                    <th translate="message.common.auto_HealedSteps_beforeLocator"></th>
                    <th translate="message.common.auto_HealedSteps_afterLocator"></th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let DIsplayAutoHedalSteps of testCaseResult?.executedResult?.autoHealedSteps">
                    <td>{{ DIsplayAutoHedalSteps.stepIndex }}</td>
                    <td>{{ DIsplayAutoHedalSteps.aiAccuracyScore | number: '1.2-2' }}</td>
                    <td>{{ DIsplayAutoHedalSteps.beforeLocator }}</td>
                    <td>{{ DIsplayAutoHedalSteps.afterLocator }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <!-- comented Recorded Video -->
            <div *ngIf="testCaseResult?.screenRecordings && testCaseResult?.screenRecordings != null">
              <h2>Recorded Video

                    <span class="ml-3 font-normal fs-15 text-capitalize">
                      {{testCaseResult?.testDeviceResult?.testDevice.title}}
                      <span
                        [matTooltip]="
                         (testCaseResult?.testDeviceResult?.testDevice?.platform ? ('platform.name.'+testCaseResult?.testDeviceResult?.testDevice?.platform | translate) : '') +
                         ((testCaseResult?.testDeviceResult?.testDevice?.platform && testCaseResult?.testDeviceResult?.testDevice?.osVersion)? '( '+ testCaseResult?.testDeviceResult?.testDevice?.osVersion+' ) ' : ' ') +
                         (testCaseResult?.testDeviceResult?.testDevice?.browser? ('browser.name.'+testCaseResult?.testDeviceResult?.testDevice?.browser | translate) : testCaseResult?.testDeviceResult?.testDevice?.deviceName) +
                         (testCaseResult?.testDeviceResult?.testDevice?.browserVersion ? '( '+testCaseResult?.testDeviceResult?.testDevice?.browserVersion+' )' : '')"
                        class="mr-5 fz-15 text-t-secondary" [class.fa-chrome]="testCaseResult?.testDeviceResult?.testDevice?.isChrome"
                        [class.fa-firefox-brands]="testCaseResult?.testDeviceResult?.testDevice?.isFirefox"
                        [class.fa-safari-brands]="testCaseResult?.testDeviceResult?.testDevice.isSafari"
                        [class.fa-edge]="testCaseResult?.testDeviceResult?.testDevice?.isEdge"
                        [class.fa-apple]="!testCaseResult?.testDeviceResult?.testDevice?.browser && testCaseResult?.testDeviceResult?.testDevice?.isIOS"
                        [class.fa-android-solid]="!testCaseResult?.testDeviceResult?.testDevice?.browser && testCaseResult?.testDeviceResult?.testDevice?.isAndroid"></span>
                    </span>
              </h2>
            
              <div *ngFor="let screenRecording of testCaseResult?.screenRecordings">
                <div [translate]="'step_result.recording'"  style="border: 2px solid; margin-top: 20px;display: flex;justify-content: center; align-items: center;">
                  <video width="100%" height="100%" src="{{screenRecording}}" type="video/webm" autoplay controls></video>
                </div>
              </div>
            </div>

            <!-- comented Recorded Video -->
            <!-- <div *ngIf="testCaseResult?.gifs">
              <h2>GIFS </h2>
              <div *ngFor="let gif of testCaseResult?.gifs">
                <div [translate]="'step_result.recording'"  style="border: 2px solid; margin-top: 20px;display: flex;justify-content: center; align-items: center;">
                  <video width="100%" height="100%" src="{{gif}}" type="video/webm" autoplay controls></video>
                </div>
              </div>
            </div> -->
            
            <div>

            </div><br><br>
          </div>
        </div>
        <!-- Recorded Video -->
        <!-- <div class="col-md-8">
          <h2 *ngIf="apiTraceViewerUrl" style="cursor: pointer;" title="downloadFile" (click)="downloadFile(apiTraceViewerUrl)"> Trace View</h2>
          <div *ngIf="!!testCaseResult?.screenRecordingUrl">
            <h2>Recorded Video</h2>
            <div style="padding-right: 15px;">
              <div [translate]="'step_result.recording'"  style="border: 2px solid; margin-top: 20px;display: flex; justify-content: center; align-items: center;">
              <video width="100%" height="100%" src="{{testCaseResult?.screenRecordingUrl}}" type="video/webm" autoplay controls></video>
            </div>
            </div>
            <br><br><br>
          </div>
        </div> -->
        <!-- Hided trase viwer  -->
        <div class="col-md-8">
          <div style="padding-right: 15px;">
            <span *ngIf="testCaseResult?.traceViewers && testCaseResult?.traceViewers != null">
              <h2>Trace View</h2>
              <div *ngFor="let traceViewers of traceViewer; let i = index;">
                <div style="width: 98%; height: 700px; margin-top: 10px;overflow: auto; border: 2px solid">
                  <div style="width: 100%; height: 100%;" class="iframe-container">
                    <ng-container>
                      <iframe style="width: 100%; height: 110%; border: none;" [src]="traceViewers" title=""></iframe>
                    </ng-container>
                  </div>
                </div>
              </div>
            </span>

            <span *ngIf="mobileTraceViewerUrl && mobileTraceViewerUrl != null">
              <h2>Trace View</h2>
                <div style="width: 98%; height: 700px; margin-top: 10px;overflow: auto; border: 2px solid">
                  <div style="width: 100%; height: 100%;" class="iframe-container">
                    <ng-container>
                      <iframe style="width: 100%; height: 110%; border: none;" [src]="mobileTraceViewerUrl" title=""></iframe>
                    </ng-container>
                  </div>
              </div>
            </span>
          </div>
          <br><br><br><br><br>
        </div>
      </div>
      <div class="ts-col-100 h-100 bg-default" *ngIf="activeTab === 'attachment'">
        <app-result-attachment
          *ngIf="activeTab === 'attachment'"
          [attachmentRow]="'TESTCASE_RESULT'"
          [attachmentRowId]="testCaseResult?.id"
          class="h-100 d-flex"></app-result-attachment>
      </div>
    </div>

  </div>
</div>
