import {Component, NgZone, OnInit} from '@angular/core';
import {FormControl, FormGroup, FormBuilder} from "@angular/forms";
import * as moment from "moment";
import {FilterOperation} from "../../enums/filter.operation.enum";
import {ResultConstant} from "../../enums/result-constant.enum";
import {FilterQuery} from "../../models/filter-query";
import {TestCaseTag} from "../../models/test-case-tag.model";
import {TestSuiteTagService} from "../../services/test-suite-tag.service";
import {BaseComponent} from "../../shared/components/base.component";
import {Page} from "../../shared/models/page";
import {TestCaseType} from "../../models/test-case-type.model";
import {UserPreferenceService} from "../../services/user-preference.service";
import {WorkspaceVersionService} from "../../shared/services/workspace-version.service";
import {WorkspaceVersion} from "../../models/workspace-version.model";
import {TestCaseTypesService} from "../../services/test-case-types.service";
import {ChartConfiguration, ChartOptions} from 'chart.js';


@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  host: {'class': 'page-content-container'},
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent extends BaseComponent implements OnInit {
  maxDate = new Date();
  public filterTagIds: number[];
  public tags: TestCaseTag[];
  public normalizedQuery: FilterQuery[];
  public isIssueTypeShow: Boolean = false;
  public testCaseTypesList: Page<TestCaseType>;
  version: WorkspaceVersion;
  agentForm: FormGroup;
  // Pie
  public pieChartOptions: ChartOptions<'pie'> = {
    responsive: false,
  };
  pieChartLabels = [ [ 'Pass' ], [ 'Fail' ], ['Not Executed'] ];
  pieChartDataset: any = [];
  pieChartLegend = true;
  pieChartPlugins = [];


  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData = {};

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    responsive: false,
  };


  constructor(private testSuiteTagService: TestSuiteTagService,
              private versionService: WorkspaceVersionService,
              private userPreferenceService: UserPreferenceService,
              private testCaseTypeService: TestCaseTypesService,
              private fb: FormBuilder,
              private ngZone: NgZone) {
    super()
  }

  ngOnInit(): void {
    this.agentForm = this.fb.group({
      dateRange: this.fb.group({
        start: [''],
        end: [''],
      }),
      tags: [], // To store selected tags
      testCaseTypes: [], // To store selected test case types
    });
    this.splitQueryHash();
    this.fetchTags();
    this.fetchVersion();
    this.getTrends({ fromDate: '',
      toDate: '',
      labels: [],
      types: 1})
    this.getHealth({ fromDate: '',
      toDate: '',
      labels: [],
      types: 1})
  }

  private fetchTestCaseTypes(): void {
    this.testCaseTypeService.findAll("workspaceId:" + this.version.workspaceId).subscribe(res => {
      this.testCaseTypesList = res;
    });
  }

  fetchVersion() {
    this.userPreferenceService.show().subscribe(res => {
      if (res?.versionId) {
        this.versionService.show(res.versionId).subscribe(res => {
          this.version = res;
          this.fetchTestCaseTypes();
        });
      }
    });
  }

  private fetchTags() {
    this.testSuiteTagService.findAll(undefined).subscribe(res => {
      this.tags = res;
    });
  }

  toggleDropdown(dropDown: string) {
    this[dropDown] = !this[dropDown];
  }


  getHealth(formData) {
    this.userPreferenceService.getHealth(formData).subscribe(res => {
      const dataArray = []
      dataArray.push( parseFloat(res.pass) || 0,parseFloat(res.fail)  || 0, parseFloat(res.notExecuted)  || 0);
      this.ngZone.run(() => {
        this.pieChartDataset = [{ data: dataArray }];
      });
      // this.pieChartDataset.push({data: dataArray});
    });
  }

  getTrends(formData) {
    this.userPreferenceService.getTrends(formData).subscribe(res => {
      const labels = [];
      const datasets = [];
      const pass = [];
      const fail = [];
      const notExecuted = [];
      this.ngZone.run(() => {
        res.values.forEach((value)=>{
          labels.push(moment(value?.dateValue).date());
          pass.push(value?.pass || 0);
          fail.push(value?.fail || 0);
          notExecuted.push(value?.notExecuted || 0);
        })
        datasets.push(
            {data: pass,label: 'Pass'},
            {data: fail, label: 'Fail'},
            {data: notExecuted, label: 'Not Executed'}
        )
        this.barChartData = {
          labels: labels,
          datasets: datasets
        }
      });
    });
  }

  onSubmit() {
    if (this.agentForm.valid) {
      // Perform your submission logic here
      const formData = this.agentForm.value;
      const newObject = {
        fromDate: formData.dateRange.start ? moment(formData.dateRange.start).format("YYYY-MM-DD") : '',
        toDate: formData.dateRange.end ? moment(formData.dateRange.end).format("YYYY-MM-DD") : '',
        labels: [formData.tags || ''],
        types: formData.testCaseTypes
      }
      this.getHealth(newObject)
      this.getTrends(newObject)
    } else {
      // Handle form validation errors or display messages
      // console.log('Form is invalid. Please check the fields.');
    }
  }
  constructQueryString() {
    let queryString = "";
    if (this.agentForm?.valid) {
      // queryString += ",createdDate>" + moment.utc(this.createdDateRange.getRawValue().start).local().format("YYYY-MM-DD");
      // queryString += ",createdDate<" + moment.utc(this.createdDateRange.getRawValue().end).local().format("YYYY-MM-DD");
    }
  }

  private splitQueryHash() {
  }

}
