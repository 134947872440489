<div>
  <div class="align-items-baseline ml-auto filter-show">
    <div >
      <h2 >Mobile Trace View</h2>
      <button
        (click)="openAddEditElement(null)"
        class="theme-btn-primary "
        style="margin-top: -39px;
    margin-right: 67px; float: right"
        [translate]="'btn.common.createElement'"></button>
    </div>

  </div>
  <div>
    <div style=" height: 700px; margin-top: 10px;
          overflow: auto !important; border: 2px solid">
      <div style="width: 100%; height: 100%; overflow: auto !important" class="iframe-container">
        <iframe style="width: 100%; height: 110%; border: none; overflow: auto !important"
                src="https://inspector.appiumpro.com/" title="" ></iframe>
      </div>
    </div>
  </div>

</div>
