import { Component, Input, OnInit } from '@angular/core';
import { WorkspaceVersion } from "../../models/workspace-version.model";
import { TestCaseService } from "../../services/test-case.service";
import { ByStatusCount } from "../../models/by-status-count.model";
import { TranslateService } from '@ngx-translate/core';
import { FilterTimePeriod } from "../../enums/filter-time-period.enum";
import * as moment from "moment";
import { LicenceSevices } from "../../shared/services/license.service";
@Component({
  selector: 'app-test-case-by-status-summary',
  templateUrl: './test-case-by-status-summary.component.html',
  styles: []
})
export class TestCaseByStatusSummaryComponent implements OnInit {
  @Input('version') version: WorkspaceVersion;
  public color = {
    READY: '#6366F1',
    UPDATE: '#F0B14C',
    OBSOLETE: '#F86920',
    IN_REVIEW: '#3C8FE2',
    DRAFT: '#C4C4C4',
    REWORK: '#d80a00'
  };
  public entity: any;
  public period: FilterTimePeriod = FilterTimePeriod.LAST_90_DAYS;
  filterObj: any;
  
  constructor(
    private testCaseService: TestCaseService,
    private translate: TranslateService,
    public LicenceSevices: LicenceSevices) {
  }

  ngOnInit(): void {
    this.LicenceSevices.dayFilter$.subscribe((data: any) => {
      this.filterObj = data;
      if (data?.customDays) {
        this.period = data.customDays;
        this.callCountAPI();
      }
    });
    this.callCountAPI();
  }

  callCountAPI() {
    const date = this.getFormattedDate(this.period);
    this.testCaseService.byStatusCount(this.version.id, date).subscribe((res: ByStatusCount[]) => {
      this.entity = [];
      res.forEach(item => {
        let statusItem = {};
        this.translate.get('testcase.status_' + item.status).subscribe(res => {
          statusItem['name'] = res;
        });
        statusItem['y'] = item.count
        statusItem['color'] = this.color[item.status]
        this.entity.push(...[statusItem])
      })
    });
  }


  private getFormattedDate(key: FilterTimePeriod): String {
    switch (key) {
      case FilterTimePeriod.TODAY:
        return moment().format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_SEVEN_DAYS:
        return moment().subtract(7, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_30_DAYS:
        return moment().subtract(30, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_90_DAYS:
        return moment().subtract(90, 'd').format("YYYY-MM-DD");
      case FilterTimePeriod.LAST_180_DAYS:
        return moment().subtract(180, 'd').format("YYYY-MM-DD");
      // case FilterTimePeriod.All_TIME:
        // return moment().subtract(180, 'd').format("YYYY-MM-DD");
    }
  }
}
