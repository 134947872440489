import {EventEmitter, Injectable} from '@angular/core';
import {OnBoarding} from "../enums/onboarding.enum";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class OnBoardingSharedService {

  public onEventComplete: EventEmitter<OnBoarding> = new EventEmitter();
  private lastRunId = new BehaviorSubject('');
  lastRunId$ = this.lastRunId.asObservable();

  constructor() {}

  public emitCompleteEvent(preferenceName: OnBoarding) {
    this.onEventComplete.emit(preferenceName);
  }

  public getPreferencesEmitter() {
    return this.onEventComplete;
  }

  setLastRunId(data) {
    this.lastRunId.next(data);
  }
}
