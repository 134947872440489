import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { WorkspaceVersion } from "../../../models/workspace-version.model";
import { AuthenticationGuard } from "../../guards/authentication.guard";
import { UserPreferenceService } from "../../../services/user-preference.service";
import { WorkspaceVersionService } from "../../services/workspace-version.service";
import { WorkspaceService } from "../../../services/workspace.service";
import { Page } from "../../models/page";
import { Workspace } from "../../../models/workspace.model";
import { UserPreference } from "../../../models/user-preference.model";
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatDialog } from '@angular/material/dialog';
import { OnBoarding } from "../../../enums/onboarding.enum";
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Pageable } from 'app/shared/models/pageable';

@Component({
  selector: 'app-workspace-switcher',
  templateUrl: './workspace-switcher.component.html',
  styles: [`
  .inner-menu{
    box-shadow: rgba(0,0,0,0.1) 0px 0px 2px 2px;
    border-radius: 3px;
    background: white;
    overflow: hidden;
  }

  .item-max-height{
    max-height: 10rem;
    overflow: auto;
  }
    .workspace-container {
  overflow-y: auto;
  height: 100vh;
}

.header-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
      width: calc(100% - 70px);
}

.close-btn {
  margin-left: 20px;
}

.workspace-grid {
  display: flex;
  flex-wrap: wrap;
}

.workspace-card {
     flex: 0 0 calc(30% - 0px);
    margin: 15px;
    border: 1px solid #e0e0e0;
    border-radius: 7px;
    display: flex;
    flex-direction: column;

}

.workspace-card:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.card-header {
  display: flex;
  align-items: center;
  padding: 15px;
}

.card-icon {
  margin-right: 15px;
}

.card-content {
  flex-grow: 1;
  padding: 0 15px 15px;
}

.card-actions {
  padding: 15px;
  border-top: 1px solid #e0e0e0;
}

.live-btn-container {
  position: relative;
}

.live-btn {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.dropdown-menu {
  display: none;
  position: absolute;
    left: 0;
    width: 100%;
    background: #F4F5FF;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin:0 !important;
}

.dropdown-menu.show {
  display: block;
}

.live-btn-container:hover .dropdown-menu {
  display: block;
}

.dropdown-header {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
      font-size: 14px;
}

.dropdown-items {
  max-height: 200px;
  overflow-y: auto;
  
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

@media (max-width: 1200px) {
  .workspace-card {
    flex: 0 0 calc(33.333% - 30px);  /* 3 cards per row on smaller screens */
  }
}

@media (max-width: 992px) {
  .workspace-card {
    flex: 0 0 calc(50% - 30px);  /* 2 cards per row on even smaller screens */
  }
}

@media (max-width: 768px) {
  .workspace-card {
    flex: 0 0 calc(100% - 30px);  /* 1 card per row on mobile */
  }
}

.search-form {
  margin-bottom: 20px;
  padding: 0 15px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  button {
    margin: 0 10px;
    background: #6366F1;
    color: white;
  }
}

  `]
})
export class WorkspaceSwitcherComponent implements OnInit {
  @Input('version') version: WorkspaceVersion;
  @Input('leftNavPath') leftNavPath?: string;
  @Output('onProjectSwitch') onProjectSwitch = new EventEmitter<void>();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('changeStep', { static: false }) public changeStepRef: ElementRef;
  activeDropdown: number | null = null;
  cqaOrigin: any;


  public userPreference: UserPreference;
  public show: Boolean = false;
  public applications: Page<Workspace>;
  public versions: any;
  public projectSwitcherForm: FormGroup;
  public applicationsData;

  public currentPage = 0;
  public pageSize = 10;
  public searchForm: FormGroup;

  constructor(
    private router: Router,
    private dialogRef: MatDialog,
    private userPreferenceService: UserPreferenceService,
    private workspaceService: WorkspaceService,
    private versionService: WorkspaceVersionService,
    public authGuard: AuthenticationGuard) {
    this.searchForm = new FormGroup({
      searchTerm: new FormControl('')
    });
  }

  get liveWebVersions() {
    return this.versions?.content;
  }

  get liveMobileWebVersions() {
    return this.versions?.content?.filter(version => !version.workspace.isDemo && version.workspace.isMobileWeb);
  }

  get liveAndroidVersions() {
    return this.versions?.content?.filter(version => !version.workspace.isDemo && version.workspace.isAndroidNative);
  }

  get liveiOSVersions() {
    return this.versions?.content?.filter(version => !version.workspace.isDemo && version.workspace.isIosNative);
  }


  get sampleWebApplicationVersion() {
    return this.versions?.content?.find(version => version.workspace.isWeb && version.workspace.isDemo);
  }


  get sampleAndroidApplicationVersion() {
    return this.versions?.content?.find(version => version.workspace.isAndroidNative && version.workspace.isDemo);
  }

  get sampleiOSApplicationVersion() {
    return this.versions?.content?.find(version => version.workspace.isIosNative && version.workspace.isDemo);
  }

  get sampleMobileWebApplicationVersion() {
    return this.versions?.content?.find(version => version.workspace.isMobileWeb && version.workspace.isDemo);
  }

  ngOnInit() {
    this.cqaOrigin = window.location.hostname.split('.')[0];
    this.fetchWorkspaces();
    this.setupSearch();
  }

  setupSearch() {
    this.searchForm.get('searchTerm').valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.currentPage = 0;
        this.fetchVersions();
      });
  }

  fetchWorkspaces() {
    this.userPreferenceService.show().subscribe(res => {
      this.userPreference = res;
      this.fetchVersions();
      this.fetchApplications();
    });
  }


  go(version: WorkspaceVersion) {
    this.activeDropdown = null; // Close the dropdown after selection
    this.userPreference.selectedVersion = version;
    this.userPreference.selectedWorkspace = version.workspace;
    let url = this.router.url.split('/').splice(1);
    this.userPreferenceService.save(this.userPreference).subscribe(res => {
      this.userPreference = res;
      if (url[0] == 'dashboard') {
        this.goToDashboard();
      } else if (isNaN(parseInt(this.leftNavPath))) {
        let leftNavPath = this.leftNavPath;
        if (leftNavPath == 'runs' || leftNavPath == 'test_case_results' || leftNavPath == 'suite_results' || leftNavPath == 'machine_results')
          leftNavPath = "results";
        this.router.navigate(["/" + url[0], this.userPreference.versionId, leftNavPath]);
      } else {
        this.router.navigate(["/" + url[0], this.userPreference.versionId, url[1]]);
      }
      this.closeToggleModal();
    });
  }

  goToDashboard() {
    let shouldReuseMethod = this.router.routeReuseStrategy.shouldReuseRoute;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/dashboard']);
    setTimeout(() => {
      this.router.routeReuseStrategy.shouldReuseRoute = shouldReuseMethod;
      this.router.onSameUrlNavigation = 'ignore';
    }, 300);
  }

  toggle() {
    this.show = !this.show;
    this.fetchVersions();
  }

  toggleModal() {
    this.trigger.openMenu();
    this.toggle();
  }

  closeToggleModal() {
    this.show = false;
    this.trigger.closeMenu();
    this.sendCloseDialog();
  }

  menuHide() {
    this.dialogRef.closeAll();
  }

  enableModalClick(event) {
    event.stopPropagation();
  }

  formatSearchTerm(searchTerm: string): string {
    if (!searchTerm) return '';
    return `name:*${searchTerm}*,workspaceVersionId:${this.version?.id || 1}`;
  }

  fetchVersions() {
    const pageable: Pageable = {
      pageNumber: this.currentPage,
      pageSize: this.pageSize,
      sort: null,
      offset: 0,
      paged: false,
      deserialize: function (input: any): Pageable {
        throw new Error('Function not implemented.');
      },
      id: 0,
      createdAt: undefined,
      updatedAt: undefined,
      name: undefined,
      disabled: undefined,
      serialize: function (): JSON {
        throw new Error('Function not implemented.');
      }
    };

    const searchTerm = this.formatSearchTerm(this.searchForm.get('searchTerm').value);

    this.versionService.findAll(searchTerm, null, pageable).subscribe(res => {
      this.versions = res;
      this.applicationsData = res.content


    });
  }

  onPageChange(page: number) {
    this.currentPage = page;
    this.fetchVersions();
  }

  fetchApplications() {
    this.workspaceService.findAll().subscribe(res => {
      this.applications = res;
      this.applicationsData = this.applications.content
    })
  }

  sendCloseDialog() {
    this.onProjectSwitch.emit();
  }

  stopAction($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    return false;
  }

  toggleDropdown(id: number): void {
    if (this.activeDropdown === id) {
      this.activeDropdown = null;
    } else {
      this.activeDropdown = id;
    }
  }

}
