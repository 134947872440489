import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PopupOpenComponent } from "../../manually-test-run-popup/popupOpen.component";
import { ScreenShortOverlayComponent } from "../../../components/webcomponents/screen-short-overlay.component";
@Component({
  selector: 'app-test-step-executed',
  templateUrl: './test-step-executed.component.html',
  styleUrls: ['./test-step-executed.component.scss']
})
export class TestStepExecutedComponent implements OnInit {
  @Input() test_steps_executed! :any;
  @Input() isFetching! :boolean;
  defaultValue = 'NOT_EXECUTED';
  constructor(public matModal: MatDialog) {}

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes.isFetching && !changes.isFetching.firstChange) {
  //     console.log('isFetching changed:', this.isFetching);
  //   }
  //   if (changes.test_steps_executed && !changes.test_steps_executed.firstChange) {
  //     this.test_steps_executed = [...this.test_steps_executed];
  //     console.log('test_steps_executed changed:', this.test_steps_executed);
  //   }
  // }

  
  ngOnInit(): void {
  }

  openScreenShort(goldenScreenshot) {
    this.matModal.open(ScreenShortOverlayComponent, {
      data: {screenShortUrl: goldenScreenshot},
    });
  }

  onSelectChange(event: any, data) {
    this.defaultValue = event;
    let teststepResultdata = {
      content : [data.teststepResult]
    }
      this.matModal.open(PopupOpenComponent, {
        width: '1000px',
        height: '540px',
        data: {selectedvalue: event, resultId: data.teststepResult.id, resultData: data.teststepResult , testStepResult: teststepResultdata}
      });
  }

}
