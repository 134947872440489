<!--<div class="d-flex flex-column h-100">-->
<!--  <div class="dashboard-content">-->
<!--    <div class="d-flex flex-wrap ts-col-100">-->
<!--      <div style="margin-left: 10px; margin-bottom: 17px"-->
<!--           class="details-items mt-30 mw-30 text-nowrap form-group ">-->
<!--        <mat-form-field class="mat-select-custom ts-col-100 mh-50 d-flex" appearance="fill">-->
<!--          <mat-label [translate]="'testcase.list.select_range_date'"></mat-label>-->
<!--          <mat-date-range-input [rangePicker]="createdDateRangePicker" [formGroup]="createdDateRange" [max]="maxDate">-->
<!--            <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>-->
<!--            <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>-->
<!--          </mat-date-range-input>-->
<!--          <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>-->
<!--          <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="ts-col-50">-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
<!--<form class="d-flex flex-column h-100">-->
<!--  <div class="pt-25 px-40">-->
<!--    <div class="ts-col-100 details-header d-flex mb-100 flex-wrap pr-20">-->
<!--      <div class="ts-col-50">-->
<!--        <mat-form-field class="mat-select-custom  mh-50 d-flex" appearance="fill">-->
<!--          <mat-label [translate]="'testcase.list.select_range_date'"></mat-label>-->
<!--          <mat-date-range-input [rangePicker]="createdDateRangePicker" [formGroup]="createdDateRange" [max]="maxDate">-->
<!--            <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>-->
<!--            <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>-->
<!--          </mat-date-range-input>-->
<!--          <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>-->
<!--          <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="ts-col-50" style="margin-left: 20px">-->

<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</form>-->


<!--<form-->
<!--        class="ts-form"-->
<!--        id="agentFormContainer"-->
<!--        (keydown.enter)="false"-->
<!--        novalidate="novalidate"-->
<!--        [formGroup]="agentForm"-->
<!--        (ngSubmit)="onSubmit()"-->
<!--        name="agentForm"-->
<!--        style="margin: 10px auto; max-width: 600px;"-->
<!--&gt;-->
<!--  <div>-->
<!--    <div class="agent-connect-container pb-50">-->
<!--      <div class="form-group pr-14">-->
<!--        <mat-form-field-->
<!--                style="height: 55px !important;"-->
<!--                class="mat-select-custom mh-50 d-flex"-->
<!--                appearance="fill"-->
<!--        >-->
<!--          <mat-label [translate]="'testcase.list.select_range_date'"></mat-label>-->
<!--          <mat-date-range-input [rangePicker]="createdDateRangePicker" [formGroup]="agentForm.get('dateRange')"-->
<!--                                [max]="maxDate">-->
<!--            <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>-->
<!--            <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>-->
<!--          </mat-date-range-input>-->
<!--          <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>-->
<!--          <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>-->

<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="form-group" style="margin-left: 10px">-->
<!--        <mat-form-field class="mat-select-custom d-flex" appearance="fill">-->
<!--          <mat-select (selectionChange)="constructQueryString()" formControlName="tags"-->
<!--                      [placeholder]="'message.common.label' | translate"-->
<!--                      panelClass="multiple" multiple [(value)]="filterTagIds">-->
<!--            <mat-option *ngFor="let tag of tags" [value]="tag.id" [textContent]="tag.name"></mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--      </div>-->
<!--      <div class="form-group">-->
<!--        <mat-form-field-->
<!--                class="mat-select-custom w-100 example-full-width"-->
<!--                appearance="fill"-->
<!--        >-->
<!--          <mat-select-->
<!--                  formControlName="testCaseTypes"-->
<!--                  (selectionChange)="constructQueryString()"-->
<!--                  disableOptionCentering-->
<!--                  [placeholder]="'message.common.any' | translate">-->
<!--            <mat-option-->
<!--                    *ngFor="let type of testCaseTypesList?.content"-->
<!--                    [value]="type.id"-->
<!--                    [textContent]="type.displayName"></mat-option>-->
<!--          </mat-select>-->
<!--        </mat-form-field>-->
<!--        <label class="control-label" [translate]="'testcase.summary.test_case_type'"></label>-->
<!--      </div>-->
<!--      <button type="submit" class="btn-submit">Submit</button>-->
<!--    </div>-->
<!--  </div>-->
<!--  <div>-->
<!--    <div style="display: block">-->
<!--      <canvas-->
<!--              baseChart-->
<!--              [type]="'pie'"-->
<!--              [datasets]="pieChartDataset"-->
<!--              [labels]="pieChartLabels"-->
<!--              [options]="pieChartOptions"-->
<!--              [plugins]="pieChartPlugins"-->
<!--              [legend]="pieChartLegend"-->
<!--      ></canvas>-->
<!--    </div>-->
<!--  </div>-->
<!--</form>-->


  <form
          class="ts-form"
          id="agentFormContainer"
          (keydown.enter)="false"
          novalidate="novalidate"
          [formGroup]="agentForm"
          (ngSubmit)="onSubmit()"
          name="agentForm"
          style="max-width: 600px; margin: 0 auto; margin-top: 10px"
  >
    <div class="form-row">
      <div class="form-group">

        <mat-form-field class="mat-select-custom" appearance="fill">
          <mat-label style="margin-bottom: 5px; padding-bottom: 10px"
                     [translate]="'testcase.list.select_range_date'"></mat-label>
          <mat-date-range-input [rangePicker]="createdDateRangePicker"
                                [formGroup]="agentForm.get('dateRange')"
                                [max]="maxDate">
            <input matStartDate formControlName="start" placeholder="Start date" [max]="maxDate" required>
            <input matEndDate formControlName="end" placeholder="End date" [max]="maxDate" required>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="createdDateRangePicker"></mat-datepicker-toggle>
          <mat-date-range-picker #createdDateRangePicker></mat-date-range-picker>

        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="mat-select-custom" appearance="fill">
          <mat-select  style="height: 32px" (selectionChange)="constructQueryString()" formControlName="tags"
                       [placeholder]="'message.common.label' | translate"
                       [(value)]="filterTagIds">
            <mat-option *ngFor="let tag of tags" [value]="tag.id" [textContent]="tag.name"></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field class="mat-select-custom" appearance="fill">
          <mat-select
                  style="height: 32px"
                  formControlName="testCaseTypes"
                  (selectionChange)="constructQueryString()"
                  [placeholder]="'message.common.type' | translate">
            <mat-option
                    *ngFor="let type of testCaseTypesList?.content"
                    [value]="type.id"
                    [textContent]="type.displayName"></mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="form-group">
        <button style="margin-top: 12px;
    width: 92px;" type="submit" class="btn-submit">Submit</button>
      </div>
    </div>
    <div class="form-row" style="display: flex; justify-content: space-between; ">
      <div class="form-group" style="flex: 1; margin-right: 10px; margin-left: -170px">
        <h3>Health</h3>
        <canvas
                baseChart
                [type]="'pie'"
                [datasets]="pieChartDataset"
                [labels]="pieChartLabels"
                [options]="pieChartOptions"
                [plugins]="pieChartPlugins"
                [legend]="pieChartLegend"
        ></canvas>
      </div>
      <div class="form-group" style="flex: 1; margin-left: 60px">
        <h3>Trend</h3>
        <canvas
                style="width: 450px; height: 300px"
                baseChart
                [data]="barChartData"
                [options]="barChartOptions"
                [plugins]="barChartPlugins"
                [legend]="barChartLegend"
                [type]="'bar'"
        ></canvas>
      </div>
    </div>
  </form>




