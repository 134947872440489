import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
// import { WorkspaceVersion } from "../../../models/workspace-version.model";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";

import { TranslateService } from "@ngx-translate/core";
import { NotificationsService } from "angular2-notifications";
import { RestAuthorization } from "app/enums/rest-authorization.enum";
import { RestCompareType } from "app/enums/rest-compare-type.enum";
import { RestMethod } from "app/enums/rest-method.enum";
import { TestStepType } from "app/enums/test-step-type.enum";
import { RestStepAuthorizationValue } from "app/models/rest-step-authorization-value.model";
import { RestStepEntity } from "app/models/rest-step-entity.model";
import { TestStep } from "app/models/test-step.model";
import { TestStepService } from "app/services/test-step.service";
import { BaseComponent } from "app/shared/components/base.component";
import { AuthenticationGuard } from "app/shared/guards/authentication.guard";
import { ToastrService } from "ngx-toastr";

// import { TestCase } from "../../../models/test-case.model";

interface Event {
  title: string;
  method: RestMethod;
  url: string;
  payload: string;
  followRedirects: boolean;
  authorizationType: RestAuthorization;
  authorizationValue: {
    username: string;
    password: string;
    token: string;
  };
  requestHeaders: Record<string, string>;
  variableName: string;
}

@Component({
  selector: "app-action-rest-api-form",
  templateUrl: "./action-rest-api-form.component.html",
  styleUrls: ["./action-rest-api-form.component.scss"],
})
export class ActionRestApiFormComponent
  extends BaseComponent
  implements OnInit {
  @HostBinding("class.theme-overlay-container")
  get isOverlay() {
    return true;
  }
  get testStep(): TestStep {
    return this.option.testStep;
  }
  readonly formGroup: FormGroup = this.fb.group({
    title: ["API", Validators.required],
    request: this.fb.group({}),
    // verify: this.fb.group({}),
    store: this.fb.group({
      variableName: ['',Validators.required]
    }),
    validation: this.fb.array([]),
  });
  get requestForm() {
    return this.formGroup.get("request") as FormGroup;
  }
  // get verifyForm() {
  //   return this.formGroup.get("verify") as FormGroup;
  // }
  get storeForm() {
    return this.formGroup.get("store") as FormGroup;
  }
  validationForm() {
    return this.formGroup.get("validation") as FormArray;
  }

  indentation: number = 0;
  // @Output('onCancel') onCancel = new EventEmitter<void>();
  // @Output('onSave') onSave = new EventEmitter<TestStep>();
  // @Output('onSubmitted') onSubmitted = new EventEmitter<void>();

  public isJSONResponse: boolean = false;
  public response: any = null;
  public restStep: RestStepEntity;
  public formSubmitted: boolean = false;
  public saving: Boolean = false;
  public validationData = {
    body: undefined
  };
  public validationData1: any;
  public isJsonPathValid:boolean = false

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<ActionRestApiFormComponent>,
    private matModal: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public option: {
      // version: WorkspaceVersion;
      testStep: TestStep;
      // versionId: number;
    },
    public authGuard: AuthenticationGuard,
    public notificationsService: NotificationsService,
    public translate: TranslateService,
    public toastrService: ToastrService,
    private testStepService: TestStepService
  ) {
    super(authGuard, notificationsService, translate, toastrService);

  }

  jsonPathValid(isjsonPathValid){
    this.isJsonPathValid = isjsonPathValid
  }

  handleData(bodyData: any) {
    for (let [key, val] of Object.entries(bodyData)) {
      this.validationData[key] = val
    }

    bodyData?.body?.forEach((val) => {
      this.validationForm().push(this.fb.group(val));
    })
  }

  handleData1(statusData: any) {
    // this.validationData["status"] = { ...statusData }
    for (let [key, val] of Object.entries(statusData)) {
      this.validationData[key] = val
    }
    // this.validationData1.status = statusData;
    statusData.status.forEach((val) => {
      this.validationForm().push(this.fb.group(val));
    })
  }

  onSaveHandler() {
    // console.warn(this.formGroup.invalid, this.formGroup.errors);
    if (this.formGroup.get('validation').valid) {
    } else {
    }
    // if (this.formGroup.invalid) {
    //   return;
    // }


    if (this.formGroup.controls.validation.valid) {
      this.convertValue(this.validationData?.body)
      let result = { ...this.formGroup.value, validationFinal: this.validationData }
      this.dialogRef.close(result);
    }
  }

  onCancelHandler() {
    // console.warn("Cancelled");
    this.dialogRef.close();
  }

  ngOnInit(): void {
    if (this.requestForm.getRawValue().authorizationType == null) {
      Object.keys(this.requestForm.controls).forEach((control) => {
        if (control != "action") {
          this.requestForm.removeControl(control);
        }
      });
    }
    this.requestForm.addControl(
      "action",
      new FormControl(this.testStep.action, [Validators.required])
    );
    this.createStep(this.testStep.eventMap);
    if (this.testStep.eventMap) {
      this.storeForm.patchValue({ variableName: this.testStep.eventMap.get('variableName') || '' });
    }
    // if (this.testStep.id) {
    //   this.requestForm.patchValue({ status: this.restStep.status });
    // }
  }

  createStep(event?: Map<string, unknown>) {
    if (event) {
      this.formGroup.patchValue({ title: event?.get('title') || 'API' });
    }
    this.restStep = new RestStepEntity();
    this.restStep.followRedirects = event?.get('followRedirects') as boolean ?? true;
    this.restStep.method = event?.get('method') as RestMethod ?? RestMethod.GET;
    this.restStep.url = event?.get('url') as string ?? "";
    this.restStep.payload = (event?.get('payload') ?? "") as unknown as JSON;
    this.restStep.authorizationType =
      event?.get('authorizationType') as RestAuthorization ?? RestAuthorization.NONE;
    const authValue = new RestStepAuthorizationValue();
    const auth = event?.get('authorizationValue') as {
      username: string,
      password: string,
      token: string
    };
    authValue.username = auth?.username ?? "";
    authValue.password = auth?.password ?? "";
    authValue.token = auth?.token ?? "";
    this.restStep.authorizationValue = authValue;
    this.restStep.responseCompareType = RestCompareType.STRICT;
    this.restStep.storeMetadata = true;
    console.info(event.entries());
    this.restStep.variableName = event?.get('variableName') as string ?? '';
    if (event) {
      this.restStep.requestHeaders = event.get('requestHeaders') as unknown as JSON;
      // this.restStep.headerRuntimeData = event.storeData.
    }
    this.testStep.type = TestStepType.ACTION_TEXT;
    this.testStep.restStep = this.restStep;
    this.restStep.jsonPath = event?.get('validation') as string ?? '';
  }

  setRequestValue() {
    this.testStep.action = this.requestForm.getRawValue()["action"];
    this.testStep.actionName = 'restApi';
    // console.warn(this.requestForm.value);
    if (
      this.requestForm.invalid ||
      !this.testStep?.action?.length ||
      (!this.requestForm.controls["status"] && !this.restStep.status)
    ) {
      this.formSubmitted = true;
      // if (this.requestForm.controls["url"].invalid) {
      //   this.activeTab = "Request";
      // } else if (
      //   !this.requestForm.controls["status"] ||
      //   this.requestForm.controls["status"].invalid
      // ) {
      //   this.activeTab = "Verify";
      // }
      return false;
    }
    let bodyRuntimeData, headerRuntimeData, storeMetaData, responseHeaders;
    if (!this.requestForm.controls.storeMetadata) {
      bodyRuntimeData = this.testStep.restStep.bodyRuntimeData;
      headerRuntimeData = this.testStep.restStep.headerRuntimeData;
      storeMetaData = this.testStep.restStep.storeMetadata;
    }
    if (!this.requestForm.controls.responseHeaders) {
      responseHeaders = this.testStep.restStep.responseHeaders;
    }
    this.testStep.restStep = new RestStepEntity().deserializeRawValue(
      this.requestForm.getRawValue()
    );
    if (this.testStep.restStep.authorizationType == RestAuthorization.BASIC) {
      delete this.testStep.restStep.authorizationValue.token;
    } else if (
      this.testStep.restStep.authorizationType == RestAuthorization.BEARER
    ) {
      delete this.testStep.restStep.authorizationValue.password;
      delete this.testStep.restStep.authorizationValue.username;
    } else {
      delete this.testStep.restStep.authorizationValue;
    }
    this.testStep.deserializeCommonProperties(this.requestForm.getRawValue());
    if (!this.requestForm.controls.storeMetadata) {
      this.testStep.restStep.bodyRuntimeData = bodyRuntimeData;
      this.testStep.restStep.headerRuntimeData = headerRuntimeData;
      this.testStep.restStep.storeMetadata = storeMetaData;
    }
    if (!this.requestForm.controls.responseHeaders) {
      this.testStep.restStep.responseHeaders = responseHeaders;
    }
    return true;
  }

  convertValue(bodyData) {
    if(bodyData){
      bodyData.map((val) => {
        if(!val.expectedValue){
          return;
        }
        try {
          const correctedInput = val.expectedValue.replace(/'/g, '"');
          const parsedValue = JSON.parse(correctedInput);
          if (Array.isArray(parsedValue)) {
            val.expectedValue = parsedValue;
          } else if (typeof parsedValue === 'object' && parsedValue !== null) {
            val.expectedValue = parsedValue;
          } else if (parsedValue === true || parsedValue === false) {
            val.expectedValue = parsedValue;
          } else if (!isNaN(parsedValue)) {
            val.expectedValue = parseFloat(parsedValue);
          } else {
            // Handle the case when parsing fails
            val.expectedValue = parsedValue;
          }
        }catch (error) {
          val.expectedValue;
        }
      })
    }
  }


  save() {
    if (!this.setRequestValue()) {
      if (!this.testStep?.action?.length)
        // this.onSubmitted.emit()
        return false;
    }
    this.saving = true;
    this.testStepService.create(this.testStep).subscribe(
      (step) => {
        step.parentStep = this.testStep.parentStep;
        step.siblingStep = this.testStep.siblingStep;
        step.stepDisplayNumber = this.testStep.stepDisplayNumber;
        // this.onSave.emit(step);
        this.saving = false;
      },
      (error) => {
        this.translate
          .get("message.common.created.failure", { FieldName: "Test Step" })
          .subscribe((res) => {
            this.showAPIError(error, res);
            this.saving = false;
          });
      }
    );
  }

  update() {
    if (!this.setRequestValue()) {
      if (!this.testStep?.action?.length)
        // this.onSubmitted.emit()
        return false;
    }
    this.saving = true;
    this.testStepService.update(this.testStep).subscribe(
      (step) => {
        step.parentStep = this.testStep.parentStep;
        step.siblingStep = this.testStep.siblingStep;
        step.stepDisplayNumber = this.testStep.stepDisplayNumber;
        // this.onSave.emit(step);
        this.saving = false;
      },
      (error) => {
        this.translate
          .get("message.common.update.failure", { FieldName: "Test Step" })
          .subscribe((res) => {
            this.showAPIError(error, res);
            this.saving = false;
          });
      }
    );
  }

  cancel() {
    // this.onCancel.emit();
  }

  setResponse(details: any) {
    this.response = details.response;
    this.isJSONResponse = details.isJSONResponse;
  }
}
