import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotificationType, NotificationsService } from 'angular2-notifications';
import { Environment } from 'app/models/environment.model';
import { TestCaseService } from 'app/services/test-case.service';
import { BaseComponent } from 'app/shared/components/base.component';
import { AuthenticationGuard } from 'app/shared/guards/authentication.guard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-steps-variables',
  templateUrl: './steps-variables.component.html',
  styleUrls: ['./steps-variables.component.scss']
})
export class StepsVariablesComponent extends BaseComponent implements OnInit {
  environment: Environment;
  // @Input('formGroup') formGroup : FormGroup;
  @Input('edit') edit: Boolean = true;
  @Input('formSubmitted') formSubmitted;
  public testCase: any;
  public selectAll: Boolean;
  public selectedList = [];
  public formGroup: FormGroup = new FormGroup({
    parameters: new FormControl('', [])
  });
  constructor(
    private formBuilder: FormBuilder,
    private testCaseService: TestCaseService,
    public translate: TranslateService,
    public notificationsService: NotificationsService,
    public toastrService:ToastrService,
    public authGuard: AuthenticationGuard,
    private dialogRef: MatDialogRef<StepsVariablesComponent>,
  ) { 
    super(authGuard, notificationsService, translate,toastrService)
  }

  ngOnInit(): void {
    this.formGroup.removeControl('parameters');
    this.formGroup.addControl('parameters', this.formBuilder.array([]));
    this.environment = new Environment();
    this.environment.parameters = JSON.parse('{"":""}');
    this.populateControls();
  }

  populateControls() {
    Object.keys(this.environment.parameters).forEach((key: string) => {
      if (key?.length || this.environment.parameters[key].length)
        this.rowControls().push(this.formBuilder.group({
          key: key,
          value: this.environment.parameters[key],
          selected: false,
        }));
    });
    if (this.testCase?.testDataFunctionArgs) {
      this.addRowControls(this.testCase.testDataFunctionArgs);
    }
    this.addRowControl();
  }

  addRowControl() {
    this.rowControls().push(this.rowControl());
  }

  rowControl(): FormGroup {
    return this.formBuilder.group({
      key: '',
      value: '',
      selected: false
    })
  }

  removeRowControl(index: number): void {
    this.rowControls().removeAt(index);
  }

  // Function to reverse the object into an array of objects
  reverseObjectToArray(object: any): any[] {
    const reversedArray = [];
    for (const key in object) {
        reversedArray.push({
          key : object.key,
          value: object.value,
          selected: false
        });
    }
    return reversedArray;
  }

  // Function to add row controls to the form array
  addRowControls(object: any): void {
    // const reversedArray = this.reverseObjectToArray(object);
    const controlArray = this.formGroup.get('parameters') as FormArray;
    object.forEach(item => {
      controlArray.push(this.createRowControl(item));
    });
  }

  // Helper function to create a single row control
  createRowControl(data: any): FormGroup {
    return this.formBuilder.group({
      key: data.key,
      value: data.value,
      selected: data.selected
    });
  }

  rowControls(): FormArray {
    return this.formGroup.get('parameters') as FormArray;
  }

  andEmptyRowIfMissing() {
    let empty = this.formGroup.value['parameters'].find(header => header.key == '' && header.value == '');
    if (!empty)
      this.addRowControl();
  }

  public addOrRemoveFromSelectList() {
    this.rowControls().controls.forEach((parameter, index) => {
      if (parameter.value.selected && !this.selectedList.includes(index))
        this.selectedList.push(index);
      else if (!parameter.value.selected && this.selectedList.includes(index))
        this.selectedList.splice(index, 1);
    });
    this.selectAll = this.selectedList.length == this.rowControls().controls.length;
  }

  public removeMultipleParameters(): void {
    let selectedRows = this.selectedList.reverse();
    for (let i of selectedRows) this.removeRowControl(i);
    if (this.rowControls().length == 0) {
      this.addRowControl();
    }
    this.selectAll = false;
    this.selectedList = [];
  }

  public selectAllToggle(selectAll: Boolean): void {
    this.selectedList = []
    this.rowControls().controls.forEach((control, index) => {
      control.value.selected = selectAll;
      if (selectAll) {
        this.selectedList.push(index);
      }
    });
  }

  isDuplicateParameters(key: String) {
    if (!this.formSubmitted)
      return false;
    let count = 0;
    this.formGroup.value['parameters'].find(parameter => {
      if (parameter.key.trim() == key.trim()) {
        count++;
      }
    })
    return count > 1;
  }

  isEmptyValue(parametersControl) {
    return parametersControl.value?.value?.trim().length == 0 && this.formSubmitted;
  }

  hasDuplicateParameters(): boolean {
    let count = 0;
    let parameters = this.formGroup.getRawValue().parameters;
    parameters.forEach(parameter => {
      if (parameter.key.trim().length || parameter.value.trim().length) {
        parameters.find(parameterItem => {
          if (parameterItem.key.trim() == parameter.key.trim()) {
            count++;
          }
        });
      }
    });
    return count > parameters.length;
  }

  hasEmptyParameters(): boolean {
    let empty = false;
    this.formGroup.getRawValue().parameters.forEach(parameter => {
      if (parameter.value.trim().length && !parameter.key.trim().length) {
        empty = true;
      }
    });
    return empty
  }

  hasEmptyParameterValues(): boolean {
    let empty = false;
    this.formGroup.getRawValue().parameters.forEach(parameter => {
      if (parameter.key.trim().length && !parameter.value.trim().length) {
        empty = true;
      }
    });
    return empty
  }

  hasEmptyParameterNames(): boolean {
    let empty = false;
    let parameters = this.formGroup.getRawValue().parameters;
    parameters.forEach((parameter, index) => {
      if (!parameter.key.trim().length && index < parameters.length - 1) {
        empty = true;
      }
    });
    return empty
  }


  returnIfInvalid(): Boolean {
    this.formSubmitted = true;
    return (this.hasDuplicateParameters() || this.hasEmptyParameterValues() || this.hasEmptyParameterNames());
  }



  /** Create And Updat the Variables */
  createAndUpdateVariables() {
    if (this.returnIfInvalid()) return;
    const result = [];
    this.formGroup.value.parameters.forEach(item => {
      if (item.key && item.value) {
        result.push({
          key : item.key,
          value : item.value
        });
      }
    });
    this.testCase.testDataFunctionArgs = result;
    this.testCaseService.update(this.testCase).subscribe(
      (testcase) => {
          this.showNotification(NotificationType.Success, 'Test Case Steps Variables Updated successfully');
          // this.testCaseService.refeshTestCaseAfterSaveOrUpdate.next(true);
          this.dialogRef.close();
      },
      error => {
        this.translate.get('message.common.update.failure', {FieldName: 'Test Case'}).subscribe((res) => {
          this.showAPIError(error, res, 'Test Case')
        })
      });
  }

}
