<div class="d-flex flex-wrap">
  <div class="d-flex justify-content-between ts-col-100">
    <app-test-case-by-status-summary class="ts-col-auto pl-20" [version]="version"></app-test-case-by-status-summary>
    <app-test-case-by-type-summary class="ts-col-auto  pl-20 dashboard-section-lg"
      [version]="version"></app-test-case-by-type-summary>
  </div>
  <!--  <div class="d-flex justify-content-between ts-col-100">-->
  <!--    <div style="height: 270px; overflow-y: auto; margin-top: 20px; width: 100%">-->
  <!--      <div class="dashboard-section-title" [translate]="'dashboard.test_run_by_feature.title'"></div>-->
  <!--      <table class="table table-striped custom-table">-->
  <!--        <thead class="thead-dark">-->
  <!--        <tr>-->
  <!--          <th scope="col">Tag Name</th>-->
  <!--          <th scope="col">Success</th>-->
  <!--          <th scope="col">Failure</th>-->
  <!--          <th scope="col">Not Executed</th>-->
  <!--          <th scope="col">Total</th>-->
  <!--        </tr>-->
  <!--        </thead>-->
  <!--        <tbody>-->
  <!--        <tr *ngFor="let feature of featureResult; index as i">-->
  <!--          <td>{{ feature?.tagName }}</td>-->
  <!--          <td>{{ feature?.successCount }}</td>-->
  <!--          <td>{{ feature?.failureCount }}</td>-->
  <!--          <td>{{ feature?.notExecutedCount }}</td>-->
  <!--          <td>{{ feature?.totalCount }}</td>-->
  <!--        </tr>-->
  <!--        </tbody>-->
  <!--      </table>-->
  <!--    </div>-->
  <!--  </div>-->

</div>