<div>
    <div class="manage-plan-title"><span class="dashboard-link" [routerLink]="['/dashboard']">Dashboard >> </span><span class="active">Manage Plan</span></div>
    <div class="page-content">
        <h2>YOUR CURRENT PLAN : {{currentPlanType}}</h2> <button class="update-plan-btn" (click)="OnclickUpdate_Plan()">Update Plan</button><br><br> 
        <!-- <button class="update-plan-btn" (click)="OnclickUpdate_Plan()">Update Plan</button> <br><br> -->
        <div class="table-responsive">
            <table class="table mb-5">
              <thead>
                <tr>
                  <th scope="col">Title</th>
                  <th class="tr-center" scope="col">TOTAL (Second)</th>
                  <th class="tr-center" scope="col">USED (Second)</th>
                  <th class="tr-center" scope="col">REMAINING (Second)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                    <th> Accessibility Execution Time </th>
                    <td> {{dataValue.totalAccessibilityExecutionTime}} </td>
                    <td> {{dataValue.usedAccessibilityExecutionTime}}</td>
                    <td> {{dataValue.remainingAccessibilityExecutionTime}} </td>
                </tr>
                <tr>
                    <th> API Execution Time </th>
                    <td> {{dataValue.totalApiExecutionTime}} </td>
                    <td> {{dataValue.usedApiExecutionTime}}</td>
                    <td> {{dataValue.remainingApiExecutionTime}} </td>
                </tr>
                <tr>
                    <th> Code AI Step </th>
                    <td> {{dataValue.totalCodeAiStep}} </td>
                    <td> {{dataValue.usedCodeAiStep}}</td>
                    <td> {{dataValue.remainingCodeAiStep}} </td>
                </tr>
                <tr>
                    <th> Code Generate Step </th>
                    <td> {{dataValue.totalCodeGenerateStep}} </td>
                    <td> {{dataValue.usedCodeGenerateStep}}</td>
                    <td> {{dataValue.remainingCodeGenerateStep}} </td>
                </tr>
                <tr>
                    <th> Cross Browser Testing Time </th>
                    <td> {{dataValue.totalCrossBrowserTestingTime}} </td>
                    <td> {{dataValue.usedCrossBrowserTestingTime}}</td>
                    <td> {{dataValue.remainingCrossBrowserTestingTime}} </td>
                </tr>
                <tr>
                    <th> Cross Device Testing Time </th>
                    <td> {{dataValue.totalCrossDeviceTestingTime}} </td>
                    <td> {{dataValue.usedCrossDeviceTestingTime}}</td>
                    <td> {{dataValue.remainingCrossDeviceTestingTime}} </td>
                </tr>
                <tr>
                    <th> Run Execution Time </th>
                    <td> {{dataValue.totalRunExecutionTime}} </td>
                    <td> {{dataValue.usedRunExecutionTime}}</td>
                    <td> {{dataValue.remainingRunExecutionTime}} </td>
                </tr>
                <tr>
                    <th> Web Performance Execution Time </th>
                    <td> {{dataValue.totalWebPerformanceExecutionTime}} </td>
                    <td> {{dataValue.usedWebPerformanceExecutionTime}}</td>
                    <td> {{dataValue.remainingWebPerformanceExecutionTime}} </td>
                </tr>
              </tbody>
            </table>
        </div>
    </div>
</div>