import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {PlatformService} from "../../agents/services/platform.service";
import {PlatformScreenResolution} from "../../agents/models/platform-screen-resolution.model";
import {TestPlanPlatformOsVersionFormComponent} from "./test-plan-platform-os-version-form.component";
import {AgentService} from "../../agents/services/agent.service";
import { Pageable } from 'app/shared/models/pageable';
import { NormalBrowser } from 'app/agents/models/normal-browser.model';
import {NormalDevice} from 'app/agents/models/normal-device.model';
@Component({
  selector: 'app-test-plan-test-machine-form',
  templateUrl: './test-plan-test-machine-form.component.html',
  styles: []
})
export class TestPlanTestMachineFormComponent extends TestPlanPlatformOsVersionFormComponent implements OnInit {
  public screenResolutions: PlatformScreenResolution[];
  public screenResolution: PlatformScreenResolution;
  public normalBrowsers: NormalBrowser[]=[];
  public normalDevice: NormalDevice[]=[];
  public staticPlatforms =[{name:'Browser', id:1, isLinux:true},{name:'Devices', id:2, isLinux:true}]
  @Input('isAvailableCheck') isAvailableCheck: Boolean;
  @Output() setAgentOnline = new EventEmitter<Boolean>();
  isBrowser = false;
  isDevice = false;

  constructor(
    public platformService: PlatformService,
    public agentService: AgentService) {
    super(platformService, agentService);
    this.fetchBrowser();
    this.fetchDevice();
  }

  ngOnInit(): void {
    if(this.environmentFormGroup.value.platform == 'Browser'){
      this.isBrowser = true
      this.isDevice = false

    } else if(this.environmentFormGroup.value.platform == 'Devices'){
      this.isDevice = true
      this.isBrowser = false
    }else{
      this.isBrowser == false
      this.isDevice == false
    }
  }

  ngOnChanges(changes:SimpleChanges) {
    super.ngOnChanges(changes);
    this.screenResolution = null;
    if (this.version && !this.isHybrid) {
      this.environmentFormGroup.patchValue({agentId: undefined});
      this.fetchPlatForms();
    }
    if(this.isHybrid)
      this.setTargetMachines();
  }

  postOsVersionFetch(setValue?: Boolean) {
    super.postOsVersionFetch();
    this.fetchResolutions(setValue);
  }

  fetchResolutions(setValue?: Boolean) {
    this.platformService.findAllScreenResolutions(this.platform, this.platformOsVersion, this.version.workspace.workspaceType, this.testPlanLabType).subscribe(res => {
      this.screenResolutions = res;
      if( this.environmentFormGroup?.controls['platformScreenResolutionId'].value) {
        this.screenResolution = this.screenResolutions.find(res => res.id == this.environmentFormGroup?.controls['platformScreenResolutionId'].value);
      }
      if (setValue || !this.screenResolution) {
        if (this.screenResolutions.length > 0) {
          this.screenResolution = this.screenResolutions[0];
          this.environmentFormGroup?.controls['platformScreenResolutionId'].setValue(this.screenResolution.id);
        } else {
          this.screenResolution = null;
          this.environmentFormGroup?.controls['platformScreenResolutionId'].setValue(null);
        }
      }
      this.setResolution(this.screenResolution);
    })
  }

  setResolution(screenResolution){
    if (screenResolution)
    this.environmentFormGroup?.controls['resolution'].setValue(screenResolution.resolution);
  }

  

  setAgentStatus(isAgentOnline: boolean){
    this.setAgentOnline.emit(isAgentOnline);
  }


  fetchBrowser() {
    let pageable = new Pageable();
    pageable.pageSize = 1;
    this.agentService.browserfindAll(null, null).subscribe(res =>{
         this.normalBrowsers = res.content;
    });
  }

  fetchDevice() {
    let pageable = new Pageable();
    pageable.pageSize = 1;
    this.agentService.deviceFindAll(null, null).subscribe(res =>{
         this.normalDevice = res.content;
    });
  }

  setPlatform(platformId) {
    // this.platform = this.platforms.find(platform => platform.id == platformId);
    // this.fetchOsVersions(true);
    if(platformId == 'Browser'){
      this.isBrowser = true
      this.isDevice = false

    } else if(platformId = 'Devices'){
      this.isDevice = true
      this.isBrowser = false
    }else{
      this.isBrowser == false
      this.isDevice == false
    }
  }
}
