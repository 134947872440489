<mat-drawer-container class="example-container">
  <mat-drawer mode="Over" position="end" [opened]="drawerOpen" >
    <app-test-case-result-details *ngIf="drawerOpen" [selectedResultId]="selectedResultId" [is_Model]="true" (statusChange)="closeDrawer($event)" [selectedCaseId]="selectedCaseId">

    </app-test-case-result-details>
  </mat-drawer>
  <mat-drawer-content>
    <div>
    <div class="d-flex" style="height:100vh">
      <div [class.ts-col-100]="!(showRunDetails)" [class.ts-col-75]="showRunDetails">
        <div class="test-run-details-page-container">
          <div class="ts-col-100 d-flex-wrap">
            <app-placeholder-loader class="ts-col-100" *ngIf="!isExecutionFetchingCompleted"
              [isDetails]="true"></app-placeholder-loader>

            <div class="theme-details-header mb-6 text-break">
              <app-td-overlay-menu-button
                [versionId]="testPlanResult?.testPlan?.workspaceVersionId"></app-td-overlay-menu-button>
              <!-- <a class="go-back-icon text-decoration-none ml-10"
             *ngIf="testPlanResult"
             [matTooltip]="'hint.message.common.back' | translate"
             [routerLink]="['/td', testPlanResult?.testPlan?.workspaceVersionId, 'results']"></a> -->
              <a class="title-name text-decoration-none ml-15"
                [routerLink]="['/td', 'plans', testPlanResult?.testPlan?.id]"
                [matTooltip]="testPlanResult?.testPlan?.name" [textContent]="testPlanResult?.testPlan?.name"></a>
              <!-- <button class="btn btn-primary ml-10" (click)="drawerOpenClose('4')">Add Test Case</button> -->
            </div>
          </div>
          <div class="ml-auto d-flex align-items-center">
            <div>
              <i class="fa-info section-action-icons pointer" [class.active]="showRunDetails"
                (click)="togglerunDetails()" [matTooltip]="'hint.message.common.details' | translate"></i>
            </div>
          </div>
        </div>
        <div class="theme-border-t d-flex theme-test-run-details-scroll-content" style="    overflow-y: auto;">
          <div class="ts-col-100 pl-20">


            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
              <!-- This is the tree node template for leaf nodes -->
              <mat-tree-node *matTreeNodeDef="let node" (click)="addeddata(node)" matTreeNodePadding style="padding-left: 0px !important">
               
                <div *ngIf="node.name" style="overflow: auto;     width: 100%;">
                  <div class="list-header flex-wrap" *ngIf="node.index == 0" (click)="addeddata(node)">
                    <div class="ts-col-8">
                      <input class="pr-10" type="checkbox">
                    </div>
                    <div class="ts-col-5 px-20">
                    </div>
                    <div class="ts-col-8 px-20">
                      ID
                    </div>
                    
                    <div class="ts-col-45 px-20">
                      TITAL
                    </div>
                    <div class="ts-col-20 px-20">
                      STATUS
                    </div>
                    
                    <div class="ts-col-15 px-20">
                      DURATION
                    </div>
                  </div>
                  <div class="list-container flex-wrap ">
                    <div class="list-view green-highlight p-12  pointer align-items-center" (click)="drawerOpenClose(node)">
                      <div class="ts-col-8">
                        <input class="pr-10" type="checkbox">
                      </div>
                      <div class="ts-col-5 px-20">
                      </div>
                      <div class="ts-col-8 px-20">
                        {{node.testCaseId}}
                      </div>
                      <div class="ts-col-45 px-20">
                        {{node.name}}
                      </div>
                      <div class="ts-col-20 px-20">
                        <button class="result_button py-7 pl-14 pr-14 fz-13 ng-star-inserted mr-4"
                          [ngClass]="{
                            'success': node.result === 'SUCCESS',
                            'failure': node.result === 'FAILURE',
                            'not-executed': node.result === 'NOT_EXECUTED'
                          }"
                          tabindex="0">
                          <span>
                            {{ node.result === 'SUCCESS' ? 'Passed' : node.result === 'FAILURE' ? 'Failed' : 'Not Executed' }}
                          </span>
                        </button>
                      </div>
                      
                      <div class="ts-col-15 px-20">
                        {{durationAsString(node.duration) }}
                      </div>
                    </div>

                  </div>
                </div>
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
                <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                  <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                  </mat-icon>
                </button>
                <input class="pr-10" type="checkbox">
                <h3 style="color: #4B5563;">{{node.name}}</h3>
              </mat-tree-node>
            </mat-tree>
          </div>
        </div>
      </div>
      <div class="ts-col-25">
        <app-run-details-quick-info [showList]="'TCR'"  [testPlanResult]="testPlanResult"
          (toggleDetailsAction)="togglerunDetails()">
        </app-run-details-quick-info>
      </div>
    </div>
  </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- Hotjar Tracking Code for https://contextqa.com -->
<script>
  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    h._hjSettings = { hjid: 3424302, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
</script>