
<div class="page-header theme-border-b">
  <div
    class="page-title">
    Terms and Conditions
  </div>
  <div class="short-hand-actions">
  </div>
</div>

<div class="page-content">
  <br/>
  <div class="d-flex h-75" style="border: 1px solid black;">
  <div class="ts-col-100 p-10" style="overflow-y: scroll">
    <br/>
    <h4>General</h4>
    This website ‘contextqa.com and ContextQA.io’ (here in after referred to as “Website” or “Website”), is owned by ContextQA Technologies Private Limited, operating in the name and style of ContextQA. Please read these Terms of Service carefully before accessing or using our website. You can review the most current version of the Terms of Service at any time on this page.<br/><br/>
    ContextQA offers this website, including all information, tools and services available from this Website to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. By accessing or using any part of the Website, you agree to be bound by this Agreement, Privacy Policy as well as rules, guidelines, policies, terms, and conditions applicable to any service / module that is provided by or through this Website, including the Terms and Policies on the Website relating to standalone services that shall be deemed to be incorporated into this Terms of Service and shall be considered as part and parcel of this Terms of Service (collectively “Terms”). If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our website. It is solely your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.

    <h4>Terms</h4>
    For the purposes of these Terms, as amended, wherever the context so requires “You” or “User” shall mean any natural or legal person who has agreed to become a user of the services provided by ContextQA, or who browses or accesses this Website.<br/><br/>
    “Agreement” shall mean and refer to this Terms, including any amendments that may be incorporated into it and other Policies available on our Website. <br/><br/>
    “Fees” shall mean consideration payable for Subscription. <br/><br/>
    “Intellectual Property Rights” shall mean all intellectual property rights and all analogous rights subsisting under the laws of each and every jurisdiction throughout the world and intellectual property of the rights concerned including all extensions and renewals of such rights, whether or not such rights are registered or capable of registration, including, without limitation, copyrights, trademarks, trade names, service marks, service names, patents, designs and all other proprietary rights of whatsoever description whether or not protected and whether or not capable of protection.<br/><br/>
    “Malicious Code” shall mean code, files, scripts, or programs intended to do harm, including, for example, viruses, worms, time bombs and Trojan horses. Additionally, any file, script, program, browser plug-in, browser helper or extension, or any robot or application designed to scrape and collect data or automate the entry of data shall also be deemed Malicious Code. <br/><br/>
    “Product / Application” shall mean and refer to the automation testing solutions, and any version of the software being made available through the Website and the portal www.ContextQA.io. <br/><br/>
    “Services” shall mean all services being made available by and through the Website, including without limitation, Subscription to the Product. <br/><br/>
    “Subscribe / Subscription” shall mean the access being granted to Use of the Application.<br/><br/>
    “Third Party” shall mean and refer to any natural or legal persons apart from the User and ContextQA. <br/><br/>
    “Use” shall mean directly or indirectly activating the processing capabilities of the Software, to load, execute access, employ the Software, or display information resulting from such capabilities, including but not limited to employing the proprietary information made available to the customer.<br/><br/>
    “We”, “Us”, “Our” shall mean ContextQA Technologies Private Limited.<br/><br/>

    <h4>Limited License</h4>
    Subject to Terms, the User is hereby granted a non-exclusive, non-transferable license to use the Application and other proprietary information. ContextQA (“Licensor”) may modify the Terms at any time. Your continued use of the ContextQA Product and/or data derived there from shall constitute your acceptance of such revised Terms. <br/><br/>
    The Licensor may at any time assign, transfer or novate all or any part of the Terms (including its rights and obligations) to any other person without the consent of the licensee.<br/><br/>
    The Licensee acknowledges the proprietary nature of the Application, its architecture, logic, data sources and processes and shall do everything within its control to protect its confidentiality.<br/><br/>
    Licensee shall not have the right to change, alter, copy, amend, reverse engineer, decompile, disassemble, publish, disclose, display or make available, in whole or in part, or otherwise use the Software in any manner whatsoever, except as specified herein.<br/><br/>
    ContextQA, its affiliates, and licensors shall retain all right, title, know-how, and other proprietary and intellectual property rights in the Application. The Licensee does not acquire any rights in the software, except as specified and granted in this Agreement, and shall not remove any proprietary, copyright, patent, trademark, design right, trade secret or any other proprietary rights legends from the software.
    Licensee shall not modify, resell, distribute, or create derivative works based on the software or any part thereof. <br/>

    <h4>Reservation of Rights</h4>
    No other rights are granted except as expressly set forth in the Terms. Subscription shall not amount to sale and does not convey any rights or ownership in, or to, the Services or any underlying software. We own all right, title, and interest, including all intellectual property rights, in and to the Services and the underlying software and any and all updates, upgrades, modifications, enhancements, content, improvements or derivative works thereof, and in any idea, know-how, and programs developed by Us or Our licensors during the course of performance of the Services.<br/>

    <h4>Restrictions on Use</h4>
    In the event you are barred from undertaking legally binding obligations under the Indian Contract Act, 1872, or are for any reason, unable to provide ‘consent’ as per the Information Technology Rules, 2011, you are not eligible to register for, use or avail the Services.<br/><br/>
    Without limiting any other provisions of these Terms, you may not use this Application for any purpose that is unlawful or prohibited by these Terms and/or any applicable additional terms. Your access of this Application may be terminated immediately, in our sole discretion, with or without notice, if you fail to comply with any provision of these Terms and/or additional terms, or for any other or no reason. You may access your account from only one platform at a time and up to three devices altogether.<br/>
    <h4>Registration</h4>
    During registration, You agree to (a) provide true, accurate, current and complete information about yourself as prompted by the registration form, (b) maintain and promptly update your data to keep it true, accurate, current and complete; and (c) comply with these Terms. If you provide any information that is untrue, inaccurate, not current or incomplete, or if we believe that such information is untrue, inaccurate, not current or incomplete, we reserve the right to suspend or terminate your account and refuse any and all current or future use of the App, or any portion thereof.<br/><br/>
    You retain sole responsibility for maintaining the confidentiality of your account, username and password and for all activities and liabilities associated with or occurring under your account. You must notify us immediately of any unauthorized use of your account, username or password and any other breach of security, and ensure that you exit from your account at the end of each session. We cannot and will not be responsible for any loss or damage arising from your failure to comply with this requirement or as a result of (mis)use of your account, username or password, by a third party, with your knowledge or otherwise. However, you could be held liable for losses incurred by us or another party due to someone else using your account, username or password.<br/><br/>
    Authorized Users. You may designate and authorize as many Users as permissible on the Subscription availed. You (i) are responsible for Your Authorized Users’ compliance with the Agreement, and (ii) shall use commercially reasonable efforts to prevent unauthorized access to or use of the Services and shall notify Us immediately of any such unauthorized access or use. It is Your responsibility to remove access to the Services if authorized status of a User or designated employee changes.<br/><br/>
    You shall not transfer your account to another person, and you may not use anyone else’s account, at any time without the permission of the account holder. <br/>

    <h4>Communications</h4>
    By using this Application, it is deemed that you have consented to receiving communications through, without limitation, telephonic calls, SMSs and/or emails. Such communications shall be sent to you on the telephone number and/or email id provided by you for the use of this Application / for the Subscription. Such communication may be for purposes that inter alia include clarification calls, marketing calls and promotional calls.<br/>

    <h4>User Content</h4>
    ContextQA allows you to post content in the form of alphanumeric data. Any content that you post / share or otherwise make available on or through the Application, including all content related to software codes is “User Generated Content”.  You retain all rights in, and are solely responsible for, any and all User Generated Content you post to ContextQA, subject to the terms set forth herein. It is hereby clarified that we play no role in User Generated Content.<br/><br/>
    You shall: (i) have sole responsibility for the accuracy, quality, integrity, legality, reliability, and appropriateness of all data and content that You submit for Your use of the Services; (ii) use commercially reasonable efforts to prevent unauthorized control or tampering or any other unauthorized access to, or use of, the Services and notify Us promptly of any unauthorized use or security breach; (iii) comply with all applicable local, state, central, and foreign laws (including laws regarding privacy and protection of personal or consumer information) in using the Services; (iv) to the extent applicable, comply with all applicable rules of credit card associations (including American Express, MasterCard and Visa); and (v) obtain and maintain all computer hardware, software and communications equipment needed to access the Services and pay all access charges (e.g., ISP fees) incurred by You in connection with Your use of the Services.<br/>
    <h4>Our use of User Generated Content</h4>
    You grant ContextQA, its affiliates and concerns, a non-exclusive, royalty-free, transferable, sub-licensable, worldwide license to store, display, reproduce, and distribute your User Generated Content on ContextQA for the purposes of operating, developing, providing ContextQA services and for its internal purposes. Nothing in these Terms shall restrict other legal rights ContextQA may have to User Generated Content under other licenses. We reserve the right to remove User Generated Content for any reason, including a violation or an apparent violation of these Terms, as we may solely determine. However, we do not retain the right to modify any User Generated Content, and shall not access the User Generated Content, in the event such access is not required for the purposes of the Services rendered.<br/><br/>
    Our Use of Anonymous Data. You agree that ContextQA may use the data generated by and stored on Our servers anonymously, for Our own internal business purposes, including but not limited to the development of anonymous marketing and sales collateral materials, statistical analysis of data, and publication solely in an aggregated form of operating data in industry benchmark reports. You shall at all times retain ownership of Your data.<br/><br/>
    Following termination or deactivation of your account, or your removal of any User Generated Content from ContextQA, we may, but shall not be bound to, retain such Content for a commercially reasonable period of time for backup, archival, or audit purposes. It is specified that ContextQA shall have the right to remove all User Generated Content following termination or deactivation of your account.<br/>
    <h4>Intellectual Property Rights</h4>
    All information, content, services and software displayed on, transmitted through, or used in connection with the Application, including for example text, photographs, images, illustrations, audio clips, video, html, source and object code, trademarks, logos, and the like (collectively and hereinafter referred to as the “Content”), as well as its selection and arrangement, is owned or licensed by Us. You may use the Content only through the Application, and solely for your personal, non-commercial use.<br/>
    You may not republish any portion of the Content on any internet, intranet or extranet website or incorporate the Content in any database, compilation, archive or cache. You may not distribute any Content to others, whether or not for payment or other consideration, and you may not modify, copy, frame, cache, reproduce, sell, publish, transmit, display or otherwise use any portion of the Content. You may not scrape or otherwise copy the Content without permission. You agree not to decompile, reverse engineer or disassemble any software or other products or processes accessible through the Application; not to insert any code or product or manipulate the content of the Application in any way that affects the user's experience, and not to use any data mining, data gathering or extraction method.<br/>

    <h4>Fees</h4>
    You shall pay all applicable Fees for the Services, in accordance with the billing frequency. Service Fees may be increased at the time of renewal of any term based upon Our then-current fees. All Service Fees are non-refundable even if You terminate Your subscription early.<br/>

    <h4>Refund and Cancellation</h4>
    It is hereby specified that we shall be under no obligation to refund, in whole, or in part, any subscription amount paid by the User to avail Services through ContextQA. It is specified that we retain all rights to alter the subscription amounts payable by the User, at all points in time. It is further specified that in the event of a change in subscription amounts payable by a User in the course of the subscription duration, we reserve all rights to direct the User to pay the amended subscription amount, failing which we shall have the right to suspend the subscription of the user till such time as complete payment has been made. It is clarified that this right shall be exercisable solely at the discretion of ContextQA.

    <h4>User’s Obligations / Representations and Warranties</h4>
    You are responsible for all activities that occur under Your use of the services and the use by Your authorised Users.<br/>
    The User undertakes to fulfil the following obligations, the failure to satisfy any of which gives us the right to permanently suspend your account and/or claim damages for any losses that accrue to us or additional costs that may be imposed on us or incurred by us.<br/>
    You may not, and You shall ensure Your authorized users do not,<br/>
    <ol>
      <li> Cut, copy, recreate, reverse engineer, or create derivative works from, transfer, or sell Content. </li>
      <li>Access (or attempt to access) the Application and/or the materials on any network by any means other than through the interface that is provided by the Application. The use of deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Application or Content, or in any way reproduce or circumvent the navigational structure or presentation of the Application, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Application is prohibited. </li>
      <li>use the Application in any manner that may impair, overburden, damage, disable or otherwise compromise ContextQA’s Services.</li>
      <li>use the modules, features, or information made available through ContextQA to further any unlawful purposes or to conduct any unlawful activity, including, but not limited to, fraud, embezzlement, money laundering or identity theft.</li>
      <li>use the modules, features, or information made available through ContextQA to abuse, harass, threaten, defame, disillusion, erode, abrogate, demean or otherwise violate the legal rights of others;</li>
      <li>engage in any activity that interferes with or disrupts access to the Application or the Services (or the servers and networks which are connected to the Application);</li>
      <li>probe, scan or test the vulnerability of the Application or any network connected to the Application, nor breach the security or authentication measures on the Application or any network connected to the Application. You may not reverse look-up, trace or seek to trace any information on any other user of the Application, or exploit the Application or Services or information made available or offered by or through the Application, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than your own information, as required;</li>
      <li>disrupt or interfere with the security of, or otherwise cause harm to, the Application, systems resources, servers or networks connected to or accessible through the Applications or any affiliated or linked applications;</li>
      <li>violate any applicable laws or regulations for the time being in force within or outside India;</li>
      <li>license, sublicense, sell, rent, assign, distribute, time share transfer, lease, loan, resell, distribute or otherwise commercially exploit, grant rights in or make the Services available to any third party; </li>
      <li>threaten the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order, or cause incitement to the commission of any cognizable offence or investigation of any offence or is insulting any other nation;</li>
      <li>late any code of conduct or other guidelines, which may be applicable for or to any particular Service; or</li>
      <li>use the Services to transmit infringing, libelous, obscene, threatening, Malicious Code, or otherwise unlawful, unsafe, abusive or tortious material, or to store or transmit material in violation of third-party privacy rights.</li>
    </ol>
    <h4>DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY</h4>
    YOU EXPRESSLY UNDERSTAND AND AGREE THAT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW:<br/><br/>
    We do not guarantee, represent or warrant that your use/access of Services will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the Application will be accurate or reliable. You agree that from time to time we may remove the Application for indefinite periods of time or cancel the Services at any time, without notice to you. You expressly agree that your use of, or inability to use, the Services is at your sole risk. <br/><br/>
    The Services, and the Product delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement. <br/><br/>
    In no event shall our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the Service or the Product, or for any other claim related in any way to your use of the Service or the Product, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of the Service or any Content or Product, posted, transmitted, or otherwise made available, even if We have been advised of such possibility. <br/><br/>
    You agree to indemnify, defend and hold harmless ContextQA and our parent company, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.<br/>

    <h4>Termination</h4>
    The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this Agreement for all purposes. The Terms are effective unless and until terminated by either you or us. You may terminate these Terms at any time by notifying us that you no longer wish to use our Services. If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).<br/>

    <h4>Confidential Information</h4>
    The Software may include confidential information that is secret and valuable to ContextQA. You are not entitled to use or disclose that confidential information other than strictly in accordance with the terms of these Terms. You expressly provide ContextQA permission to include and publish Your name and logo on lists of ContextQA customers for the Product.<br/><br/>
    Our Confidential Information does not include any information that (i) is or becomes generally known to the public without breach of any obligation (ii) was known to the User prior to its disclosure by Us without breach of any obligation owed to Us, (iii) is received from a third party without breach of any obligation owed to Us, or (iv) was independently developed by the User.<br/><br/>
    The User will use the same degree of care that it uses to protect the confidentiality of its own confidential information of like kind (but not less than reasonable care) (i) not to use any Confidential Information of ContextQA for any purpose outside the scope of these Terms, and (ii) except as otherwise authorized by Us in writing, to limit access to Our Confidential Information to those of its and its Affiliates’ employees and contractors who need that access for purposes consistent with this Agreement and who have signed confidentiality agreements with the User containing protections no less stringent than those herein. <br/>

    <h4>Jurisdiction</h4>
    This agreement is governed and construed in accordance with the Laws of Union of India. You hereby irrevocably consent to the exclusive jurisdiction and venue of courts in Bangalore, Karnataka, India, in all disputes arising out of or relating to the use of ContextQA services. Use of Services is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph. You agree to indemnify and hold ContextQA, its subsidiaries, affiliates, officers, directors, employees, and representatives harmless from any claim, demand, or damage, including reasonable attorneys' fees, asserted by any third-party due to or arising out of your use of or conduct on ContextQA services.<br/>

    <h4>Entire Agreement</h4>
    The failure by us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision. These Terms and any policies or operating rules posted by us on this Website or in respect of the Service constitutes the entire agreement and understanding between You and Us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms). Any ambiguities in the interpretation of these Terms shall not be construed against the drafting party.<br/>

    <h4>Contact Information</h4>
    Questions about the Terms should be sent to us at <b>support@contextqa.com</b>
  </div>


  </div>

      <form class="ts-form pt-10" [formGroup]="formGroup">
        <div class="d-flex justify-content-center">
          <p>On clicking Agree, You agree for the Terms and Conditions mentioned above</p>
        </div>
        <div class="d-flex justify-content-center">
          <p>On clicking Disagree You will be logged out and cannot proceed further</p>
        </div>
        <div class="d-flex justify-content-center">
          <div class="pb-10"><mat-checkbox formControlName="agree" [(ngModel)]="server.consent">Allow ContextQA to collect anonymous stats</mat-checkbox></div>
        </div>


        <div class="d-flex w-100 justify-content-center">
          <button class="btn btn-clear" (click)="logout()">Disagree</button>
          <button class="btn btn-primary" (click)="onSubmit()" style="size">Agree</button>
        </div>

    </form>
</div>

