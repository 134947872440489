/*
 *
 * ****************************************************************************
 *  * Copyright (C) 2019 Testsigma Technologies Inc.
 *  * All rights reserved.
 *  ****************************************************************************
 *
 */

export enum TestDataType {
  raw = "raw",
  /** "@|paramVarName|" */
  parameter = "parameter",
  /** "$|runtimeVarName|" */
  runtime = "runtime",
  /** "*|environmentVarName|"" */
  environment = "environment",
  /** "~|randomVarName|"" */
  random = "random",
  /** "!|functionName|"" */
  function = "function"
}
