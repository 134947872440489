import { Component, Input, HostListener } from '@angular/core';

@Component({
    selector: 'app-limited-chip-list',
    template: `
    <div class="ts-col-10 chip-container">
      <mat-chip-list class="mat-custom-chip-list">
        <mat-chip 
          *ngFor="let tag of visibleTags"
          class="fixed-width-chip">
          <span class="chip-content" [matTooltip]="tag">{{ tag }}</span>
        </mat-chip>
        <mat-chip 
          *ngIf="hiddenTags.length > 0"
          class="fixed-width-chip more-chip"
          (click)="toggleDropdown($event)">
          +{{ hiddenTags.length }} more
        </mat-chip>
      </mat-chip-list>
      
      <div *ngIf="isDropdownOpen" class="custom-dropdown">
        <div *ngFor="let tag of hiddenTags" class="dropdown-item">
          {{ tag }}
        </div>
      </div>
    </div>
  `,
    styles: [`
    .chip-container {
      position: relative;
    }
    .fixed-width-chip {
      max-width: 100px;
      font-size: 10px;
      padding: 3px 8px;
      min-height: 24px;
    }
    .chip-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
    .more-chip {
      cursor: pointer;
    }
    .custom-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: white;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1);
      z-index: 1000;
      max-height: 200px;
      overflow-y: auto;
    }
    .dropdown-item {
      padding: 8px 12px;
      cursor: pointer;
    }
    .dropdown-item:hover {
      background-color: #f5f5f5;
    }
  `]
})
export class LimitedChipListComponent {
    @Input() tags: string[] = [];
    visibleTags: string[] = [];
    hiddenTags: string[] = [];
    isDropdownOpen = false;

    ngOnInit() {
        this.updateVisibleTags();
    }

    updateVisibleTags() {
        this.visibleTags = this.tags.slice(0, 2);
        this.hiddenTags = this.tags.slice(2);
    }

    toggleDropdown(event: Event) {
        event.stopPropagation();
        this.isDropdownOpen = !this.isDropdownOpen;
    }

    @HostListener('document:click')
    closeDropdown() {
        this.isDropdownOpen = false;
    }
}