import {Component, OnInit} from '@angular/core';
import { LicenceSevices } from '../../shared/services/license.service'


@Component({
    selector: 'manage-plan-template',
    templateUrl: './manage-plan.componet.html',
    styleUrls: ["./manage-plan.componet.scss"]
  })
  // export class SubscriptionComponet extends BaseComponent implements OnInit {
  export class ManageplanComponet implements OnInit {
    dataValue:any
    currentPlanType

    constructor( public LicenceSevices: LicenceSevices) {}

    ngOnInit(): void {
      this.LicenceSevices.getAll().subscribe(data => {
        this.dataValue = data;
        this.currentPlanType = this.dataValue.type
      })
    }

    OnclickUpdate_Plan(){
      window.open('https://billing.stripe.com/p/login/14keYedyTcepgso288 ', '_blank').focus();
    }
  }
