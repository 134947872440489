<!-- <h1>Hello world</h1> -->

<form
  class="ts-form p-30 mt-n80"
  [formGroup]="stepAiIntegrationForm">
  <div
    class="px-5 pt-14 pb-15 step-container-section"
    [style]="'padding-left: calc('+ indentation*3 +'px + 5px) !important' ">
    <!-- <app-auto-complete
      class="pb-2 ts-col-100 d-block mt-n4"
      [formGroup]="stepAiIntegrationForm"
      [formCtrlName]="stepAiIntegrationForm.controls['steps']"
      [value]="testStep?.steps"
      [inline]="true"
      (onValueChange)="setStepGroup($event)"
    ></app-auto-complete> -->
    <!-- <input type="text" [formGroup]="stepAiIntegrationForm"> -->
    <!-- <app-description ></app-description> -->
    
    <textarea name="" id="" [formControlName]="['step']" cols="100" rows="2" style="margin-top: -10px;box-sizing: border-box;  border: 1px solid #ccc;  border-radius: 4px;"></textarea>
  </div>
  <!-- <div
    class="align-items-center d-flex"
    *ngIf="(stepGroups?.content?.length || searchQuery?.length) && testStep?.stepGroup">
    <div class="text-secondary text-truncate d-flex flex-col">
      <div class="rb-semi-medium">{{'test_case.create_help.description.label' | translate}} : </div>
      <div
        #stepGroupDescElem
        class="ml-5 text-truncate"
        [textContent]="stepGroupDescriptionText || ('results.list_view.no_description' | translate)"
        [matTooltip]="stepGroupDescElem.offsetWidth < stepGroupDescElem.scrollWidth? stepGroupDescElem.textContent:''"
      ></div>
    </div>
    <a
      class="action-icon fa-external-link-alt-solid"
      target="_blank"
      [routerLink]="['/td', 'cases', testStep.stepGroup.id, 'steps']"
      [matTooltip]="'test_step.step_group.view_details' | translate"
    ></a>
  </div> -->
  <div
    class="action-cf-btn-container">
    <i class="fa-help text-t-secondary pr-7"></i>
    <!-- <a
      class=" text-t-secondary"
      rel="noreferrer nofollow"
      href="https://contextqa.com/docs/test-cases/step-types/step-group/"
      target="_blank"
      [translate]="'step.create.help.note'"></a> -->

    <div class="ml-auto">
      <button
        (click)="cancel()"
        class="theme-btn-clear-default"
        [translate]="'btn.common.cancel'"></button>
      <button
        *ngIf="!testStep.id"
        [isLoading]="saving"
        [message]="'message.common.saving'"
        appAsyncBtn
        (click)="add()"
        class="theme-btn-primary"
        [textContent]="'btn.common.add' | translate"></button>
      <button
        *ngIf="testStep.id"
        (click)="update()"
        [isLoading]="saving"
        [message]="'message.common.updating'"
        appAsyncBtn
        class="theme-btn-primary"
        [textContent]="'btn.common.update' | translate"></button>
    </div>
  </div>
</form>
