<div mat-dialog-title style="background-color: #3f51b5; color: white;">
    <b>Start a new test run</b>
    <mat-icon class="close-icon" style="cursor: pointer;" (click)="closeDialog()">close</mat-icon>
</div>
<div mat-dialog-content>
    <form [formGroup]="addRunForm">
      <div class="flex-container" style="margin-left: 25px;">

        <div class="flex-item with-divider">

        <div class="d-flex ts-col-100 mt-10">
<div class="ts-col-25">
    <mat-form-field class="sssss" appearance="outline">
        <mat-select formControlName="status" >
          <mat-option value="Manual" >Manual</mat-option>
          <mat-option value="Automated">Automated</mat-option>
        </mat-select>
      </mat-form-field>
</div>
<div class="ts-col-75">
    <input formControlName="expectedResult"  style="height: 40px;border: 1px solid #ccc;
    border-radius: 4px;" matInput>
</div>   
        </div>
  
          <p style="font-size: 20px; margin-bottom: 8px;"><b>Description</b></p>
          <input formControlName="message" placeholder="For Example : we can authorize on page https://example.com" style="height: 40px;border: 1px solid #ccc; 
    border-radius: 4px;" matInput>
          <p style="font-size: 17px; color: gray; margin-top: 3px;">Describe your test result.</p>
  
  <!--        <p style="font-size: 20px; margin-bottom: 8px;"><b>Upload an image</b></p>-->
  <!--        <input type="file" formControlName="image" class="sssss" matInput>-->
        </div>
  
        <!--  -->
  
        <div class="ts-col-100 d-flex">
         <div class="ts-col-45">
          <p style="font-size: 20px; margin-bottom: 8px;"><b>Environment</b></p>
          <mat-form-field class="w-full" appearance="outline">
            <mat-select formControlName="expectedResult" >
              <mat-option value="Manual" >Manual</mat-option>
              <mat-option value="Automated">Automated</mat-option>
            </mat-select>
          </mat-form-field>
          </div>
          <div class="ts-col-10">

          </div>
          <div class="ts-col-45">
          <p style="font-size: 20px; margin-bottom: 8px;"><b>Default assignee</b></p>
          <mat-form-field class="w-full" appearance="outline">
            <mat-select formControlName="actualResult" >
              <mat-option value="Manual" >Manual</mat-option>
              <mat-option value="Automated">Automated</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        </div>
        <p style="font-size: 25px; margin-bottom: 8px;"><b>Tests</b></p>
        <div class="ts-col-100 d-flex">
            <div class="ts-col-45">
             <p style="font-size: 20px; margin-bottom: 8px;"><b>Select cases</b></p>
             <mat-form-field class="w-full" appearance="outline">
               <mat-select formControlName="expectedResult" >
                 <mat-option value="Manual" >Manual</mat-option>
                 <mat-option value="Automated">Automated</mat-option>
               </mat-select> 
             </mat-form-field>
             </div>
             <div class="ts-col-55">
   
             </div>
     
           </div>
        <div class="flex-item small-width">
            <p style="font-size: 20px; margin-bottom: 8px;"><b>Reason</b></p>
          <input formControlName="reason" class="yyyyy"
                 style="height: 40px;border: 1px solid #ccc; border-radius: 4px;" matInput>
  
          <p style="font-size: 20px; margin-bottom: 8px;"><b>Suggestions</b></p>
          <input formControlName="suggestions" class="yyyyy" style="margin-bottom: 18px; height: 40px;border: 1px solid
           #ccc; border-radius: 4px;" matInput>
        </div>
      </div>
    </form>
  </div>
  <div mat-dialog-actions style="display: flex; justify-content: flex-start;">
    <button mat-raised-button style="background-color: #3f51b5; color: white; width: 150px;" (click)="save()">
      <mat-icon>check</mat-icon>
      Add
    </button>
    <button mat-stroked-button style="color: red; border: 2px solid red;" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
      Cancel
    </button>
  </div>