import {Component, Inject, OnInit, Optional} from '@angular/core';
import {WorkspaceVersionService} from "../shared/services/workspace-version.service";
import {BaseComponent} from "../shared/components/base.component";
import {ActivatedRoute, Router} from '@angular/router';
import {WorkspaceVersion} from "../models/workspace-version.model";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-test-development',
  templateUrl: './test-development.component.html',
  styles: [
    `
    .nav-items {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s ease;
}

.nav-items i {
  margin-right: 10px;
  font-size: 18px;
}

.nav-text {
  display: flex;
  align-items: center;
}

.beta-label {
  background-color: #FCD900;
  color: white !important;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 10px;
  margin-left: 8px;
  text-transform: uppercase;
  font-weight: bold;
}

.nav-items:hover {
  background-color: #f0f0f0;
}

.nav-items.active {
  background-color: #e0e0e0;
  font-weight: bold;
}
  .category-header {
  padding-left:16px;
  }
    `
  ]
})
export class TestDevelopmentComponent extends BaseComponent implements OnInit {
  public versionId: Number;
  public version: WorkspaceVersion;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private versionService: WorkspaceVersionService,
    @Optional() private dialogRef?: MatDialogRef<TestDevelopmentComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data?: {versionId: number}) {
    super();
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.pushToParent(this.route, params);
      this.versionId = params.versionId | this.data?.versionId;
      if (this.versionId > 0)
        this.fetchVersion()
    });
  }

  get detailsAliasName(){
    let leftPath = this.router.url.split("/")[3]
    if(isNaN(parseInt(leftPath)))
      return  leftPath
    else
      return this.router.url.split("/")[2];
  }

  fetchVersion() {
    this.versionService.show(this.versionId).subscribe(res => this.version = res, err => {
      if (err.status == 404) {
        this.versionService.findAll("isDemo:true").subscribe(versions => {
          this.version = versions.content[0];
          // this.router.navigate(['/td', this.version.id, 'cases']);
          window.location.href = "https://accounts.contextqa.com/error?error=Test%20Case%20not%20Exists."
        });
      };
    });
  }

  closeDialog(){
    if(this.dialogRef)
      this.dialogRef.close();
  }
}
